export const bank = [
    // {
    //   "value": "001",
    //   "label": "ธนาคารแห่งประเทศไทย"
    // },
    {
        value: '002',
        label: 'ธนาคารกรุงเทพ จำกัด (มหาชน)'
    },
    {
        value: '014',
        label: 'ธนาคารไทยพาณิชย์จำกัด (มหาชน)'
    },
    {
        value: '004',
        label: 'ธนาคารกสิกรไทย จำกัด (มหาชน)'
    },
    // {
    //   "value": "005",
    //   "label": "เดอะรอยัลแบงก์อ๊อฟสกอตแลนด์เอน็ วี"
    // },
    {
        value: '006',
        label: 'ธนาคารกรุงไทย จำกัด (มหาชน)'
    },
    // {
    //   "value": "008",
    //   "label": "ธนาคารเจพีมอร์แกน เชส"
    // },
    {
        value: '011',
        label: 'ธนาคารทหารไทย จำกัด (มหาชน)'
    },
    {
        value: '017',
        label: 'ธนาคารซิตี้แบงค์'
    },
    // {
    //   "value": "018",
    //   "label": "ชูมิโตโม มิตซุย แบงก์ คอร์ปอเรชั่น"
    // },
    {
        value: '020',
        label: 'ธนาคารสแตนดาร์ด ชาร์เตอร์ด (ไทย) จำกัด'
    },
    {
        value: '022',
        label: 'ธนาคาร ซีไอเอม็ บีไทย จำกัด (มหาชน)'
    },
    {
        value: '024',
        label: 'ธนาคารยูโอบีจำกัด (มหาชน)'
    },
    {
        value: '025',
        label: 'ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)'
    },
    // {
    //   "value": "026",
    //   "label": "ธนาคาร เมกะ สากลพาณิชย์จำกัด (มหาชน)"
    // },
    // {
    //   "value": "027",
    //   "label": "ธนาคารแห่ง อเมริกา เนชั่นแนล แอสโซซิเอชั่น"
    // },
    {
        value: '030',
        label: 'ธนาคารออมสิน'
    },
    // {
    //   "value": "031",
    //   "label": "ธนาคารฮ่องกงและเซี่ยงไฮ้แบงก์คอร์ปอเรชั่น จำกัด"
    // },
    // {
    //   "value": "032",
    //   "label": "ธนาคารดอยซ์แบงก์"
    // },
    {
        value: '033',
        label: 'อาคารสงเคราะห์'
    },
    {
        value: '034',
        label: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'
    },
    // {
    //   "value": "039",
    //   "label": "ธนาคารมิซูโฮ คอร์เปอเรท"
    // },
    // {
    //   "value": "045",
    //   "label": "ธนาคาร บีเอ็น พีพารีบาส์"
    // },
    {
        value: '052',
        label: 'ธนาคารแห่ง ประเทศจีน (ไทย) จำกัด (มหาชน)'
    },
    {
        value: '065',
        label: 'ธนาคารธนชาต จำกัด (มหาชน)'
    },
    {
        value: '066',
        label: 'ธนาคารอิสลามแห่งประเทศไทย'
    },
    {
        value: '067',
        label: 'ธนาคารทิสโก้จำกัด (มหาชน)'
    },
    {
        value: '069',
        label: 'ธนาคารเกียรตินาคิน จำกัด (มหาชน)'
    },
    {
        value: '070',
        label: 'ธนาคารไอซีบีซี(ไทย) จำกัด (มหาชน)'
    },
    {
        value: '071',
        label: 'ธนาคารไทยเครดิตอเพื่อยย่อยจำกัด (มหาชน)'
    },
    {
        value: '073',
        label: 'ธนาคารแลนด์แอนด์เฮาส์'
    }
    // {
    //   "value": "079",
    //   "label": "ธนาคารเอเอ็น แซด (ไทย) จำกัด (มหาชน)"
    // }
];
