import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import { maxLengthCheck, validateNumber } from '../../../../../../_metronic/_helpers';
import { geo, province, district, subdistrict } from '../../../../../address';
import { ControlComponent, customStyles } from './dropdown';

export function SubscriberAddress(props) {
    const [ geooptions, setGeoOptions ] = useState([]);
    const [ provinceoptions, setProvinceOptions ] = useState([]);
    const [ districtoptions, setDistrictOptions ] = useState([]);
    const [ subdistrictoptions, setSubDistrictOptions ] = useState([]);

    useEffect(() => {
        setGeoOptions(geo);
        if (props.geo !== '') {
            let geoid = props.geo.value;
            let thisProvince = [];
            let provinceindex = 0;
            for (const index in province) {
                if (province[index]['geoid'] === geoid) {
                    thisProvince[provinceindex] = province[index];
                    provinceindex += 1;
                }
            }
            setProvinceOptions(thisProvince);
        } else {
            setProvinceOptions(province);
        }
        setDistrictOptions(district);
        setSubDistrictOptions(subdistrict);
    }, []);

    const selectGeo = (item) => {
        props.setProvince('');
        props.setDistrict('');
        props.setSubDistrict('');
        props.setZipcode('');
        setProvinceOptions(province);
        setDistrictOptions(district);
        setSubDistrictOptions(subdistrict);
        if (item) {
            props.setGeo(item);
            let thisProvince = [];
            let thisindex = 0;
            for (const index in province) {
                if (province[index]['geoid'] === item['value']) {
                    thisProvince[thisindex] = province[index];
                    thisindex += 1;
                }
            }
            setProvinceOptions(thisProvince);
        } else {
            props.setGeo('');
        }
    };

    const selectProvince = (item) => {
        props.setDistrict('');
        props.setSubDistrict('');
        props.setZipcode('');
        setDistrictOptions(district);
        setSubDistrictOptions(subdistrict);
        if (item) {
            props.setProvince(item);
            let thisDistrict = [];
            let thisindex = 0;
            for (const index in district) {
                if (district[index]['provinceid'] === item['value']) {
                    thisDistrict[thisindex] = district[index];
                    thisindex += 1;
                }
            }
            setDistrictOptions(thisDistrict);
        } else {
            props.setProvince('');
        }
    };

    const selectDistrict = (item) => {
        props.setSubDistrict('');
        props.setZipcode('');
        setSubDistrictOptions(subdistrict);
        if (item) {
            props.setDistrict(item);
            let thisSubDistrict = [];
            let thisindex = 0;
            for (const index in subdistrict) {
                if (subdistrict[index]['districtid'] === item['value']) {
                    thisSubDistrict[thisindex] = subdistrict[index];
                    thisindex += 1;
                }
            }
            setSubDistrictOptions(thisSubDistrict);
        } else {
            props.setDistrict('');
        }
    };

    const selectSubDistrict = (item) => {
        let thisZipcode = '';
        props.setZipcode(thisZipcode);
        if (item) {
            props.setSubDistrict(item);
            thisZipcode = item['zipcode'];
            props.setZipcode(thisZipcode);
        } else {
            props.setSubDistrict('');
        }
    };

    const btnBack = () => {
        props.setPage('information');
    };

    const btnNext = () => {
        let message = '';
        if (props.geo === '' && message === '') {
            message = 'กรุณาเลือกภูมิภาค';
        }
        if (props.province === '' && message === '') {
            message = 'กรุณาเลือกจังหวัด';
        }
        if (props.district === '' && message === '') {
            let thisdistrict = '';
            if (props.province !== '') {
                if (props.province.value === '1') {
                    thisdistrict = 'เขต';
                } else {
                    thisdistrict = 'อำเภอ';
                }
            } else {
                thisdistrict = 'อำเภอ / เขต';
            }
            message = `กรุณาเลือก${thisdistrict}`;
        }
        if (props.subdistrict === '' && message === '') {
            let thissubdistrict = '';
            if (props.province !== '') {
                if (props.province.value === '1') {
                    thissubdistrict = 'แขวง';
                } else {
                    thissubdistrict = 'ตำบล';
                }
            } else {
                thissubdistrict = 'ตำบล / แขวง';
            }
            message = `กรุณาเลือก${thissubdistrict}`;
        }
        if (props.zipcode === '' && message === '') {
            message = 'กรุณากรอกรหัสไปรษณีย์';
        }
        if (props.address === '' && message === '') {
            message = 'กรุณากรอกบ้านเลขที่ ถนน ซอย';
        }
        if (message !== '') {
            props.SwalBs.fire({
                // title: 'ผิดพลาด!',
                html: `<div class='alert-text'>${message}</div>`,
                icon: 'warning',
                confirmButtonColor: '#ffa800',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        if (!props.edit) {
            props.setPage('shipping');
        } else {
            props.setPage('summary');
        }
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครผู้ใช้งานใหม่</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครผู้ใช้งานใหม่</div>
                                                    กรอกข้อมูลที่อยู่ที่สามารถติดต่อได้
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <form
                                                    className='form mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='pb-5'>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        ภูมิภาค<span className='required-text'>*</span>
                                                                    </label>
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent
                                                                        }}
                                                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                                        value={props.geo}
                                                                        options={geooptions}
                                                                        placeholder='เลือก'
                                                                        name='geo'
                                                                        onChange={selectGeo}
                                                                        isClearable={true}
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        จังหวัด<span className='required-text'>*</span>
                                                                    </label>
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent
                                                                        }}
                                                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                                        value={props.province}
                                                                        options={provinceoptions}
                                                                        placeholder='เลือก'
                                                                        name='province'
                                                                        onChange={selectProvince}
                                                                        isClearable={true}
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyles}
                                                                        isDisabled={props.geo === ''}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        {props.province !== '' ? props.province
                                                                            .value === '1' ? (
                                                                            'เขต'
                                                                        ) : (
                                                                            'อำเภอ'
                                                                        ) : (
                                                                            'อำเภอ / เขต'
                                                                        )}
                                                                        <span className='required-text'>*</span>
                                                                    </label>
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent
                                                                        }}
                                                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                                        value={props.district}
                                                                        options={districtoptions}
                                                                        placeholder='เลือก'
                                                                        name='district'
                                                                        onChange={selectDistrict}
                                                                        isClearable={true}
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyles}
                                                                        isDisabled={props.province === ''}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        {props.province !== '' ? props.province
                                                                            .value === '1' ? (
                                                                            'แขวง'
                                                                        ) : (
                                                                            'ตำบล'
                                                                        ) : (
                                                                            'ตำบล / แขวง'
                                                                        )}
                                                                        <span className='required-text'>*</span>
                                                                    </label>
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent
                                                                        }}
                                                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                                        value={props.subdistrict}
                                                                        options={subdistrictoptions}
                                                                        placeholder='เลือก'
                                                                        name='subdistrict'
                                                                        onChange={selectSubDistrict}
                                                                        isClearable={true}
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyles}
                                                                        isDisabled={props.district === ''}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        รหัสไปรษณีย์<span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        inputMode='tel'
                                                                        className='form-control form-control-lg'
                                                                        name='zipcode'
                                                                        maxLength='5'
                                                                        onInput={maxLengthCheck}
                                                                        onKeyPress={(event) => validateNumber(event)}
                                                                        onChange={(e) =>
                                                                            props.setZipcode(e.target.value)}
                                                                        value={props.zipcode}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='form-group fv-plugins-icon-container'>
                                                            <label>
                                                                บ้านเลขที่ ถนน ซอย<span className='required-text'>*</span>
                                                            </label>
                                                            <TextareaAutosize
                                                                className='form-control form-control-lg textarea-min-h'
                                                                name='address'
                                                                onChange={(e) => props.setAddress(e.target.value)}
                                                                value={props.address}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                                        <div className='mr-2'>
                                                            {!props.edit && (
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-light-primary font-weight-bold text-uppercase px-9 py-4'
                                                                    onClick={btnBack}
                                                                >
                                                                    ก่อนหน้า
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
