import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export function FeelsAgreement(props) {
    const btnNext = () => {
        checkPosition();
    };

    const checkPosition = () => {
        props.setLoading(true);
        fetch(props.config.dealer_url + props.config.version + 'profile/valid-register-status', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: props.config.secret
            },
            body: JSON.stringify({
                phonenumber: props.msisdn
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    if (result.numbers[0]['can_register_sim'] === 'Y') {
                        checkSim();
                    } else {
                        props.setLoading(false);
                        props.SwalBs.fire({
                            // title: 'ยินดีต้อนรับ',
                            html: `<div class="alert-text">เบอร์ ${props.msisdn} ยังไม่สามารถลงทะเบียนได้ เนื่องจากยังไม่อัพเกรด</div>`,
                            // icon: 'info',
                            imageUrl: props.toAbsoluteUrl('/media/misc/info-punsook.svg'),
                            imageWidth: 80,
                            imageHeight: 80,
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    }
                } else {
                    props.setLoading(false);
                    let message = '';
                    if (result.responsecode === '100') {
                        message = `ไม่พบเบอร์ ${props.msisdn} ในระบบ กรุณาติดต่อเจ้าหน้าที่เพื่อตรวจสอบ`;
                    } else {
                        message = 'มีบางอย่างผิดพลาด กรุณาติดต่อเจ้าหน้าที่เพื่อตรวจสอบ';
                    }
                    props.SwalBs.fire({
                        // title: 'ยินดีต้อนรับ',
                        html: '<div class="alert-text">' + message + '</div>',
                        // icon: 'info',
                        imageUrl: props.toAbsoluteUrl('/media/misc/info-punsook.svg'),
                        imageWidth: 80,
                        imageHeight: 80,
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                props.setLoading(false);
                props.SwalBs.fire({
                    title: 'ผิดพลาด!',
                    html:
                        '<div class="alert-text">ไม่สามารถตรวจสอบเบอร์ที่ต้องการลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง</div>',
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const checkSim = () => {
        fetch(props.config.custom_url + props.config.version + 'onlinesim/checksim', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: props.config.custom_secret
            },
            body: JSON.stringify({
                iccid: props.iccid,
                msisdn: '66' + props.msisdn.slice(1)
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                if (result.responsecode === 0) {
                    props.setPage('infoimage');
                } else {
                    let message = '';
                    if (result.responsecode === 103) {
                        message = `ไม่พบเบอร์ ${props.msisdn} ในระบบ Feels`;
                    } else if (result.responsecode === 104) {
                        message = 'ซิมของคุณพบข้อผิดพลาดใน iccid กรุณาติดต่อเจ้าหน้าที่เพื่อตรวจสอบ';
                    } else if (result.responsecode === 107) {
                        message = 'ซิมที่คุณมีเกินกำหนดให้ลงทะเบียนแล้ว';
                    } else if (result.responsecode === 105) {
                        message = `เบอร์ ${props.msisdn} ถูกลงทะเบียนไปแล้ว`;
                    } else if (result.responsecode === 106) {
                        message = `เบอร์ ${props.msisdn} อยู่ระหว่างรอเจ้าหน้าที่ตรวจสอบ`;
                    }
                    props.SwalBs.fire({
                        // title: 'ยินดีต้อนรับ',
                        html: '<div class="alert-text">' + message + '</div>',
                        // icon: 'info',
                        imageUrl: props.toAbsoluteUrl('/media/misc/info-punsook.svg'),
                        imageWidth: 80,
                        imageHeight: 80,
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                props.setLoading(false);
                props.SwalBs.fire({
                    title: 'ผิดพลาด!',
                    html:
                        '<div class="alert-text">ไม่สามารถตรวจสอบเบอร์ที่ต้องการลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง</div>',
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };
    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Feels-logo'
                                    src={props.toAbsoluteUrl('/media/logos/feels-full-logo.svg')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>ลงทะเบียนซิมออนไลน์</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center' />
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <form id='login-form' className='nobottommargin'>
                        <div
                            className='card card-custom card-stretch example example-compact'
                            id='kt_page_stretched_card'
                        >
                            <div className='card-header'>
                                <div className='card-title'>
                                    <h3 className='card-label'>
                                        <small>เงื่อนไขการใช้งานระบบลงทะเบียนออนไลน์</small>
                                    </h3>
                                </div>
                                <div className='card-toolbar' />
                            </div>
                            <div className='card-body'>
                                <OverlayScrollbarsComponent className='agreement-body'>
                                    <div className='p-5 agreement-bg'>
                                        <p className='text-left mb-3'>
                                            ผู้ใช้บริการสามารถลงทะเบียนซิมการ์ดแบบเติมเงินเครือข่าย Feels ได้ทุกประเภท
                                            โดยผู้ใช้บริการสามารถเลือกซื้อบริการเสริมได้จากช่องทางต่างๆ ที่บริษัทกำหนด
                                            อาทิเช่น บนเว็บไซต์ ตัวแทนจำหน่ายฟีล สื่อสังคม เป็นต้น
                                            โดยผู้ใช้บริการที่ประสงค์ใช้บริการ Feels สามารถลงทะเบียนผ่านช่องทางเว็บไซต์
                                            ตัวแทนจำหน่าย หรือจากจุดให้บริการของฟีลได้
                                        </p>
                                        <p className='text-left mb-3'>
                                            ผู้ใช้บริการสามารถเข้าสู่หน้าลงทะเบียนซิมการ์ดได้ผ่านช่องทางเว็บไซต์
                                            หรือผู้ใช้บริการสามารถแสกน QR Code
                                            บนซองซิมการ์ดเพื่อเข้าไปยังช่องทางลงทะเบียนซิมฟีล
                                            โดยบริษัทจะนับว่าผู้ใช้บริการได้อ่านและยอมรับเงื่อนไขการใช้บริการของบริษัทแล้ว
                                            ผู้ใช้บริการสามารถอ่านเงื่อนไขการใช้งานได้{' '}
                                            <a
                                                href='https://feels.co.th/proviso'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='link-txt text-feels-main'>ที่นี่</span>
                                            </a>
                                        </p>
                                        <p className='text-left mb-3'>
                                            ในการใช้งานระบบเติมเงิน หรือระบบชำระเงินของบริษัท
                                            ผู้ใช้บริการยินยอมให้บริษัทหักบัญชีจากบัตรเครดิต บัตรเดบิต บัญชี Alipay
                                            บัญชี Rabbit Line Pay บัญชี WeChat Pay
                                            หรือวิธีการอื่นใดที่บริษัทจะมีในอนาคตที่ผู้ใช้บริการได้เลือกไว้
                                        </p>
                                        <p className='text-left mb-3'>
                                            ผู้ใช้บริการจะต้องถ่ายรูปบาร์โค้ดบนซองซิมการ์ดเพื่อยืนยันเลขหมายที่ต้องการลงทะเบียน
                                            อัพโหลดรูปบัตรประจำตัวประชาชน รูปหน้าตรงของผู้ใช้บริการ หมายเลขติดต่อ
                                            และที่อยู่จัดส่งซิมการ์ด (กรณีสั่งซื้อซิมการ์ดออนไลน์)
                                            ทั้งนี้เพื่อเป็นข้อมูลประกอบในการยืนยันตัวตนในขั้นตอนการแสดงตนเพื่อการเปิดใช้บริการ
                                        </p>
                                        <p className='text-left mb-3'>
                                            บริษัทมีหน้าที่จัดเก็บเลขประจำตัวประชาชน ชื่อ สกุล ที่อยู่ รูปถ่าย
                                            ของผู้ใช้บริการ โดยผู้ใช้บริการต้องใช้บัตรประจำตัวประชาชนฉบับจริง
                                            หรือบัตรอื่นใดฉบับจริงตามที่ สำนักงาน กสทช. กำหนด
                                            เพื่อลงทะเบียนแสดงตนในฐานะผู้ใช้บริการ ตามขั้นตอน วิธีการที่สำนักงาน กสทช.
                                            กำหนด
                                            โดยผู้ใช้บริการมีสิทธิปฏิเสธการจัดเก็บข้อมูลดังกล่าวและยกเลิกการลงทะเบียนซิมการ์ดได้
                                            ผู้ใช้บริการสามารถอ่านรายละเอียดนโยบายความเป็นส่วนตัวเพิ่มเติมได้{' '}
                                            <a
                                                href='https://feels.co.th/policy'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='link-txt text-feels-main'>ที่นี่</span>
                                            </a>
                                        </p>
                                        <p className='text-left mb-3'>
                                            ผู้ใช้บริการยินยอมให้บริษัทจัดเก็บ รวบรวม ใช้
                                            และประมวลผลข้อมูลของผู้ใช้บริการ
                                            เพื่อใช้ประโยชน์เกี่ยวกับหรือเกี่ยวเนื่องกับการให้บริการอันเป็นประโยชน์แก่ผู้ใช้บริการ
                                        </p>
                                        <p className='text-left mb-3'>
                                            บริษัทขอสงวนสิทธิในการระงับการใช้งานหรือปฏิเสธการลงทะเบียน ให้บริการ
                                            หรือการสั่งซื้อซิมการ์ดในกรณีที่ผู้ใช้บริการให้ข้อมูลไม่เพียงพอ
                                            มิได้ดำเนินตามขั้นตอนตามที่บริษัทและวิธีการที่สำนักงาน กสทช. กำหนด
                                            หรือบริษัทตรวจสอบพบว่าข้อมูลการลงทะเบียนของผู้ใช้บริการทั้งหมดหรือบางส่วนเป็นเท็จ
                                            และบริษัทจะดำเนินการในทางกฎหมายต่อไป
                                        </p>
                                        <ol className='text-left mb-3'>
                                            <li>
                                                ใช้บริการ หรืออนุญาตให้บุคคลอื่นใช้บริการในลักษณะหยาบคาย ลามกอนาจาร
                                                ไม่ชอบด้วยกฎหมาย หรือขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน
                                            </li>
                                            <li>
                                                ใช้เพื่อวัตถุประสงค์ในการข่มขู่ รบกวน คุกคาม ล่วงเกิน
                                                หรือเป็นการกระทำละเมิดต่อสิทธิในความเป็นส่วนตัวหรือทำให้บุคคลอื่นเสื่อมเสียชื่อเสียง
                                            </li>
                                            <li>กระทำการใดอันเป็นการละเมิดสิทธิในทรัพย์สินทางปัญญา</li>
                                            <li>ใช้เพื่อการสร้างความเข้าใจผิด และ/หรือหลอกลวงประชาชน</li>
                                            <li>
                                                ดำเนินการใด ๆ ในลักษณะของการแทรกแซงการให้บริการของผู้ให้บริการ
                                                หรือผู้ให้บริการรายอื่นซึ่งอาจก่อให้เกิดความเสียหายต่อผู้ให้บริการหรือโครงข่ายโทรคมนาคมอื่น
                                                ๆ
                                            </li>
                                            <li>
                                                ใช้ในลักษณะที่ต้องห้ามหรือขัดต่อกฎหมาย ระเบียบ จรรยาบรรณ หรือประกาศ
                                                ใดๆที่ใช้บังคับ
                                            </li>
                                        </ol>
                                        <p className='text-left mb-3'>
                                            กรณีผู้ให้บริการตรวจสอบได้ว่าผู้ใช้บริการมีเจตนาในการทุจริตในทุกรูปแบบ
                                            หรือมิได้ทำตามเงื่อนไขการใช้บริการที่บริษัทกำหนด
                                            หรือกระทำการใดหรือแสดงข้อความหรือเอกสารใดอันเป็นเท็จเพื่อให้ได้มาซึ่งผลประโยชน์ส่วนตัวของผู้ใช้บริการ
                                            บริษัทสามารถระงับบริการหมายเลข และ/หรือใช้สิทธิเรียกร้อง
                                            และ/หรือดำเนินคดีตามกฎหมายได้
                                        </p>
                                        <p className='text-left mb-3'>
                                            ในกรณีที่ผู้ใช้บริการมิได้ใช้งานซิมการ์ด หรือลงทะเบียนซิมการ์ดภายในระยะเวลา
                                            60 วันนับจากวันที่ผู้ใช้บริการได้รับซิมการ์ด
                                            บริษัทขอสงวนสิทธิระงับการเปิดใช้ซิมการ์ดนั้นเพื่อความปลอดภัยของผู้ใช้บริการ
                                        </p>
                                    </div>
                                </OverlayScrollbarsComponent>
                                <div className='d-flex justify-content-center border-top mt-5 pt-10'>
                                    <div>
                                        <button
                                            type='button'
                                            className='btn btn-success btn-feels-main font-weight-bold text-uppercase px-9 py-4'
                                            onClick={btnNext}
                                        >
                                            ยอมรับเงื่อนไข
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
