import React from 'react';
import { components } from 'react-select';

export const controlStyles = {
    // borderRadius: '0.85rem',
    // padding: '5px',
    // background: '#F3F6F9',
    color: 'white'
    // border: '1px solid #E4E6EF'
};

export const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

export const customStyles = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        border: state.isFocused ? '1px solid #F64E60' : '1px solid #E4E6EF',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: '0.85rem',
        padding: '5px',
        '&:hover': {
            border: state.isFocused ? '1px solid #F64E60 !important' : '1px solid #E4E6EF !important'
        }
    })
    // singleValue: (provided, state) => {
    //     const opacity = state.isDisabled ? 0.5 : 1;
    //     const transition = "opacity 300ms";
    //     return { ...provided, opacity, transition };
    // },
};

export const shipcontrolStyles = {
    // borderRadius: '0.85rem',
    // padding: '5px',
    // background: '#F3F6F9',
    color: 'white'
    // border: '1px solid #E4E6EF'
};

export const ShipControlComponent = (props) => (
    <div style={shipcontrolStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

export const shipcustomStyles = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        border: state.isFocused ? '1px solid #F64E60' : '1px solid #E4E6EF',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: '0.85rem',
        padding: '5px',
        '&:hover': {
            border: state.isFocused ? '1px solid #F64E60 !important' : '1px solid #E4E6EF !important'
        }
    })
    // singleValue: (provided, state) => {
    //     const opacity = state.isDisabled ? 0.5 : 1;
    //     const transition = "opacity 300ms";
    //     return { ...provided, opacity, transition };
    // },
};
