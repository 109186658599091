import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import { maxLengthCheck, validateNumber } from '../../../../../../_metronic/_helpers';
import { geo, province, district, subdistrict } from '../../../../../address';
import { ShipControlComponent, shipcustomStyles } from './dropdown';

export function SubscriberShipping(props) {
    const [ shipgeooptions, setShipGeoOptions ] = useState([]);
    const [ shipprovinceoptions, setShipProvinceOptions ] = useState([]);
    const [ shipdistrictoptions, setShipDistrictOptions ] = useState([]);
    const [ shipsubdistrictoptions, setShipSubDistrictOptions ] = useState([]);

    useEffect(() => {
        setShipGeoOptions(geo);
        if (props.shiptype === 'new') {
            if (props.shipgeo !== '') {
                let geoid = props.geo.value;
                let thisProvince = [];
                let provinceindex = 0;
                for (const index in province) {
                    if (province[index]['geoid'] === geoid) {
                        thisProvince[provinceindex] = province[index];
                        provinceindex += 1;
                    }
                }
                setShipProvinceOptions(thisProvince);
            } else {
                setShipProvinceOptions(province);
            }
            setShipDistrictOptions(district);
            setShipSubDistrictOptions(subdistrict);
        }
    }, []);

    const btnNext = () => {
        if (props.shiptype === 'new') {
            let message = '';
            if (props.shipname === '' && message === '') {
                message = 'กรุณากรอกชื่อ - นามสกุลผู้รับสินค้า';
            }
            if (props.shipcontactnumber === '' && message === '') {
                message = 'กรุณากรอกเบอร์โทรศัพท์ติดต่อ';
            } else if (props.shipcontactnumber !== '' && message === '') {
                if (props.shipcontactnumber.length !== 10 || props.shipcontactnumber.charAt(0) !== '0') {
                    message = 'กรุณากรอกเบอร์โทรศัพท์ติดต่อให้ถูกต้อง';
                }
            }
            if (props.shipgeo === '' && message === '') {
                message = 'กรุณาเลือกภูมิภาค';
            }
            if (props.shipprovince === '' && message === '') {
                message = 'กรุณาเลือกจังหวัด';
            }
            if (props.shipdistrict === '' && message === '') {
                let thisdistrict = '';
                if (props.shipprovince !== '') {
                    if (props.shipprovince.value === '1') {
                        thisdistrict = 'เขต';
                    } else {
                        thisdistrict = 'อำเภอ';
                    }
                } else {
                    thisdistrict = 'อำเภอ / เขต';
                }
                message = `กรุณาเลือก${thisdistrict}`;
            }
            if (props.shipsubdistrict === '' && message === '') {
                let thissubdistrict = '';
                if (props.shipprovince !== '') {
                    if (props.shipprovince.value === '1') {
                        thissubdistrict = 'แขวง';
                    } else {
                        thissubdistrict = 'ตำบล';
                    }
                } else {
                    thissubdistrict = 'ตำบล / แขวง';
                }
                message = `กรุณาเลือก${thissubdistrict}`;
            }
            if (props.shipzipcode === '' && message === '') {
                message = 'กรุณากรอกรหัสไปรษณีย์';
            }
            if (props.shipaddress === '' && message === '') {
                message = 'กรุณากรอกบ้านเลขที่ ถนน ซอย';
            }
            if (message !== '') {
                props.SwalBs.fire({
                    // title: 'ผิดพลาด!',
                    html: `<div class='alert-text'>${message}</div>`,
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
        }
        props.setPage('summary');
    };

    const selectType = (type) => {
        if (type === 'new') {
            props.setShipName('');
            props.setShipContactnumber('');
            props.setShipGeo('');
            props.setShipProvince('');
            props.setShipDistrict('');
            props.setShipSubDistrict('');
            props.setShipZipcode('');
            props.setShipAddress('');
        }
        props.setShipType(type);
    };

    const selectShipGeo = (item) => {
        props.setShipProvince('');
        props.setShipDistrict('');
        props.setShipSubDistrict('');
        props.setShipZipcode('');
        setShipProvinceOptions(province);
        setShipDistrictOptions(district);
        setShipSubDistrictOptions(subdistrict);
        if (item) {
            props.setShipGeo(item);
            let thisProvince = [];
            let thisindex = 0;
            for (const index in province) {
                if (province[index]['geoid'] === item['value']) {
                    thisProvince[thisindex] = province[index];
                    thisindex += 1;
                }
            }
            setShipProvinceOptions(thisProvince);
        } else {
            props.setShipGeo('');
        }
    };

    const selectShipProvince = (item) => {
        props.setShipDistrict('');
        props.setShipSubDistrict('');
        props.setShipZipcode('');
        setShipDistrictOptions(district);
        setShipSubDistrictOptions(subdistrict);
        if (item) {
            props.setShipProvince(item);
            let thisDistrict = [];
            let thisindex = 0;
            for (const index in district) {
                if (district[index]['provinceid'] === item['value']) {
                    thisDistrict[thisindex] = district[index];
                    thisindex += 1;
                }
            }
            setShipDistrictOptions(thisDistrict);
        } else {
            props.setShipProvince('');
        }
    };

    const selectShipDistrict = (item) => {
        props.setShipSubDistrict('');
        props.setShipZipcode('');
        setShipSubDistrictOptions(subdistrict);
        if (item) {
            props.setShipDistrict(item);
            let thisSubDistrict = [];
            let thisindex = 0;
            for (const index in subdistrict) {
                if (subdistrict[index]['districtid'] === item['value']) {
                    thisSubDistrict[thisindex] = subdistrict[index];
                    thisindex += 1;
                }
            }
            setShipSubDistrictOptions(thisSubDistrict);
        } else {
            props.setShipDistrict('');
        }
    };

    const selectShipSubDistrict = (item) => {
        let thisZipcode = '';
        props.setShipZipcode(thisZipcode);
        if (item) {
            props.setShipSubDistrict(item);
            thisZipcode = item['zipcode'];
            props.setShipZipcode(thisZipcode);
        } else {
            props.setShipSubDistrict('');
        }
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครผู้ใช้งานใหม่</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครผู้ใช้งานใหม่</div>
                                                    กรอกข้อมูลที่อยู่จัดส่ง
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <form
                                                    className='form mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='form-group fv-plugins-icon-container'>
                                                        <label>เลือกที่อยู่จัดส่ง</label>
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <label className='option'>
                                                                    <span className='option-control'>
                                                                        <span className='radio'>
                                                                            <input
                                                                                type='radio'
                                                                                name='shippingtype'
                                                                                checked={props.shiptype === 'same'}
                                                                                onChange={() => selectType('same')}
                                                                            />
                                                                            <span />
                                                                        </span>
                                                                    </span>
                                                                    <span className='option-label'>
                                                                        <span className='option-head'>
                                                                            <span className='option-title'>
                                                                                ตามที่อยู่ที่ติดต่อได้
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <label className='option'>
                                                                    <span className='option-control'>
                                                                        <span className='radio'>
                                                                            <input
                                                                                type='radio'
                                                                                name='shippingtype'
                                                                                checked={props.shiptype === 'new'}
                                                                                onChange={() => selectType('new')}
                                                                            />
                                                                            <span />
                                                                        </span>
                                                                    </span>
                                                                    <span className='option-label'>
                                                                        <span className='option-head'>
                                                                            <span className='option-title'>
                                                                                ที่อยู่อื่นนอกจากที่อยู่ที่ติดต่อได้
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='form-group fv-plugins-icon-container'>
                                                        <label>ที่อยู่จัดส่ง</label>
                                                        {props.shiptype === 'same' ? (
                                                            <div className='row'>
                                                                <div className='col-lg-12'>
                                                                    <label className='option'>
                                                                        <span className='option-label'>
                                                                            <span className='option-head'>
                                                                                <span className='option-title'>
                                                                                    ชื่อผู้รับสินค้า
                                                                                </span>
                                                                                <span className='option-focus'>
                                                                                    {props.information.name}
                                                                                </span>
                                                                            </span>
                                                                            <span className='option-head'>
                                                                                <span className='option-title'>
                                                                                    เบอร์โทรศัพท์ติดต่อ
                                                                                </span>
                                                                                <span className='option-focus'>
                                                                                    {props.information.contactnumber}
                                                                                </span>
                                                                            </span>
                                                                            <span className='option-head'>
                                                                                <span className='option-title'>
                                                                                    ภาค
                                                                                </span>
                                                                                <span className='option-focus'>
                                                                                    {props.geo.label}
                                                                                </span>
                                                                            </span>
                                                                            <span className='option-head'>
                                                                                <span className='option-title'>
                                                                                    จังหวัด
                                                                                </span>
                                                                                <span className='option-focus'>
                                                                                    {props.province.label}
                                                                                </span>
                                                                            </span>
                                                                            <span className='option-head'>
                                                                                <span className='option-title'>
                                                                                    {props.province !== '' ? props
                                                                                        .province.value === '1' ? (
                                                                                        'เขต'
                                                                                    ) : (
                                                                                        'อำเภอ'
                                                                                    ) : (
                                                                                        'อำเภอ / เขต'
                                                                                    )}
                                                                                </span>
                                                                                <span className='option-focus'>
                                                                                    {props.district.label}
                                                                                </span>
                                                                            </span>
                                                                            <span className='option-head'>
                                                                                <span className='option-title'>
                                                                                    {props.province !== '' ? props
                                                                                        .province.value === '1' ? (
                                                                                        'แขวง'
                                                                                    ) : (
                                                                                        'ตำบล'
                                                                                    ) : (
                                                                                        'ตำบล / แขวง'
                                                                                    )}
                                                                                </span>
                                                                                <span className='option-focus'>
                                                                                    {props.subdistrict.label}
                                                                                </span>
                                                                            </span>
                                                                            <span className='option-head'>
                                                                                <span className='option-title'>
                                                                                    รหัสไปรษณีย์
                                                                                </span>
                                                                                <span className='option-focus'>
                                                                                    {props.zipcode}
                                                                                </span>
                                                                            </span>
                                                                            <span className='option-head'>
                                                                                <span className='option-title'>
                                                                                    บ้านเลขที่ ถนน ซอย
                                                                                </span>
                                                                                <span className='option-focus'>
                                                                                    {props.address}
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='row'>
                                                                <div className='pb-5 col-xl-12'>
                                                                    <label className='option'>
                                                                        <span className='option-label'>
                                                                            <div className='row'>
                                                                                <div className='col-xl-6'>
                                                                                    <div className='form-group fv-plugins-icon-container has-danger'>
                                                                                        <label>
                                                                                            ชื่อ - นามสกุลผู้รับสินค้า<span className='required-text'>*</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type='text'
                                                                                            className='form-control form-control-lg'
                                                                                            name='shipname'
                                                                                            value={props.shipname}
                                                                                            onChange={(e) =>
                                                                                                props.setShipName(
                                                                                                    e.target.value
                                                                                                )}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-xl-6'>
                                                                                    <div className='form-group fv-plugins-icon-container has-danger'>
                                                                                        <label>
                                                                                            เบอร์โทรศัพท์ติดต่อ<span className='required-text'>*</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type='text'
                                                                                            inputMode='tel'
                                                                                            className='form-control form-control-lg'
                                                                                            name='shipcontactnumber'
                                                                                            value={
                                                                                                props.shipcontactnumber
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                props.setShipContactnumber(
                                                                                                    e.target.value
                                                                                                )}
                                                                                            maxLength='10'
                                                                                            onInput={maxLengthCheck}
                                                                                            onKeyPress={(event) =>
                                                                                                validateNumber(event)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-xl-6'>
                                                                                    <div className='form-group fv-plugins-icon-container'>
                                                                                        <label>
                                                                                            ภูมิภาค<span className='required-text'>*</span>
                                                                                        </label>
                                                                                        <Select
                                                                                            components={{
                                                                                                Control: ShipControlComponent
                                                                                            }}
                                                                                            noOptionsMessage={() =>
                                                                                                'ไม่พบข้อมูล'}
                                                                                            value={props.shipgeo}
                                                                                            options={shipgeooptions}
                                                                                            placeholder='เลือก'
                                                                                            inputId='shipgeo'
                                                                                            name='shipgeo'
                                                                                            onChange={selectShipGeo}
                                                                                            isClearable={true}
                                                                                            menuPortalTarget={
                                                                                                document.body
                                                                                            }
                                                                                            styles={shipcustomStyles}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-xl-6'>
                                                                                    <div className='form-group fv-plugins-icon-container'>
                                                                                        <label>
                                                                                            จังหวัด<span className='required-text'>*</span>
                                                                                        </label>
                                                                                        <Select
                                                                                            components={{
                                                                                                Control: ShipControlComponent
                                                                                            }}
                                                                                            noOptionsMessage={() =>
                                                                                                'ไม่พบข้อมูล'}
                                                                                            value={props.shipprovince}
                                                                                            options={
                                                                                                shipprovinceoptions
                                                                                            }
                                                                                            placeholder='เลือก'
                                                                                            inputId='shipprovince'
                                                                                            name='shipprovince'
                                                                                            onChange={
                                                                                                selectShipProvince
                                                                                            }
                                                                                            isClearable={true}
                                                                                            menuPortalTarget={
                                                                                                document.body
                                                                                            }
                                                                                            styles={shipcustomStyles}
                                                                                            isDisabled={
                                                                                                props.shipgeo === ''
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-xl-6'>
                                                                                    <div className='form-group fv-plugins-icon-container'>
                                                                                        <label>
                                                                                            {props.shipprovince !==
                                                                                            '' ? props.shipprovince
                                                                                                .value === '1' ? (
                                                                                                'เขต'
                                                                                            ) : (
                                                                                                'อำเภอ'
                                                                                            ) : (
                                                                                                'อำเภอ / เขต'
                                                                                            )}
                                                                                            <span className='required-text'>
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <Select
                                                                                            components={{
                                                                                                Control: ShipControlComponent
                                                                                            }}
                                                                                            noOptionsMessage={() =>
                                                                                                'ไม่พบข้อมูล'}
                                                                                            value={props.shipdistrict}
                                                                                            options={
                                                                                                shipdistrictoptions
                                                                                            }
                                                                                            placeholder='เลือก'
                                                                                            inputId='shipdistrict'
                                                                                            name='shipdistrict'
                                                                                            onChange={
                                                                                                selectShipDistrict
                                                                                            }
                                                                                            isClearable={true}
                                                                                            menuPortalTarget={
                                                                                                document.body
                                                                                            }
                                                                                            styles={shipcustomStyles}
                                                                                            isDisabled={
                                                                                                props.shipprovince ===
                                                                                                ''
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-xl-6'>
                                                                                    <div className='form-group fv-plugins-icon-container'>
                                                                                        <label>
                                                                                            {props.shipprovince !==
                                                                                            '' ? props.shipprovince
                                                                                                .value === '1' ? (
                                                                                                'แขวง'
                                                                                            ) : (
                                                                                                'ตำบล'
                                                                                            ) : (
                                                                                                'ตำบล / แขวง'
                                                                                            )}
                                                                                            <span className='required-text'>
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <Select
                                                                                            components={{
                                                                                                Control: ShipControlComponent
                                                                                            }}
                                                                                            noOptionsMessage={() =>
                                                                                                'ไม่พบข้อมูล'}
                                                                                            value={
                                                                                                props.shipsubdistrict
                                                                                            }
                                                                                            options={
                                                                                                shipsubdistrictoptions
                                                                                            }
                                                                                            placeholder='เลือก'
                                                                                            inputId='shipsubdistrict'
                                                                                            name='shipsubdistrict'
                                                                                            onChange={
                                                                                                selectShipSubDistrict
                                                                                            }
                                                                                            isClearable={true}
                                                                                            menuPortalTarget={
                                                                                                document.body
                                                                                            }
                                                                                            styles={shipcustomStyles}
                                                                                            isDisabled={
                                                                                                props.shipdistrict ===
                                                                                                ''
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-xl-6'>
                                                                                    <div className='form-group fv-plugins-icon-container'>
                                                                                        <label>
                                                                                            รหัสไปรษณีย์<span className='required-text'>*</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type='text'
                                                                                            inputMode='tel'
                                                                                            className='form-control form-control-lg'
                                                                                            name='shipzipcode'
                                                                                            maxLength='5'
                                                                                            onInput={maxLengthCheck}
                                                                                            onKeyPress={(event) =>
                                                                                                validateNumber(event)}
                                                                                            onChange={(e) =>
                                                                                                props.setShipZipcode(
                                                                                                    e.target.value
                                                                                                )}
                                                                                            value={props.shipzipcode}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='form-group fv-plugins-icon-container'>
                                                                                <label>
                                                                                    บ้านเลขที่ ถนน ซอย<span className='required-text'>*</span>
                                                                                </label>
                                                                                <TextareaAutosize
                                                                                    className='form-control form-control-lg textarea-min-h'
                                                                                    name='shipaddress'
                                                                                    onChange={(e) =>
                                                                                        props.setShipAddress(
                                                                                            e.target.value
                                                                                        )}
                                                                                    value={props.shipaddress}
                                                                                />
                                                                            </div>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                                        <div className='mr-2' />
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
