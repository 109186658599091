import React, { useEffect, useState } from 'react';
import { getQueryVariable, toAbsoluteUrl, SwalBs } from '../../../../../_metronic/_helpers';
import {
    SubscriberAgreement,
    SimkitPackage,
    SubscriberResult,
    SimkitShipping,
    SubscriberSummary,
    SimkitRegister,
    SimkitShowNumber
} from './components';
import { config } from '../../../../config';
import { useLocation } from 'react-router-dom';

export function SimkitMain(props) {
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('agreement');
    const [ edit, setEdit ] = useState(false);
    // agreement
    const [ agreement, setAgreement ] = useState(false);
    // package
    const [ selectpackage, setSelectPackage ] = useState('');
    const [ packageobject, setPackageObject ] = useState({});
    // register
    const [ name, setName ] = useState('');
    const [ birthday, setBirthday ] = useState('');
    const [ personalid, setPersonalid ] = useState('');
    const [ contactnumber, setContactnumber ] = useState('');
    const [ reference, setReference ] = useState('');
    const [ referencename, setReferencename ] = useState('');
    // shipping
    const [ shiptype, setShipType ] = useState('same');
    const [ shipname, setShipName ] = useState('');
    const [ shipcontactnumber, setShipContactnumber ] = useState('');
    const [ shipgeo, setShipGeo ] = useState('');
    const [ shipprovince, setShipProvince ] = useState('');
    const [ shipdistrict, setShipDistrict ] = useState('');
    const [ shipsubdistrict, setShipSubDistrict ] = useState('');
    const [ shipzipcode, setShipZipcode ] = useState('');
    const [ shipaddress, setShipAddress ] = useState('');
    // summary
    const [ result, setResult ] = useState({});

    const search = useLocation().search;
    const mobile = new URLSearchParams(search).get('mobile');
    const userid = new URLSearchParams(search).get('userid');

    useEffect(() => {
        document.title = 'เมืองปันสุข';
        document.getElementById('kt_body').classList.add('bg-subscriber');
        // document.getElementById('favicon').href = 'https://www.google.com/favicon.ico';
        let mobileparam = getQueryVariable(props, 'mobile');
        if (!mobileparam) {
            SwalBs.fire({
                title: 'ยินดีต้อนรับ',
                // html: '<div class="alert-text">ท่านกำลังทำการสมัครดีลเลอร์โดยไม่มีผู้แนะนำ</div>',
                html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สั่งซื้อจากดีลเลอร์</div>',
                // icon: 'info',
                imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonColor: '#0bb7af',
                confirmButtonText: 'รับทราบ',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        } else {
            if (mobileparam === '') {
                SwalBs.fire({
                    title: 'ยินดีต้อนรับ',
                    // html: '<div class="alert-text">ท่านกำลังทำการสมัครดีลเลอร์โดยไม่มีผู้แนะนำ</div>',
                    html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สั่งซื้อจากดีลเลอร์</div>',
                    // icon: 'info',
                    imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    confirmButtonColor: '#0bb7af',
                    confirmButtonText: 'รับทราบ',
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            } else {
                if (mobileparam.length !== 10 || mobileparam.charAt(0) !== '0') {
                    SwalBs.fire({
                        title: 'ยินดีต้อนรับ',
                        // html: '<div class="alert-text">ท่านกำลังทำการสมัครดีลเลอร์โดยไม่มีผู้แนะนำ</div>',
                        html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สั่งซื้อจากดีลเลอร์</div>',
                        // icon: 'info',
                        imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                        imageWidth: 80,
                        imageHeight: 80,
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'รับทราบ',
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else {
                    checkOwner();
                }
            }
        }
    }, []);

    const checkOwner = () => {
        setLoading(true);
        fetch(config.dealer_url + config.version + 'register/number-owner', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                dealer_id: userid,
                phone_number: mobile
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode !== 0) {
                    SwalBs.fire({
                        title: 'ยินดีต้อนรับ',
                        // html: '<div class="alert-text">ท่านกำลังทำการสมัครดีลเลอร์โดยไม่มีผู้แนะนำ</div>',
                        html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สั่งซื้อจากดีลเลอร์</div>',
                        // icon: 'info',
                        imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                        imageWidth: 80,
                        imageHeight: 80,
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'รับทราบ',
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    title: 'ยินดีต้อนรับ',
                    html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สมัครจากผู้แนะนำ</div>',
                    // icon: 'info',
                    imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    return (
        <div className='subscriber-register'>
            {loading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            <div>
                {page === 'agreement' ? (
                    <SubscriberAgreement
                        agreement={agreement}
                        setAgreement={setAgreement}
                        setPage={setPage}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'package' ? (
                    <SimkitPackage
                        config={config}
                        loading={loading}
                        setLoading={setLoading}
                        selectpackage={selectpackage}
                        setSelectPackage={setSelectPackage}
                        setPackageObject={setPackageObject}
                        setPage={setPage}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'show' ? (
                    <SimkitShowNumber mobile={mobile} setPage={setPage} SwalBs={SwalBs} toAbsoluteUrl={toAbsoluteUrl} />
                ) : page === 'register' ? (
                    <SimkitRegister
                        edit={edit}
                        config={config}
                        setLoading={setLoading}
                        setPage={setPage}
                        userid={userid}
                        mobile={mobile}
                        name={name}
                        setName={setName}
                        birthday={birthday}
                        setBirthday={setBirthday}
                        personalid={personalid}
                        setPersonalid={setPersonalid}
                        contactnumber={contactnumber}
                        setContactnumber={setContactnumber}
                        reference={reference}
                        setReference={setReference}
                        setReferencename={setReferencename}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'shipping' ? (
                    <SimkitShipping
                        shiptype={shiptype}
                        setShipType={setShipType}
                        shipname={shipname}
                        setShipName={setShipName}
                        shipcontactnumber={shipcontactnumber}
                        setShipContactnumber={setShipContactnumber}
                        shipgeo={shipgeo}
                        setShipGeo={setShipGeo}
                        shipprovince={shipprovince}
                        setShipProvince={setShipProvince}
                        shipdistrict={shipdistrict}
                        setShipDistrict={setShipDistrict}
                        shipsubdistrict={shipsubdistrict}
                        setShipSubDistrict={setShipSubDistrict}
                        shipzipcode={shipzipcode}
                        setShipZipcode={setShipZipcode}
                        shipaddress={shipaddress}
                        setShipAddress={setShipAddress}
                        setPage={setPage}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'summary' ? (
                    <SubscriberSummary
                        setEdit={setEdit}
                        config={config}
                        setLoading={setLoading}
                        mobile={mobile}
                        name={name}
                        birthday={birthday}
                        personalid={personalid}
                        contactnumber={contactnumber}
                        reference={reference}
                        referencename={referencename}
                        shiptype={shiptype}
                        shipname={shipname}
                        shipcontactnumber={shipcontactnumber}
                        shipgeo={shipgeo}
                        shipprovince={shipprovince}
                        shipdistrict={shipdistrict}
                        shipsubdistrict={shipsubdistrict}
                        shipzipcode={shipzipcode}
                        shipaddress={shipaddress}
                        selectpackage={selectpackage}
                        packageobject={packageobject}
                        setResult={setResult}
                        setPage={setPage}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : (
                    <SubscriberResult result={result} SwalBs={SwalBs} toAbsoluteUrl={toAbsoluteUrl} />
                )}
            </div>
        </div>
    );
}
