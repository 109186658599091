export const config = {
    custom_url: 'https://payments.simk4.com/',
    dealer_url: 'https://api-v2.simk4.com/',
    // dealer_url: 'https://beta-api.simk4.com/',
    // dealer_url: 'http://localhost:5000/',
    dealer_php_url: 'https://api.simk4.com/',
    // dealer_php_url: 'https://beta-php-api.simk4.com/',
    version: 'v1/',
    custom_secret: 'HKFMHwEHTCJbMQYzVzgN8CJpFLJozj6z',
    secret: '***',
    maintain: false
};