import React, { useRef } from 'react';
import { convertBase64 } from '../../../../../../_metronic/_helpers';

export function FeelsPersonalImage(props) {
    const personal = useRef(null);

    const btnNext = () => {
        activateSim();
    };

    const handleImage = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        props.setPersonal(base64.split(',')[1]);
    };

    const updateeKyc = () => {
        fetch(props.config.dealer_php_url + 'api/onlinesim/updateekyc', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phonenumber: props.information.phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setUpdateResponse(result);
                props.setLoading(false);
                props.setPage('result');
            })
            .catch((error) => {
                props.setLoading(false);
                props.setPage('result');
            });
    };

    const activateSim = () => {
        props.setLoading(true);
        let postparam = {
            personalid: props.information.citizenid,
            firstname: props.information.firstname,
            lastname: props.information.lastname,
            birthday: props.information.birthday,
            religion: props.information.religion,
            address: props.information.address,
            dateissue: props.information.dateissue,
            dateexpire: props.information.dateexpire,
            lasercode: props.information.lasercode.split('-').join(''),
            contactnumber: '66' + props.information.contactnumber.slice(1),
            activatednumber: '66' + props.information.phonenumber.slice(1),
            imgcardid: props.frontidcard,
            imgbackcard: props.backidcard,
            imgpersonal: props.personal
        };
        fetch(props.config.custom_url + props.config.version + 'onlinesim/activesim', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: props.config.custom_secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                props.setActivateResponse(result);
                if (result.responsecode === 0) {
                    updateeKyc();
                } else {
                    props.setLoading(false);
                    props.setPage('result');
                }
            })
            .catch((error) => {
                props.setLoading(false);
                props.setPage('result');
            });
    };
    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Feels-logo'
                                    src={props.toAbsoluteUrl('/media/logos/feels-full-logo.svg')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>ลงทะเบียนซิมออนไลน์</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center' />
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>ตรวจสอบ</div>
                                                    อัตลักษณ์
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center pb-8 px-8 pb-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <form
                                                    className='form mt-0 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='pb-5'>
                                                        <div className='form-group fv-plugins-icon-container has-danger' />
                                                        <div className='row'>
                                                            <div className='col-xl-12'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <div className='text-center upload-img mt-3'>
                                                                        <img
                                                                            className={`${props.personal === ''
                                                                                ? 'd-example'
                                                                                : 'd-none'}`}
                                                                            alt='personal-preview-img'
                                                                            src={props.toAbsoluteUrl(
                                                                                '/media/misc/example-personal-image.png'
                                                                            )}
                                                                            onClick={() => {
                                                                                personal.current.click();
                                                                            }}
                                                                        />
                                                                        <div>
                                                                            <img
                                                                                className={`${props.personal === ''
                                                                                    ? 'd-none'
                                                                                    : 'd-example'}`}
                                                                                alt='personal-img'
                                                                                src={`data:image/png;base64,${props.personal}`}
                                                                                onClick={() => {
                                                                                    personal.current.click();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <p className='mt-3'>ถ่ายภาพหน้าตรง</p>
                                                                        <input
                                                                            ref={personal}
                                                                            className='d-none'
                                                                            type='file'
                                                                            accept='image/*'
                                                                            capture
                                                                            onChange={(e) => {
                                                                                handleImage(e);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center border-top mt-5 pt-10'>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success btn-feels-main font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
