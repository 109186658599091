import React from 'react';
import MaskedInput from 'react-text-mask';
import { maxLengthCheck, validateAge, validateCitizenid, validateNumber } from '../../../../../../_metronic/_helpers';

export function SimkitRegister(props) {
    const btnBack = () => {
        props.setPage('show');
    };

    const getDealerInfo = () => {
        props.setLoading(true);
        fetch(props.config.dealer_php_url + 'api/register/getdealerinfo', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phonenumber: props.reference
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                if (result.responsecode === 0) {
                    props.setReferencename(result.name);
                    props.SwalBs
                        .fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class='alert-text'>ท่านกำลังสั่งซื้อกับ <b>${result.name}</b> ใช่หรือไม่?</div>`,
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'ยืนยัน',
                            cancelButtonText: 'ยกเลิก',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        })
                        .then((result) => {
                            if (result.value) {
                                checkUser();
                            }
                        });
                } else {
                    props.SwalBs.fire({
                        title: 'ยินดีต้อนรับ',
                        html: `<div class="alert-text">ไม่พบผู้แนะนำนี้ กรุณาลองใหม่อีกครั้ง</div>`,
                        icon: 'warning',
                        // imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                        // imageWidth: 80,
                        // imageHeight: 80,
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                props.setLoading(false);
                props.SwalBs.fire({
                    title: 'ยินดีต้อนรับ',
                    html: `<div class="alert-text">ไม่สามารถตรวจสอบผู้แนะนำได้ กรุณาลองใหม่อีกครั้ง</div>`,
                    icon: 'error',
                    // imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                    // imageWidth: 80,
                    // imageHeight: 80,
                    confirmButtonColor: '#0bb7af',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const btnNext = () => {
        let message = '';
        if (props.name === '' && message === '') {
            message = 'กรุณากรอกชื่อ - นามสกุล';
        }
        if (props.birthday === '' && message === '') {
            message = 'กรุณากรอกวัน/เดือน/ปีเกิด';
        } else if (props.birthday !== '' && message === '') {
            if (props.birthday.includes('_')) {
                message = 'กรุณากรอกวัน/เดือน/ปีเกิดให้ครบ';
            } else {
                let birthdatelist = props.birthday.split('/');
                let newBirthday = birthdatelist[2] + '-' + birthdatelist[1] + '-' + birthdatelist[0];
                let maxdate = new Date(parseInt(birthdatelist[2]), parseInt(birthdatelist[1]), 0).getDate();
                let checkAge = validateAge(newBirthday, 7);
                if (parseInt(birthdatelist[1]) > 12 || parseInt(birthdatelist[1]) < 0) {
                    message = 'กรุณากรอกเดือนเกิดให้ถูกต้อง';
                }
                if (parseInt(birthdatelist[2] !== 0)) {
                    if (parseInt(birthdatelist[0]) > maxdate || parseInt(birthdatelist[0]) < 0) {
                        message = 'กรุณากรอกวันเกิดให้ถูกต้อง';
                    }
                } else {
                    if (parseInt(birthdatelist[1]) !== 2) {
                        if (parseInt(birthdatelist[0]) > maxdate || parseInt(birthdatelist[0]) < 0) {
                            message = 'กรุณากรอกวันเกิดให้ถูกต้อง';
                        }
                    }
                }
            }
        }
        if (props.personalid === '' && message === '') {
            message = 'กรุณากรอกหมายเลขบัตรประชาชน';
        } else if (props.personalid !== '' && message === '') {
            if (props.personalid.length !== 13) {
                message = 'กรุณากรอกหมายเลขบัตรประชาชนให้ครบ 13 หลัก';
            } else {
                let citizenidstatus = validateCitizenid(props.personalid);
                if (!citizenidstatus) {
                    message = 'กรุณากรอกหมายเลขบัตรประชาชนให้ถูกต้อง';
                }
            }
        }
        if (props.contactnumber === '' && message === '') {
            message = 'กรุณากรอกเบอร์โทรศัพท์ติดต่อ';
        } else if (props.contactnumber !== '' && message === '') {
            if (props.contactnumber.length !== 10 || props.contactnumber.charAt(0) !== '0') {
                message = 'กรุณากรอกเบอร์โทรศัพท์ติดต่อให้ถูกต้อง';
            }
        }
        if (props.reference === '' && message === '') {
            message = 'กรุณากรอกเบอร์ผู้แนะนำ';
        } else if (props.reference !== '' && message === '') {
            if (props.reference.length !== 10 || props.reference.charAt(0) !== '0') {
                message = 'กรุณากรอกเบอร์เบอร์ผู้แนะนำให้ถูกต้อง';
            }
        }
        if (message !== '') {
            props.SwalBs.fire({
                // title: 'ผิดพลาด!',
                html: `<div class='alert-text'>${message}</div>`,
                icon: 'warning',
                confirmButtonColor: '#ffa800',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        getDealerInfo();
    };

    const checkUser = () => {
        props.setLoading(true);
        fetch(props.config.dealer_php_url + 'api/register/existidcard', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idcard: props.personalid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                if (result.responsecode === 0) {
                    if (!props.edit) {
                        props.setPage('shipping');
                    } else {
                        props.setPage('summary');
                    }
                } else if (result.responsecode === 100) {
                    let message = 'กรุณากรอกหมายเลขบัตรประชาชนอื่น เนื่องจากหมายเลขนี้ถูกใช้งานแล้ว';
                    props.SwalBs.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class='alert-text'>${message}</div>`,
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else {
                    let message = 'ไม่สามารถตรวจสอบเลขบัตรประชาชนได้ชั่วคราว กรุณาลองใหม่อีกครั้ง';
                    props.SwalBs.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class='alert-text'>${message}</div>`,
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                props.setLoading(false);
                let message = 'ไม่สามารถตรวจสอบเลขบัตรประชาชนได้ชั่วคราว กรุณาลองใหม่อีกครั้ง';
                props.SwalBs.fire({
                    // title: 'ผิดพลาด!',
                    html: `<div class='alert-text'>${message}</div>`,
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครผู้ใช้งานใหม่</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div> */}
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครผู้ใช้งานใหม่</div>
                                                    รายละเอียดผู้สั่งซื้อ
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <form
                                                    className='form mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='pb-5'>
                                                        <div className='row'>
                                                            <div className='col-12 col-lg-6'>
                                                                <div className='form-group fv-plugins-icon-container has-danger'>
                                                                    <label>
                                                                        ชื่อ - นามสกุล
                                                                        <span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='name'
                                                                        onChange={(e) => props.setName(e.target.value)}
                                                                        value={props.name}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-12 col-lg-6'>
                                                                <div className='form-group'>
                                                                    <label>
                                                                        วัน/เดือน/ปีเกิด (ปีคริสต์ศักราช)
                                                                        <span className='required-text'>*</span>
                                                                    </label>
                                                                    <div className='form-group fv-plugins-icon-container has-danger'>
                                                                        <MaskedInput
                                                                            mask={[
                                                                                /[0-9]/,
                                                                                /[0-9]/,
                                                                                '/',
                                                                                /[0-9]/,
                                                                                /[0-9]/,
                                                                                '/',
                                                                                /[0-9]/,
                                                                                /[0-9]/,
                                                                                /[0-9]/,
                                                                                /[0-9]/
                                                                            ]}
                                                                            inputMode='tel'
                                                                            className='form-control form-control-lg'
                                                                            name='birthday'
                                                                            keepCharPositions={true}
                                                                            onChange={(e) =>
                                                                                props.setBirthday(e.target.value)}
                                                                            value={props.birthday}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12 col-lg-6'>
                                                                <div className='form-group fv-plugins-icon-container has-danger'>
                                                                    <label>
                                                                        หมายเลขบัตรประจำตัวประชาชน
                                                                        <span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='personalid'
                                                                        onChange={(e) =>
                                                                            props.setPersonalid(e.target.value)}
                                                                        value={props.personalid}
                                                                        maxLength='13'
                                                                        onInput={maxLengthCheck}
                                                                        onKeyPress={(event) => {
                                                                            validateNumber(event);
                                                                        }}
                                                                        onPaste={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                        onCopy={(e) => {
                                                                            e.preventDefault();
                                                                            return false;
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-12 col-lg-6'>
                                                                <div className='form-group fv-plugins-icon-container has-danger'>
                                                                    <label>
                                                                        เบอร์โทรศัพท์ติดต่อ
                                                                        <span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        inputMode='tel'
                                                                        className='form-control form-control-lg'
                                                                        name='contactnumber'
                                                                        onChange={(e) =>
                                                                            props.setContactnumber(e.target.value)}
                                                                        value={props.contactnumber}
                                                                        maxLength='10'
                                                                        onInput={maxLengthCheck}
                                                                        onKeyPress={(event) => {
                                                                            validateNumber(event);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label>
                                                                        เบอร์ผู้แนะนำ
                                                                        <span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        inputMode='tel'
                                                                        className='form-control form-control-lg'
                                                                        name='reference'
                                                                        value={props.reference}
                                                                        onChange={(e) =>
                                                                            props.setReference(e.target.value)}
                                                                        maxLength='10'
                                                                        onInput={maxLengthCheck}
                                                                        onKeyPress={(event) => {
                                                                            validateNumber(event);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                                        <div className='mr-2'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-light-primary font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnBack}
                                                            >
                                                                ก่อนหน้า
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
