import React from 'react';

export function SimkitShowNumber(props) {
    const btnNext = () => {
        props.SwalBs.fire({
            // title: 'ผิดพลาด!',
            html: `<div class='alert-text'>ยืนยันคำสั่งซื้อเบอร์นี้หรือไม่ <b>${props.mobile}</b> ใช่หรือไม่?</div>`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
        }).then((result) => {
            if (result.value) {
                props.setPage('register');
            }
        });
    };

    const btnBack = () => {
        props.setPage('package');
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครผู้ใช้งานใหม่</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div> */}
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครผู้ใช้งานใหม่</div>
                                                    เบอร์ที่คุณสั่งซื้อ
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <div className='mt-0 mt-lg-10'>
                                                    <div className='row justify-content-center'>
                                                        <div className='col-12 col-lg-8 row'>
                                                            <div className='col-12'>
                                                                <h4>
                                                                    <b>เบอร์ของคุณ</b>
                                                                </h4>
                                                            </div>
                                                            <div className='d-flex justify-content-center col-12'>
                                                                <div className='h1' style={{ color: '#A93226' }}>
                                                                    {props.mobile}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                                        <div className='mr-2'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-light-primary font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnBack}
                                                            >
                                                                ก่อนหน้า
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
