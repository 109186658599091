import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SVG from 'react-inlinesvg';

export function DealerAgreement(props) {
    const btnNext = () => {
        props.setPage('information');
        // props.setPage('address');
        // props.setPage('shipping');
        // props.setPage('summary');
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครดีลเลอร์ใหม่</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg mr-2'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <form id='login-form' className='nobottommargin'>
                        <div
                            className='card card-custom card-stretch example example-compact'
                            id='kt_page_stretched_card'
                        >
                            <div className='card-header'>
                                <div className='card-title'>
                                    <h3 className='card-label'>
                                        Dealer <small>สัญญาแต่งตั้งตัวแทนจำหน่าย</small>
                                    </h3>
                                </div>
                                <div className='card-toolbar' />
                            </div>
                            <div className='card-body'>
                                <OverlayScrollbarsComponent className='agreement-body'>
                                    <div className='p-5 agreement-bg'>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สัญญาฉบับนี้จัดทำขึ้นเมื่อวันที่
                                            [•] ณ <b>บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด</b> สำนักงานตั้งอยู่เลขที่
                                            21/111-112 ซอยศูนย์วิจัย ถนนพระราม 9 แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ระหว่าง{' '}
                                            <b>บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด</b> สำนักงานตั้งอยู่เลขที่ 21/111-112
                                            ซอยศูนย์วิจัย ถนนพระราม 9 แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร
                                            ซึ่งต่อไปในสัญญาฉบับนี้ให้เรียกว่า <b>“บริษัท”</b> ฝ่ายหนึ่ง
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กับ
                                            นาย/นาง/นางสาว [•] /บริษัท [•] จำกัด โดย [•] กรรมการผู้มีอำนาจ
                                            อยู่บ้านเลขที่ / สำนักงานตั้งอยู่เลขที่ [•]
                                            ซึ่งต่อไปในสัญญาฉบับนี้ให้เรียกว่า <b>“ตัวแทนจำหน่าย”</b> อีกฝ่ายหนึ่ง
                                            และทั้งสองฝ่ายในสัญญาฉบับนี้ให้เรียกว่า “คู่สัญญา”
                                        </p>
                                        <p>คู่สัญญาตกลงทำสัญญากัน ดังมีข้อความต่อไปนี้</p>
                                        <p>
                                            <b>1. คำจำกัดความ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;คำหรือข้อความให้มีความหมายที่กำหนดไว้ในสัญญาฉบับนี้
                                            เว้นแต่มีคำหรือข้อความในสัญญาฉบับนี้แสดงให้เห็นว่ามีความหมายเป็นอย่างอื่น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>“โครงการ”</b>
                                            หมายถึง โครงการที่บริษัทหรือบริษัทในเครือจัดตั้งขึ้น
                                            ตามวัตถุประสงค์ที่บริษัทจะได้แจ้งให้ตัวแทนจำหน่ายทราบเป็นคราวๆ ไป
                                            เพื่อจัดจำหน่ายสินค้าและบริการ และผลิตภัณฑ์ของบริษัท
                                            หรือเพื่อประโยชน์อื่นใดที่บริษัทได้จัดตั้งขึ้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>“ผู้ใช้บริการ”</b>
                                            หมายถึง ผู้ใช้บริการโทรศัพท์เคลื่อนที่โครงข่ายเสมือนสัญญาณ Feels
                                            ตามสัญญานี้ภายในโครงการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>“ผลิตภัณฑ์ของบริษัท”</b>
                                            หมายถึง
                                            สินค้าและ/หรือบริการตามรายละเอียดของสินค้าและ/หรือบริการที่บริษัทจะประกาศให้ตัวแทนจำหน่ายทราบเป็นครั้งคราว
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>“ตลาด”</b>
                                            หมายถึง เขตราชอาณาจักรไทย หรือเขตอื่นๆ ที่บริษัทประกาศให้ตัวแทนจำหน่ายทราบ
                                        </p>
                                        <p>
                                            <b>2. บททั่วไป</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“บริษัท”
                                            เป็นผู้ประกอบกิจการโทรคมนาคม
                                            และเป็นผู้จัดจำหน่ายผลิตภัณฑ์ของบริษัทรวมถึงบริษัทพันธมิตรและบริษัทในเครือ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“ตัวแทนจำหน่าย”
                                            เป็นบุคคลหรือนิติบุคคล ที่มีความรู้ความสามารถ
                                            มีความพร้อมด้านบุคลากรและองค์ประกอบอื่นใดซึ่งจะต้องมีคุณสมบัติที่เหมาะสม
                                            และสามารถดำเนินการเพื่อให้บรรลุวัตถุประสงค์ร่วมกับบริษัทได้อย่างมีประสิทธิภาพ
                                            อีกทั้งตัวแทนจำหน่ายต้องไม่เป็นบุคคลล้มละลายและ/หรือไม่เป็นผู้ที่เคยถูกเพิกถอนการเป็นตัวแทนจำหน่ายมาก่อน
                                        </p>
                                        <p>
                                            <b>
                                                3. การแต่งตั้ง ระยะเวลาการเป็นตัวแทนจำหน่าย และการต่ออายุตัวแทนจำหน่าย
                                            </b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัทขอแต่งตั้งให้
                                            “นาย/นาง/นางสาว [•] / บริษัท [•] จำกัด” เป็นตัวแทนจำหน่ายของบริษัท
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การเป็นตัวแทนจำหน่ายให้มีผลนับตั้งแต่วันที่ลงนามในสัญญาฉบับนี้และให้สิ้นอายุเมื่อครบ
                                            1 ปี นับตั้งแต่วันทำสัญญาฉบับนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ตัวแทนจำหน่ายที่ประสงค์จะเป็นตัวแทนจำหน่ายต่อไป
                                            ให้ดําเนินการขอต่ออายุก่อนวันสิ้นอายุไม่น้อยกว่าสามสิบวัน
                                            และต้องปฏิบัติตามหลักเกณฑ์ วิธีการ และขั้นตอนที่บริษัทกําหนด
                                            หากไม่ดำเนินการต่ออายุก่อนครบกำหนดระยะเวลาดังกล่าว
                                            ให้ถือว่าพ้นสภาพจากการเป็นตัวแทนจำหน่ายนับแต่วันสิ้นอายุนั้น
                                            และห้ามมิให้ดำเนินการใดๆ ในฐานะตัวแทนจำหน่ายหรือฐานะอื่นใดของบริษัทอีกต่อไป
                                            และตัวแทนจำหน่ายขอสละสิทธิทั้งหลายซึ่งขวนขวายได้มาในขณะเป็นตัวแทนจำหน่ายนั้น
                                            และตัวแทนจำหน่ายขอโอนสิทธิดังกล่าวให้แก่บริษัททั้งสิ้น
                                        </p>
                                        <p>
                                            <b>4. เป้าหมาย</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การแต่งตั้งตัวแทนจำหน่ายตามสัญญาฉบับนี้
                                            มีวัตถุประสงค์เพื่อเป็นการเพิ่มช่องทางการขยายฐานลูกค้าและผู้ใช้บริการและสร้างยอดการจำหน่ายผลิตภัณฑ์ของบริษัท
                                            โดยตัวแทนจำหน่ายจะต้องประชาสัมพันธ์และจำหน่ายผลิตภัณฑ์ของบริษัทตามที่บริษัทจะประกาศให้ตัวแทนจำหน่ายทราบเป็นครั้งคราว
                                        </p>
                                        <p>
                                            <b>5. หน้าที่</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1{' '}
                                            <b>บริษัท</b> มีหน้าที่สนับสนุนการดำเนินงานของตัวแทนจำหน่าย ดังต่อไปนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.1
                                            จัดทำแผนการตลาด ข้อมูลและเอกสารที่จำเป็น
                                            ซึ่งบริษัทจะประกาศให้ตัวแทนจำหน่ายทราบ
                                            รวมตลอดถึงการจัดการโฆษณาส่งเสริมการขายและข้อมูลทางเทคนิค
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.2
                                            จัดเตรียมบุคลากรเท่าที่จำเป็นเพื่อให้คำแนะนำกับตัวแทนจำหน่ายในการแก้ไขปัญหาต่างๆ
                                            ที่เกิดจากการจำหน่ายผลิตภัณฑ์ของบริษัท
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.3
                                            กำหนดแนวทางการสื่อสารและจัดอบรมให้ความรู้ทางเทคนิคแก่พนักงานหรือบุคคลใดที่ได้รับมอบหมายจากตัวแทนจำหน่าย
                                            เพื่อให้มีความรู้ความเข้าใจที่ตรงกันในผลิตภัณฑ์ของบริษัท
                                            รวมถึงแนวทางและเทคนิคการจำหน่าย ทั้งนี้ จำนวนผู้เข้ารับการฝึกอบรม สถานที่
                                            วันเวลาฝึกอบรม บริษัทจะประกาศให้ตัวแทนจำหน่ายทราบเป็นครั้งคราว
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.4
                                            จัดสรรหรือกำหนดเขตพื้นที่ตลาดในการดำเนินการของตัวแทนจำหน่ายเพื่อมิให้เกิดความขัดแย้งระหว่างตัวแทนจำหน่ายด้วยกัน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1.5
                                            กำหนดผลตอบแทนให้กับตัวแทนจำหน่ายตามเงื่อนไขที่ได้กำหนดไว้
                                            โดยบริษัทจะประกาศให้ตัวแทนจำหน่ายทราบเป็นครั้งคราวตามที่บริษัทกำหนด
                                            ซึ่งจะพิจารณาตามกลไกการตลาดและอาจปรับปรุงแก้ไขได้เสมอโดยไม่ต้องได้รับความยินยอมจากตัวแทนจำหน่ายเสียก่อน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2{' '}
                                            <b>ตัวแทนจำหน่าย</b>{' '}
                                            มีหน้าที่ปฏิบัติตามประกาศและแผนการตลาดของบริษัทอย่างเคร่งครัด ดังต่อไปนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2.1
                                            ตัวแทนจำหน่ายมีหน้าที่จำหน่ายผลิตภัณฑ์ของบริษัท
                                            รวมถึงสินค้าและบริการอื่นที่จะเกิดขึ้นในอนาคตตามหลักเกณฑ์ เงื่อนไข ราคา
                                            และโปรโมชั่นที่บริษัทกำหนด
                                            ซึ่งบริษัทจะประกาศให้ตัวแทนจำหน่ายทราบเป็นครั้งคราว
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2.2
                                            ตัวแทนจำหน่ายมีหน้าที่รายงานผลการดำเนินการในเขตพื้นที่ที่รับผิดชอบตามหลักเกณฑ์
                                            วิธีการ ขั้นตอน และระยะเวลาที่บริษัทกําหนด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2.3
                                            ตัวแทนจำหน่ายมีหน้าที่และ/หรือส่งบุคลากร เข้ารับการอบรมตามที่บริษัทกำหนด
                                            เพื่อให้มีความรู้ความเข้าใจที่ตรงกัน
                                            สร้างความเข้าใจในหลักการขายและหน้าที่ที่ต้องพึงระวัง
                                            รวมถึงแนวทางและเทคนิคการจำหน่ายตลอดจนเสริมสร้างความรู้ความเข้าใจในผลิตภัณฑ์ของบริษัทให้แก่ลูกค้าและผู้ใช้บริการ
                                            และตัวแทนจำหน่ายจะต้องเข้าร่วมหรือส่งบุคลากรเข้าร่วมกิจกรรมการส่งเสริมการขายหรือกิจกรรมอื่นๆ
                                            ตามที่บริษัทร้องขอ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2.4
                                            ตัวแทนจำหน่ายต้องจัดเตรียมพื้นที่และอุปกรณ์ต่างๆ
                                            ที่เกี่ยวข้องกับการจำหน่ายผลิตภัณฑ์ของบริษัท
                                            ตลอดจนจัดหาบุคลากรที่มีความรู้ความสามารถ
                                            มีประสบการณ์ในการจำหน่ายสินค้าและ/หรือบริการ
                                            ซึ่งต้องเป็นผู้ผ่านการฝึกอบรมจากบริษัทตามระยะเวลาที่บริษัทกำหนดในจำนวนที่เพียงพอ
                                            และพร้อมเปิดดำเนินการก่อนการแต่งตั้งเป็นตัวแทนจำหน่ายของบริษัทตามสัญญานี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2.5
                                            ตัวแทนจำหน่ายต้องปฏิบัติตามระเบียบ กฎเกณฑ์ และเงื่อนไขต่างๆ
                                            ที่เกี่ยวข้องกับการเป็นตัวแทนจำหน่ายของบริษัท
                                            ซึ่งบริษัทจะได้แจ้งเป็นประกาศให้ตัวแทนจำหน่ายทราบ
                                            และตัวแทนจำหน่ายจะต้องรักษาผลการดำเนินการการประกอบธุรกิจตามวัตถุประสงค์ของสัญญาฉบับนี้ให้อยู่ในระดับที่เหมาะสมด้วยการจำหน่ายอย่างมีประสิทธิภาพ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2.6
                                            ต้องดูแลลูกค้าและผู้ใช้บริการอยู่เสมอ
                                            และต้องพร้อมที่จะตอบข้อสงสัยได้อยู่ตลอด สามารถอธิบาย หมั่นเข้าไปแสดงตน
                                            ให้คำแนะนำ
                                            ให้ความรู้เกี่ยวกับผลิตภัณฑ์ของบริษัทได้อย่างถูกต้องต่อลูกค้าและผู้ใช้บริการ
                                            และจะต้องรักษาความลับระหว่างบริษัท ลูกค้าและผู้ใช้บริการไว้เป็นสำคัญ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2.7
                                            ต้องจำหน่ายผลิตภัณฑ์ของบริษัทตามที่บริษัทกำหนดและต้องแจ้งรายละเอียดที่เป็นความจริงให้ลูกค้าและผู้ใช้บริการเท่านั้น
                                            ตัวแทนจำหน่ายไม่มีสิทธิในการลดหรือเพิ่มราคาสินค้า
                                            ค่าบริการหรือการเสนอผลตอบแทนอื่นใดด้วยตนเองทั้งสิ้น
                                            นอกเสียจากบริษัทเป็นผู้กำหนดขึ้นมาใหม่หรืออยู่ในช่วงโปรโมชั่นที่บริษัทจะประกาศให้ตัวแทนจำหน่ายทราบเป็นลายลักษณ์อักษรเท่านั้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2.8
                                            ตัวแทนจำหน่าย พนักงาน ลูกจ้าง หรือตัวแทนของตัวแทนจำหน่าย ไม่มีสิทธิใช้ตรา
                                            สัญลักษณ์ เครื่องหมายการค้า
                                            เครื่องหมายอื่นใดของบริษัทหรือบริษัทพันธมิตรหรือบริษัทในเครือ
                                            เพื่อวัตถุประสงค์อื่นใดนอกจากการปฏิบัติงานตามสัญญาฉบับนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2.9
                                            การจองพื้นที่โครงการหรือตลาดที่มุ่งหวังให้เป็นไปตามหลักเกณฑ์ที่บริษัทกำหนด
                                        </p>
                                        <p>
                                            <b>6. ผลประโยชน์ที่ตัวแทนจำหน่ายจะได้รับ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ตัวแทนจำหน่ายตกลงยอมรับผลตอบแทนจากการดำเนินการประชาสัมพันธ์และการจำหน่ายผลิตภัณฑ์ของบริษัทตามที่บริษัทได้ประกาศให้ตัวแทนจำหน่ายทราบเป็นครั้งคราว
                                            และตามผลงานที่ตัวแทนจำหน่ายสามารถดำเนินการได้
                                            ซึ่งบริษัทจะเป็นผู้กําหนดอัตราผลตอบแทนและแจ้งให้ตัวแทนจำหน่ายทราบเป็นคราวๆ
                                            ไป
                                            โดยจะพิจารณาตามกลไกการตลาดและอาจปรับปรุงแก้ไขได้อยู่เสมอโดยไม่ต้องได้รับความยินยอมจากตัวแทนจำหน่ายเสียก่อน
                                        </p>
                                        <p>
                                            <b>7. เงื่อนไขในการชำระเงิน</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การจ่ายเงินผลตอบแทนให้กับตัวแทนจำหน่ายให้เป็นไปตามเงื่อนไขและรายละเอียดที่บริษัทจะได้แจ้งให้ตัวแทนจำหน่ายทราบเป็นคราวๆ
                                            ไป และให้เป็นไปตามข้อกำหนดการรับวางบิล และการจ่ายตามหลักเกณฑ์ วิธีการ
                                            ขั้นตอน และระยะเวลาที่บริษัทกําหนด
                                        </p>
                                        <p>
                                            <b>8. กฎเกณฑ์</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1
                                            ตัวแทนจำหน่ายจะไม่จำหน่ายผลิตภัณฑ์ของบริษัทต่างจากราคาที่บริษัทกำหนด
                                            รวมถึงการจำหน่ายนอกพื้นที่ หรือนอกเหนือวิธีการที่บริษัทกำหนด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.2
                                            ตัวแทนจำหน่ายจะต้องจำหน่ายผลิตภัณฑ์ของบริษัทด้วยความซื่อสัตย์สุจริต
                                            ใช้ความรู้ความสามารถส่งเสริมผลิตภัณฑ์ของบริษัทให้เป็นที่แพร่หลาย
                                            และไม่กระทำการใดๆ ให้เป็นการเสื่อมเสียต่อชื่อเสียง ภาพลักษณ์
                                            และความน่าเชื่อถืออันกระทบต่อบริษัททั้งโดยตรงและโดยอ้อม
                                            หากตัวแทนจำหน่ายจำหน่ายสินค้าและ/หรือเสนอการให้บริการนอกเหนือมาตรฐานตามข้อกำหนดและ/หรือไม่ใช่ผลิตภัณฑ์ของบริษัท
                                            บริษัทจะไม่รับผิดชอบต่อความเสียหายใดๆ
                                            ที่อาจเกิดขึ้นต่อตัวแทนจำหน่ายและ/หรือบุคคลภายนอก
                                            ตัวแทนจำหน่ายต้องรับผิดชอบต่อความเสียหายนั้นเพียงฝ่ายเดียว
                                            และตัวแทนจำหน่ายจะต้องรับผิดชอบต่อความเสียหายดังกล่าวให้แก่บริษัทอีกด้วย
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.3
                                            ตัวแทนจำหน่ายต้องไม่จำหน่ายผลิตภัณฑ์อื่นใดที่เป็นลักษณะ
                                            ประเภทและ/หรือชนิดอันมีสภาพอย่างเดียวกันและ/หรือเป็นการแข่งขันกับการจำหน่ายผลิตภัณฑ์ของบริษัท
                                            ไม่ว่าตัวแทนจำหน่ายจะได้กระทำการนั้นด้วยตนเองหรือบุคคลอื่นกระทำการแทน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.4
                                            ตัวแทนจำหน่ายจะไม่กระทำการเป็นตัวการสนับสนุน หรือร่วมกับผู้ใดกระทำการปลอม
                                            แปลง ทำซ้ำ ดัดแปลง ลอกเลียนแบบ สับเปลี่ยน แปรสภาพ
                                            หรือกระทำการใดทำนองเดียวกัน เพื่อให้โครงการ ลูกค้า ผู้ใช้บริการ
                                            หรือบุคคลทั่วไปเกิดความเข้าใจผิดคิดว่าเป็นผลิตภัณฑ์ของบริษัท
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.5
                                            ตัวแทนจำหน่ายต้องรักษาข้อมูลที่ตัวแทนจำหน่ายได้รับจากบริษัท โครงการ
                                            ลูกค้าและผู้ใช้บริการ
                                            รวมถึงข้อมูลเอกสารที่เกี่ยวข้องตามสัญญาฉบับนี้ไว้เป็นความลับไม่เปิดเผยให้แก่ผู้ใดทราบ
                                            เว้นแต่จะได้รับการอนุญาตเป็นลายลักษณ์อักษรจากบริษัทเสียก่อน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.6
                                            ตัวแทนจำหน่ายยอมรับว่าการประกอบธุรกิจตามสัญญาฉบับนี้
                                            เป็นการแต่งตั้งตัวแทนจำหน่ายแบบทั่วไป (Non-Exclusive Distributor)
                                            ไม่ใช่การให้สิทธิแต่เพียงผู้เดียว (Exclusive Distributor)
                                        </p>
                                        <p>
                                            <b>9. การเลิกสัญญา</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สัญญาฉบับนี้เป็นอันสิ้นสุดลงในกรณีดังต่อไปนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.1
                                            สิ้นอายุตามกำหนดระยะเวลาของสัญญาฉบับนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ตัวแทนจำหน่ายอาจสิ้นสภาพการเป็นตัวแทนจำหน่ายก่อนสิ้นอายุก็ย่อมได้
                                            เมื่อปรากฏว่าตัวแทนจำหน่ายมีคุณสมบัติไม่เหมาะสมและประสิทธิภาพในการจำหน่ายไม่เป็นไปตามวัตถุประสงค์และไม่บรรลุเป้าหมายที่บริษัทกำหนด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.2
                                            หากตัวแทนจำหน่ายผิดสัญญาข้อหนึ่งข้อใดและบริษัทได้แจ้งให้แก้ไขภายในระยะเวลาที่กำหนดแล้วนั้น
                                            แต่ไม่แก้ไข หรือแก้ไขไม่แล้วเสร็จ
                                            บริษัทมีสิทธิบอกเลิกสัญญาได้โดยทันทีเมื่อครบกำหนดระยะเวลาให้ดำเนินการแก้ไข
                                            และไม่ตัดสิทธิบริษัทในการเรียกร้องค่าเสียหายที่เกิดขึ้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.3
                                            ในกรณีบริษัทประสงค์จะบอกเลิกสัญญาก่อนครบกำหนดระยะเวลาตามสัญญาฉบับนี้ไม่ว่าทั้งหมดหรือบางส่วน
                                            บริษัทจะแจ้งเป็นลายลักษณ์อักษรให้ตัวแทนจำหน่ายทราบล่วงหน้าอย่างน้อย 14 วัน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.4
                                            คู่สัญญาฝ่ายใดฝ่ายหนึ่งจงใจ หรือประมาทเลินเล่อ
                                            อันเป็นเหตุให้คู่สัญญาอีกฝ่ายหนึ่งได้รับความเสียหาย หรือถูกยึดอายัดทรัพย์
                                            หรือตกเป็นบุคคลล้มละลาย หรือถูกพิทักษ์ทรัพย์ไม่ว่าเด็ดขาดหรือชั่วคราว
                                            หรือมีหนี้สินล้นพ้นตัว หรือเข้าสู่กระบวนการฟื้นฟูกิจการ
                                            หรือไม่สามารถประกอบกิจการต่อไปได้
                                        </p>
                                        <p>
                                            <b>10. การส่งคำบอกกล่าว</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การส่งคำบอกกล่าว
                                            คู่สัญญาทั้งสองฝ่ายตกลงให้ส่งไปยังภูมิลำเนาตามที่อยู่ข้างต้นโดยการส่งไปรษณีย์ตอบรับ
                                            การนำส่งด้วยบุคคล จดหมายอิเล็กทรอนิกส์ การแจ้งตามช่องทางสื่อโซเชียล
                                            หรือการสื่อสารอื่นๆ ของบริษัท ให้ถือว่าเป็นการส่งโดยชอบด้วยกฎหมายแล้ว
                                        </p>
                                        <div className='row justify-content-center m-0'>
                                            <div className='col-12 col-lg-6 row m-0 p-0'>
                                                <div className='col-lg-3 col-12 p-0'>บริษัท:</div>
                                                <div className='col-lg-9 col-12 row m-0 p-0'>
                                                    <div className='col-2 p-0'>ชื่อ</div>
                                                    <div className='col-10 p-0'>บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด</div>
                                                </div>
                                                <div className='col-lg-9 col-12 ml-auto row m-0 p-0'>
                                                    <div className='col-2 p-0'>ที่อยู่</div>
                                                    <div className='col-10 p-0'>
                                                        21/111-112 ซอนศูนย์วิจัย ถนนพระราม 9
                                                    </div>
                                                    <div className='col-10 ml-auto p-0'>
                                                        แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310
                                                    </div>
                                                </div>
                                                <div className='col-lg-9 col-12 ml-auto row m-0 p-0'>
                                                    <div className='col-2 p-0'>โทรศัพท์</div>
                                                    <div className='col-10 p-0'>02 026 3721</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row justify-content-center m-0 my-5'>
                                            <div className='col-12 col-lg-6 row m-0 p-0'>
                                                <div className='col-lg-3 col-12 p-0'>ตัวแทนจำหน่าย:</div>
                                                <div className='col-lg-9 col-12 row m-0 p-0'>
                                                    <div className='col-2 p-0'>ชื่อ</div>
                                                    <div className='col-10 p-0'>
                                                        ..................................................................................
                                                    </div>
                                                </div>
                                                <div className='col-lg-9 col-12 ml-auto row m-0 p-0'>
                                                    <div className='col-2 p-0'>ที่อยู่</div>
                                                    <div className='col-10 p-0'>
                                                        ..................................................................................
                                                    </div>
                                                </div>
                                                <div className='col-lg-9 col-12 ml-auto row m-0 p-0'>
                                                    <div className='col-2 p-0'>โทรศัพท์</div>
                                                    <div className='col-10 p-0'>
                                                        ..................................................................................
                                                    </div>
                                                </div>
                                                <div className='col-lg-9 col-12 ml-auto row m-0 p-0'>
                                                    <div className='col-2 p-0'>โทรสาร</div>
                                                    <div className='col-10 p-0'>
                                                        ..................................................................................
                                                    </div>
                                                </div>
                                                <div className='col-lg-9 col-12 ml-auto row m-0 p-0'>
                                                    <div className='col-2 p-0'>อีเมลล์</div>
                                                    <div className='col-10 p-0'>
                                                        ..................................................................................
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีที่ฝ่ายใดฝ่ายหนึ่งมีการเปลี่ยนแปลงชื่อ
                                            ที่อยู่ หมายเลขโทรศัพท์ หมายเลขโทรสาร ตลอดจนชื่อจดหมายอิเล็กทรอนิกส์ (อีเมล)
                                            ตามที่กำหนดไว้ ให้แจ้งเป็นหนังสือให้อีกฝ่ายหนึ่งทราบภายใน 7
                                            วันนับตั้งแต่วันที่มีการเปลี่ยนแปลงดังกล่าว
                                        </p>
                                        <p>
                                            <b>11. ความไม่สมบูรณ์ของสัญญาบางส่วน</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ข้อสัญญาส่วนหนึ่งส่วนใดตกเป็นโมฆะหรือไม่มีผลบังคับตามกฎหมาย
                                            หรือขัดต่อกฎหมายของเขตอำนาจที่เกี่ยวข้อง ความสมบูรณ์ของส่วนอื่นๆ
                                            ที่เหลืออยู่ในสัญญาฉบับนี้จะไม่เสียไปเพราะการนั้น
                                            และให้ข้อความส่วนอื่นยังคงมีผลใช้บังคับต่อคู่สัญญาต่อไปตามกฎหมาย
                                        </p>
                                        <p>
                                            <b>12. การประมวลผลเพื่อการดำเนินการส่งเสริมการขาย</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เพื่อเป็นการให้ความยินยอมเปิดเผยข้อมูลส่วนบุคคลแก่บริษัท
                                            ซึ่งตัวแทนจำหน่ายผู้เป็นเจ้าของข้อมูลส่วนบุคคล ยินยอมให้บริษัทดำเนินการเก็บ
                                            รวบรวม การใช้ หรือการเปิดเผยข้อมูลส่วนบุคคล
                                            ที่บริษัทจำต้องใช้ข้อมูลส่วนบุคคลของตัวแทนจำหน่ายซึ่งเป็นเจ้าของข้อมูลส่วนบุคคล
                                            อันได้แก่ ชื่อ นามสกุล อายุ เพศ ที่อยู่ อีเมล เลขประจำตัวประชาชน
                                            หนังสือเดินทาง หรือหมายเลขประจำตัวอื่นๆ หมายเลขโทรศัพท์
                                            (ซึ่งรวมถึงหมายเลขโทรศัพท์เคลื่อนที่) ข้อมูลอุปกรณ์ หรือเครื่องมือ
                                            ข้อมูลตำแหน่งที่อยู่ บัตรประจำตัวผู้ใช้บริการ (SIM Card) ข้อมูลส่วนบุคคลอื่น
                                            และข้อมูลอื่นๆ ที่เกี่ยวข้องกับบุคคลที่ตัวแทนจำหน่ายได้ให้ไว้กับบริษัท
                                            หากตัวแทนจำหน่ายได้ให้ข้อมูลส่วนบุคคลของบุคคลที่สาม (เช่น ข้อมูลของคู่สมรส
                                            ลูก พ่อ แม่ และบุคคลอื่น)
                                            ตัวแทนจำหน่ายได้รับความยินยอมจากบุคคลที่สามก่อนที่จะให้ข้อมูลส่วนบุคคลของบุคคลเหล่านั้นให้แก่บริษัทแล้ว
                                            ไม่ว่าในรูปแบบใดๆ ที่ตัวแทนจำหน่ายส่งให้บริษัท
                                            (รวมถึงในรูปแบบของข้อมูลอัตลักษณ์บุคคล (Biometrics)) หรือผ่านรูปแบบอื่นๆ
                                            ที่ติดต่อกับตัวแทนจำหน่าย ดังระบุในนโยบายการคุ้มครองข้อมูลส่วนบุคคล (Data
                                            Protection Policy) ของบริษัท
                                            เพื่อใช้ในการดำเนินการส่งเนื้อหาเกี่ยวกับการส่งเสริมการขายและการสื่อสารการตลาดทางตรงเกี่ยวกับผลิตภัณฑ์
                                            และ/หรือ สินค้าและบริการของบริษัท คำแนะนำและข้อมูลในเรื่องต่างๆ
                                            ซึ่งรวมถึงการประกัน ตลอดจนการบริหารความมั่งคั่ง การลงทุน การธนาคาร
                                            บริการทางการเงิน การจ้างงาน การฝึกอบรม โปรแกรมให้รางวัล
                                            ให้ผลประโยชน์สำหรับการเป็นตัวแทนจำหน่ายที่มีความภักดีต่อบริษัท ให้สิทธิพิเศษ
                                            กิจกรรมการกุศล กิจกรรมที่ไม่หวังผล และการจัดกิจกรรมทางการตลาด การประกวด
                                            จับฉลากชิงโชค การจัดงาน งานกิจกรรมต่างๆ และการแข่งขันต่างๆ
                                            ซึ่งเลือกที่จะเข้าร่วมโดยในการดำเนินการดังกล่าวนี้
                                            บริษัทอาจต้องเปิดเผยข้อมูลส่วนบุคคลของตัวแทนจำหน่ายซึ่งเป็นเจ้าของข้อมูลส่วนบุคคลให้แก่บุคลากร
                                            ตัวแทนจำหน่ายอื่น และบริษัทพันธมิตรของบริษัท บริษัทในเครือ
                                            เพื่อการดำเนินการใดๆ เพื่อให้บริษัทบรรลุวัตถุประสงค์นี้
                                            โดยรายละเอียดเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลจะเป็นไปตามนโยบายการคุ้มครองข้อมูลส่วนบุคคล
                                            (Data Protection Policy) ของบริษัท
                                            และตัวแทนจำหน่ายสามารถยกเลิกและตรวจสอบข้อมูลได้ตลอดเวลาทำการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สัญญาฉบับนี้ทำขึ้นเป็นสองฉบับมีข้อความถูกต้องตรงกัน
                                            คู่สัญญาทั้งสองฝ่ายได้อ่านและเข้าใจข้อความของสัญญาฉบับนี้โดยตลอดแล้ว
                                            เพื่อเป็นหลักฐานในการนี้
                                            ตัวแทนจำหน่ายได้ตกลงทำสัญญาฉบับนี้ด้วยการแสดงเจตนาโดยการกดยอมรับสัญญาฉบับนี้ผ่านเว็บไซต์
                                            และ/หรือช่องทางอื่นใดที่บริษัทได้กำหนดไว้
                                            ซึ่งถือว่าตัวแทนจำหน่ายได้ลงลายมือชื่ออิเล็กทรอนิกส์และ/หรือเป็นการยืนยันตัวตนและได้อ่านและเข้าใจเนื้อหาโดยตลอดแล้วจึงได้ตกลงและยอมรับในเงื่อนไขตามสัญญาฉบับนี้ทุกประการและถือว่าตัวแทนจำหน่ายได้ตกลงทำสัญญาฉบับนี้นับตั้งแต่วันที่กดยอมรับสัญญาฉบับนี้แล้ว
                                        </p>
                                    </div>
                                </OverlayScrollbarsComponent>
                                <div className='row pl-5 pr-5 mt-5'>
                                    <div className='text-center'>
                                        <div className='radio-inline'>
                                            <label className='radio radio-success'>
                                                <input
                                                    type='radio'
                                                    name='agreement'
                                                    checked={props.agreement}
                                                    onClick={() => props.setAgreement(!props.agreement)}
                                                    readOnly
                                                />
                                                <span />
                                                ยอมรับ
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                    <div className='mr-2' />
                                    <div>
                                        <button
                                            type='button'
                                            className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                            disabled={!props.agreement}
                                            onClick={btnNext}
                                        >
                                            ตกลง
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
