import React from 'react';
import MaskedInput from 'react-text-mask';
import TextareaAutosize from 'react-textarea-autosize';
import SVG from 'react-inlinesvg';
import {
    maxLengthCheck,
    validateAge,
    validateCitizenid,
    validateEmail,
    validateNumber
} from '../../../../../../_metronic/_helpers';

export function SubscriberInformation(props) {
    const btnNext = () => {
        let message = '';
        if (props.information.name === '' && message === '') {
            message = 'กรุณากรอกชื่อ - นามสกุล';
        }
        if (props.information.birthday === '' && message === '') {
            message = 'กรุณากรอกวัน/เดือน/ปีเกิด';
        } else if (props.information.birthday !== '' && message === '') {
            if (props.information.birthday.includes('_')) {
                message = 'กรุณากรอกวัน/เดือน/ปีเกิดให้ครบ';
            } else {
                let birthdatelist = props.information.birthday.split('/');
                let newBirthday = birthdatelist[2] + '-' + birthdatelist[1] + '-' + birthdatelist[0];
                let maxdate = new Date(parseInt(birthdatelist[2]), parseInt(birthdatelist[1]), 0).getDate();
                let checkAge = validateAge(newBirthday, 7);
                if (parseInt(birthdatelist[1]) > 12 || parseInt(birthdatelist[1]) < 0) {
                    message = 'กรุณากรอกเดือนเกิดให้ถูกต้อง';
                }
                if (parseInt(birthdatelist[2] !== 0)) {
                    if (parseInt(birthdatelist[0]) > maxdate || parseInt(birthdatelist[0]) < 0) {
                        message = 'กรุณากรอกวันเกิดให้ถูกต้อง';
                    }
                } else {
                    if (parseInt(birthdatelist[1]) !== 2) {
                        if (parseInt(birthdatelist[0]) > maxdate || parseInt(birthdatelist[0]) < 0) {
                            message = 'กรุณากรอกวันเกิดให้ถูกต้อง';
                        }
                    }
                }
                if (!checkAge) {
                    message = 'อายุของคุณต่ำกว่า 7 ปี ไม่สามารถเข้าร่วมได้';
                }
            }
        }
        if (props.national === 'thai') {
            if (props.information.personalid === '' && message === '') {
                message = 'กรุณากรอกหมายเลขบัตรประชาชน';
            } else if (props.information.personalid !== '' && message === '') {
                if (props.information.personalid.length !== 13) {
                    message = 'กรุณากรอกหมายเลขบัตรประชาชนให้ครบ 13 หลัก';
                } else {
                    let citizenidstatus = validateCitizenid(props.information.personalid);
                    if (!citizenidstatus) {
                        message = 'กรุณากรอกหมายเลขบัตรประชาชนให้ถูกต้อง';
                    }
                }
            }
        } else {
            if (props.information.personalid === '' && message === '') {
                message = 'กรุณากรอกหมายเลขพาสปอร์ต';
            }
        }
        if (props.information.contactnumber === '' && message === '') {
            message = 'กรุณากรอกเบอร์โทรศัพท์ติดต่อ';
        } else if (props.information.contactnumber !== '' && message === '') {
            if (props.information.contactnumber.length !== 10 || props.information.contactnumber.charAt(0) !== '0') {
                message = 'กรุณากรอกเบอร์โทรศัพท์ติดต่อให้ถูกต้อง';
            }
        }
        if (props.information.email !== '' && message === '') {
            let emailStatus = validateEmail(props.information.email);
            if (!emailStatus) {
                message = 'กรุณากรอกอีเมลให้ถูกต้อง';
            }
        }
        if (message !== '') {
            props.SwalBs.fire({
                // title: 'ผิดพลาด!',
                html: `<div class='alert-text'>${message}</div>`,
                icon: 'warning',
                confirmButtonColor: '#ffa800',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        checkUser();
    };

    const handleChange = (e) => {
        let information = props.information;
        if (Object.keys(props.information).includes(e.target.name)) {
            information[e.target.name] = e.target.value;
        }
        props.setInformation(information);
    };

    const checkUser = () => {
        props.setLoading(true);
        fetch(props.config.dealer_php_url + 'api/register/existidcard', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idcard: props.information.personalid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                if (result.responsecode === 0) {
                    if (!props.edit) {
                        props.setPage('address');
                    } else {
                        props.setPage('summary');
                    }
                } else if (result.responsecode === 100) {
                    let message = '';
                    if (props.national === 'thai') {
                        message = 'กรุณากรอกหมายเลขบัตรประชาชนอื่น เนื่องจากหมายเลขนี้ถูกใช้งานแล้ว';
                    } else {
                        message = 'กรุณากรอกหมายเลขพาสปอร์ตอื่น เนื่องจากหมายเลขนี้ถูกใช้งานแล้ว';
                    }
                    props.SwalBs.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class='alert-text'>${message}</div>`,
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else {
                    let message = '';
                    if (props.national === 'thai') {
                        message = 'ไม่สามารถตรวจสอบเลขบัตรประชาชนได้ชั่วคราว กรุณาลองใหม่อีกครั้ง';
                    } else {
                        message = 'ไม่สามารถตรวจสอบเลขพาสปอร์ตได้ชั่วคราว กรุณาลองใหม่อีกครั้ง';
                    }
                    props.SwalBs.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class='alert-text'>${message}</div>`,
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                props.setLoading(false);
                let message = '';
                if (props.national === 'thai') {
                    message = 'ไม่สามารถตรวจสอบเลขบัตรประชาชนได้ชั่วคราว กรุณาลองใหม่อีกครั้ง';
                } else {
                    message = 'ไม่สามารถตรวจสอบเลขพาสปอร์ตได้ชั่วคราว กรุณาลองใหม่อีกครั้ง';
                }
                props.SwalBs.fire({
                    // title: 'ผิดพลาด!',
                    html: `<div class='alert-text'>${message}</div>`,
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครผู้ใช้งานใหม่</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครผู้ใช้งานใหม่</div>
                                                    กรอกข้อมูลส่วนบุคคล
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <form
                                                    className='form mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='pb-5'>
                                                        <div className='form-group fv-plugins-icon-container has-danger'>
                                                            <label>
                                                                ชื่อ - นามสกุล<span className='required-text'>*</span>
                                                            </label>
                                                            <input
                                                                type='text'
                                                                className='form-control form-control-lg'
                                                                name='name'
                                                                onChange={(e) => handleChange(e)}
                                                                defaultValue={props.information.name}
                                                            />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>เพศ</label>
                                                                    <div className='radio-inline'>
                                                                        <label className='radio radio-success'>
                                                                            <input
                                                                                type='radio'
                                                                                name='gender'
                                                                                value='male'
                                                                                checked={props.gender === 'male'}
                                                                                onChange={() => props.setGender('male')}
                                                                                readOnly
                                                                            />
                                                                            <span />
                                                                            ชาย
                                                                        </label>
                                                                        <label className='radio radio-success'>
                                                                            <input
                                                                                type='radio'
                                                                                name='gender'
                                                                                value='female'
                                                                                checked={props.gender === 'female'}
                                                                                onChange={() =>
                                                                                    props.setGender('female')}
                                                                                readOnly
                                                                            />
                                                                            <span />
                                                                            หญิง
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>
                                                                        วัน/เดือน/ปีเกิด (ปีคริสต์ศักราช)<span className='required-text'>*</span>
                                                                    </label>
                                                                    <div className='form-group fv-plugins-icon-container has-danger'>
                                                                        <MaskedInput
                                                                            mask={[
                                                                                /[0-9]/,
                                                                                /[0-9]/,
                                                                                '/',
                                                                                /[0-9]/,
                                                                                /[0-9]/,
                                                                                '/',
                                                                                /[0-9]/,
                                                                                /[0-9]/,
                                                                                /[0-9]/,
                                                                                /[0-9]/
                                                                            ]}
                                                                            inputMode='tel'
                                                                            className='form-control form-control-lg'
                                                                            name='birthday'
                                                                            keepCharPositions={true}
                                                                            onChange={(e) => handleChange(e)}
                                                                            defaultValue={props.information.birthday}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>สัญชาติ</label>
                                                                    <div className='radio-inline'>
                                                                        <label className='radio radio-success'>
                                                                            <input
                                                                                type='radio'
                                                                                name='national'
                                                                                value='thai'
                                                                                checked={props.national === 'thai'}
                                                                                onChange={() =>
                                                                                    props.setNational('thai')}
                                                                                readOnly
                                                                            />
                                                                            <span />
                                                                            ไทย
                                                                        </label>
                                                                        <label className='radio radio-success'>
                                                                            <input
                                                                                type='radio'
                                                                                name='national'
                                                                                value='outlander'
                                                                                checked={props.national === 'outlander'}
                                                                                onChange={() =>
                                                                                    props.setNational('outlander')}
                                                                                readOnly
                                                                            />
                                                                            <span />
                                                                            ต่างชาติ
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container has-danger'>
                                                                    <label>
                                                                        {props.national === 'thai' ? (
                                                                            'หมายเลขบัตรประชาชน'
                                                                        ) : (
                                                                            'หมายเลขพาสปอร์ต'
                                                                        )}
                                                                        <span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        inputMode={
                                                                            props.national === 'thai' ? 'tel' : 'text'
                                                                        }
                                                                        className='form-control form-control-lg'
                                                                        name='personalid'
                                                                        onChange={(e) => handleChange(e)}
                                                                        maxLength='13'
                                                                        onInput={maxLengthCheck}
                                                                        onKeyPress={(event) => {
                                                                            if (props.national === 'thai') {
                                                                                validateNumber(event);
                                                                            }
                                                                        }}
                                                                        onPaste={(e) => {
                                                                            if (props.national === 'thai') {
                                                                                e.preventDefault();
                                                                                return false;
                                                                            }
                                                                        }}
                                                                        // onCopy={(e) => {
                                                                        //     e.preventDefault();
                                                                        //     return false;
                                                                        // }}
                                                                        defaultValue={props.information.personalid}
                                                                    />
                                                                    {/* <div className='fv-help-block'>
                                                                        กรุณากรอกหมายเลขบัตรประชาชนอื่น
                                                                        เนื่องจากหมายเลขนี้ถูกใช้งานแล้ว
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            {/* <div className='fv-help-block'>
                                                                กรุณากรอกหมายเลขพาสปอร์ตอื่น
                                                                เนื่องจากหมายเลขนี้ถูกใช้งานแล้ว
                                                            </div> */}
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container has-danger'>
                                                                    <label>
                                                                        เบอร์โทรศัพท์ติดต่อ<span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        inputMode='tel'
                                                                        className='form-control form-control-lg'
                                                                        name='contactnumber'
                                                                        onChange={(e) => handleChange(e)}
                                                                        maxLength='10'
                                                                        onInput={maxLengthCheck}
                                                                        onKeyPress={(event) => {
                                                                            validateNumber(event);
                                                                        }}
                                                                        defaultValue={props.information.contactnumber}
                                                                    />
                                                                    {/* <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>
                                                                            กรุณากรอกเบอร์โทรศัพท์ติดต่อ
                                                                        </div>
                                                                        <div className='fv-help-block'>
                                                                            กรุณากรอกเบอร์โทรศัพท์ติดต่อให้ถูกต้อง
                                                                        </div>
                                                                        <div className='fv-help-block'>
                                                                            กรุณากรอกเบอร์โทรศัพท์ติดต่อโดยไม่ใส่เครื่องหมาย
                                                                            “-”
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>อีเมล</label>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='email'
                                                                        onChange={(e) => handleChange(e)}
                                                                        defaultValue={props.information.email}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-12'>
                                                                <div className='form-group'>
                                                                    <label>หมายเหตุ</label>
                                                                    <TextareaAutosize
                                                                        className='form-control form-control-lg textarea-min-h'
                                                                        name='remark'
                                                                        onChange={(e) => handleChange(e)}
                                                                        defaultValue={props.information.remark}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                                        <div className='mr-2' />
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
