import React, { useEffect, useState } from 'react';
import { getQueryVariable, toAbsoluteUrl, SwalBs } from '../../../../../_metronic/_helpers';
import {
    FeelsAgreement,
    FeelsInfoImage,
    FeelsInformation,
    FeelsPersonalImage,
    FeelsResult,
    FeelsResultTerm,
    FeelsTerm
} from './components';
import { config } from '../../../../config';

const initInformation = {
    citizenid: '',
    firstname: '',
    lastname: '',
    birthday: '',
    religion: '',
    address: '',
    dateissue: '',
    dateexpire: '',
    lasercode: '',
    contactnumber: '',
    phonenumber: ''
};

export function FeelsForceMain(props) {
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('agreement');
    const [ iccid, setIccid ] = useState('');
    const [ id, setId ] = useState('');
    const [ msisdn, setMsisdn ] = useState('');
    const [ image1, setImage1 ] = useState('');
    const [ image2, setImage2 ] = useState('');
    // infoimage
    const [ frontidcard, setFrontIdcard ] = useState('');
    const [ backidcard, setBackIdcard ] = useState('');
    // information
    const [ information, setInformation ] = useState(initInformation);
    // personalimage
    const [ personal1, setPersonal1 ] = useState('');
    const [ personal2, setPersonal2 ] = useState('');
    // result
    const [ activateresponse, setActivateResponse ] = useState({});
    const [ pendingresponse, setPendingResponse ] = useState({});
    const [ updateresponse, setUpdateResponse ] = useState({});

    useEffect(() => {
        document.title = 'เมืองปันสุข';
        document.getElementById('kt_body').classList.add('bg-subscriber');
        // document.getElementById('favicon').href = 'https://www.google.com/favicon.ico';
        let idparam = getQueryVariable(props, 'id');
        let msisdnparam = getQueryVariable(props, 'mobile');
        let iccidparam = getQueryVariable(props, 'serialno');
        if (!idparam || !msisdnparam || !iccidparam) {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: `ค่าส่งมาไม่ครบ กรุณาลองใหม่อีกครั้ง`,
                imageUrl: toAbsoluteUrl('/media/misc/info-punsook.svg'),
                imageWidth: 80,
                imageHeight: 80,
                // icon: 'warning',
                // showCancelButton: true,
                confirmButtonColor: '#0bb7af',
                confirmButtonText: 'รับทราบ',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
        } else {
            if (idparam === '' || msisdnparam === '' || iccidparam === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ค่าส่งมาไม่ครบ กรุณาลองใหม่อีกครั้ง`,
                    imageUrl: toAbsoluteUrl('/media/misc/info-punsook.svg'),
                    imageWidth: 80,
                    imageHeight: 80,
                    // icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonColor: '#0bb7af',
                    confirmButtonText: 'รับทราบ',
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            } else {
                setId(idparam);
                setMsisdn(msisdnparam);
                setIccid(iccidparam);
                let newinformation = information;
                newinformation['phonenumber'] = msisdnparam;
                setInformation(newinformation);
            }
        }
    }, []);

    return (
        <div className='feels-register'>
            {loading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            <div>
                {page === 'agreement' ? (
                    <FeelsAgreement
                        config={config}
                        iccid={iccid}
                        id={id}
                        msisdn={msisdn}
                        setLoading={setLoading}
                        setPage={setPage}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'infoimage' ? (
                    <FeelsInfoImage
                        config={config}
                        msisdn={msisdn}
                        setLoading={setLoading}
                        setPage={setPage}
                        information={information}
                        setInformation={setInformation}
                        frontidcard={frontidcard}
                        setFrontIdcard={setFrontIdcard}
                        backidcard={backidcard}
                        setBackIdcard={setBackIdcard}
                        setImage1={setImage1}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'information' ? (
                    <FeelsInformation
                        setPage={setPage}
                        information={information}
                        setInformation={setInformation}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'personalimage' ? (
                    <FeelsPersonalImage
                        config={config}
                        msisdn={msisdn}
                        setLoading={setLoading}
                        setPage={setPage}
                        information={information}
                        frontidcard={frontidcard}
                        backidcard={backidcard}
                        image1={image1}
                        image2={image2}
                        setImage2={setImage2}
                        personal1={personal1}
                        setPersonal1={setPersonal1}
                        personal2={personal2}
                        setPersonal2={setPersonal2}
                        setActivateResponse={setActivateResponse}
                        setUpdateResponse={setUpdateResponse}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'term' ? (
                    <FeelsTerm
                        config={config}
                        msisdn={msisdn}
                        setLoading={setLoading}
                        setPage={setPage}
                        information={information}
                        frontidcard={frontidcard}
                        backidcard={backidcard}
                        image1={image1}
                        image2={image2}
                        setImage2={setImage2}
                        personal1={personal1}
                        setPersonal1={setPersonal1}
                        personal2={personal2}
                        setPersonal2={setPersonal2}
                        setPendingResponse={setPendingResponse}
                        setUpdateResponse={setUpdateResponse}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'resultterm' ? (
                    <FeelsResultTerm
                        pendingresponse={pendingresponse}
                        updateresponse={updateresponse}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : (
                    <FeelsResult
                        activateresponse={activateresponse}
                        updateresponse={updateresponse}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                )}
            </div>
        </div>
    );
}
