import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { ContentRoute } from '../../../../_metronic/layout';
import { NotFoundPage } from '../NotFound';
import { DealerMain } from './Dealer/main';
import { FeelsMain } from './Feels/main';
import { SubscriberMain } from './Subscriber/main';
import { FeelsForceMain } from './FeelsForce/main';
import { Layout } from '../../../../_metronic/layout';
import { SimkitMain } from './Simkit/main';
export default function RegisterSwitch(props) {
    let location = useLocation();
    return (
        <Layout>
            <Switch>
                <ContentRoute path='/dealer/register' component={DealerMain} />
                <ContentRoute path='/subscriber/register' component={SubscriberMain} />
                <ContentRoute path='/feels-sim/register' component={FeelsMain} />
                <ContentRoute path='/feels-sim/force/register' component={FeelsForceMain} />
                <ContentRoute path='/simkit/register' component={SimkitMain} />
                <Redirect from='/agreement' to={`/dealer/register${location.search}`} />
                <ContentRoute path='/not-found' component={NotFoundPage} />
                <Redirect from='/index.php' to={`/dealer/register${location.search}`} />
                <Redirect from='/agreement' to={`/dealer/register${location.search}`} />
                <Redirect from='/onlinesim/agreement' to={`/feels-sim/register${location.search}`} />
                <Redirect to='not-found' />
            </Switch>
        </Layout>
    );
}
