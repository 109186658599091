import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import NumberFormat from 'react-number-format';

export function SubscriberSummary(props) {
    useEffect(() => {
        props.setEdit(true);
    }, []);

    const btnEdit = (page) => {
        props.setPage(page);
    };

    const btnBack = () => {
        props.setPage('register');
    };

    const btnNext = () => {
        console.log(props.selectpackage);
        let postparam = {
            phonenumber: props.mobile,
            id_card: props.personalid,
            package: props.selectpackage,
            refnumber: props.reference,
            name_t: props.name,
            mobile: props.contactnumber
            // bankcode: props.bank.value,
            // acc_no: props.banknumber
        };
        let birthdatelist = props.birthday.split('/');
        let newBirthday = birthdatelist[2] + '-' + birthdatelist[1] + '-' + birthdatelist[0];
        postparam['birthday'] = newBirthday;
        if (props.shiptype === 'new') {
            postparam['ship_name'] = props.shipname;
            postparam['ship_mobile'] = props.shipcontactnumber;
            postparam['ship_geo_id'] = props.shipgeo.value;
            postparam['ship_province_id'] = props.shipprovince.value;
            postparam['ship_amphur_id'] = props.shipdistrict.value;
            postparam['ship_district_id'] = props.shipsubdistrict.value;
            postparam['ship_zip'] = props.shipzipcode;
            postparam['ship_address'] = props.shipaddress;
        }
        createOrder(postparam);
    };

    const createOrder = (postparam) => {
        props.setLoading(true);
        fetch(props.config.dealer_url + props.config.version + 'register/simkit/subscriber', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: props.config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                props.setResult(result);
                props.setPage('result');
            })
            .catch((error) => {
                props.setLoading(false);
                props.setPage('result');
            });
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครผู้ใช้งานใหม่</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg mr-2'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div> */}
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครผู้ใช้งานใหม่</div>
                                                    สรุปรายการ
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-10'>
                                                <div className='row d-flex justify-content-center pb-5'>
                                                    <div className='col-sm-5 col-md-5 ml-1'>
                                                        <div className='py-4 d-flex flex-row'>
                                                            <img
                                                                alt='Logo'
                                                                src={props.toAbsoluteUrl('/media/logos/new-logo.png')}
                                                                className='logo-sticky max-h-35px mr-3'
                                                            />
                                                            <h5 className='my-auto'>
                                                                <b>รายละเอียดผู้สมัคร</b>
                                                            </h5>
                                                            {/* <span className='ml-3 pl-2 my-auto'>ซิม</span> */}
                                                        </div>
                                                        <OverlayScrollbarsComponent className='summary-body bg-light max-h-350px'>
                                                            <div className='p-5'>
                                                                <div className='d-flex row m-0 p-2'>
                                                                    <div className='col-xl-12 d-flex'>
                                                                        <div>
                                                                            <p>
                                                                                <i className='fas fa-user mr-3' />
                                                                                <b>ข้อมูลส่วนบุคคล</b>
                                                                            </p>
                                                                        </div>
                                                                        <div className='ml-auto'>
                                                                            <p
                                                                                className='text-primary cursor-pointer'
                                                                                onClick={() => btnEdit('register')}
                                                                            >
                                                                                <i className='fas fa-pencil-alt text-primary mr-3' />
                                                                                แก้ไข
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-xl-12'>
                                                                        <div className='rounded bg-white row m-0 p-3'>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    ชื่อ - นามสกุล
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.name}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    วัน/เดือน/ปีเกิด
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.birthday}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    หมายเลขบัตรประจำตัวประชาชน
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.personalid}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    เบอร์โทรศัพท์ติดต่อ
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.contactnumber}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {props.shiptype === 'new' ? (
                                                                    <div className='d-flex row m-0 p-2'>
                                                                        <div className='col-xl-12 d-flex'>
                                                                            <div>
                                                                                <p>
                                                                                    <i className='fas fa-truck mr-3' />
                                                                                    <b>ข้อมูลที่อยู่จัดส่ง</b>
                                                                                </p>
                                                                            </div>
                                                                            <div className='ml-auto'>
                                                                                <p
                                                                                    className='text-primary cursor-pointer'
                                                                                    onClick={() => btnEdit('shipping')}
                                                                                >
                                                                                    <i className='fas fa-pencil-alt text-primary mr-3' />
                                                                                    แก้ไข
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-xl-12'>
                                                                            <div className='rounded bg-white row m-0 p-3'>
                                                                                <div className='d-flex col-xl-12'>
                                                                                    <div className='p-2'>
                                                                                        ชื่อผู้รับสินค้า
                                                                                    </div>
                                                                                    <div className='ml-auto p-2'>
                                                                                        {props.shipname}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex col-xl-12'>
                                                                                    <div className='p-2'>
                                                                                        เบอร์โทรศัพท์ติดต่อ
                                                                                    </div>
                                                                                    <div className='ml-auto p-2'>
                                                                                        {props.shipcontactnumber}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex col-xl-12'>
                                                                                    <div className='p-2'>ภูมิภาค</div>
                                                                                    <div className='ml-auto p-2'>
                                                                                        {props.shipgeo.label}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex col-xl-12'>
                                                                                    <div className='p-2'>จังหวัด</div>
                                                                                    <div className='ml-auto p-2'>
                                                                                        {props.shipprovince.label}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex col-xl-12'>
                                                                                    <div className='p-2'>
                                                                                        {props.shipprovince !==
                                                                                        '' ? props.shipprovince
                                                                                            .value === '1' ? (
                                                                                            'เขต'
                                                                                        ) : (
                                                                                            'อำเภอ'
                                                                                        ) : (
                                                                                            'อำเภอ / เขต'
                                                                                        )}
                                                                                    </div>
                                                                                    <div className='ml-auto p-2'>
                                                                                        {props.shipdistrict.label}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex col-xl-12'>
                                                                                    <div className='p-2'>
                                                                                        {props.shipprovince !==
                                                                                        '' ? props.shipprovince
                                                                                            .value === '1' ? (
                                                                                            'แขวง'
                                                                                        ) : (
                                                                                            'ตำบล'
                                                                                        ) : (
                                                                                            'ตำบล / แขวง'
                                                                                        )}
                                                                                    </div>
                                                                                    <div className='ml-auto p-2'>
                                                                                        {props.shipsubdistrict.label}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex col-xl-12'>
                                                                                    <div className='p-2'>
                                                                                        รหัสไปรษณีย์
                                                                                    </div>
                                                                                    <div className='ml-auto p-2'>
                                                                                        {props.shipzipcode}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex col-xl-12'>
                                                                                    <div className='p-2'>
                                                                                        บ้านเลขที่ ถนน ซอย
                                                                                    </div>
                                                                                    <div className='ml-auto p-2'>
                                                                                        {props.shipaddress}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='d-flex row m-0 p-2'>
                                                                        <div className='col-xl-12 d-flex'>
                                                                            <div>
                                                                                <p>
                                                                                    <i className='fas fa-truck mr-3' />
                                                                                    <b>ข้อมูลที่อยู่จัดส่ง</b>
                                                                                </p>
                                                                            </div>
                                                                            <div className='ml-auto'>
                                                                                <p
                                                                                    className='text-primary cursor-pointer'
                                                                                    onClick={() => btnEdit('shipping')}
                                                                                >
                                                                                    <i className='fas fa-pencil-alt text-primary mr-3' />
                                                                                    แก้ไข
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-xl-12'>
                                                                            <div className='rounded bg-white row m-0 p-3 h-100px'>
                                                                                <div className='d-flex col-xl-12 text-center'>
                                                                                    <div className='col-12 p-0 my-auto'>
                                                                                        ไม่ต้องจัดส่ง
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </OverlayScrollbarsComponent>
                                                        <div className='pt-5' />
                                                    </div>
                                                    <div className='col-sm-3 col-md-5 offset-md-1 mobile'>
                                                        <div className='bg-light rounded d-flex flex-column p-3'>
                                                            <div className='p-2 ml-3'>
                                                                <h4>รายละเอียดการสมัครผู้ใช้งานใหม่</h4>
                                                            </div>
                                                            <div className='p-2 d-flex'>
                                                                <div className='col-6'>ผู้แนะนำ</div>
                                                                <div className='ml-auto'>{props.referencename}</div>
                                                            </div>
                                                            <div className='p-2 d-flex'>
                                                                <div className='col-8'>ขนาดแพ็กเกจของผู้ใช้งานใหม่</div>
                                                                <div className='ml-auto'>
                                                                    {/* {props.packageobject.title} */}
                                                                    <NumberFormat
                                                                        value={parseFloat(props.packageobject.price)}
                                                                        displayType={'text'}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        thousandSeparator={true}
                                                                        suffix={' บาท'}
                                                                    />
                                                                    {/* {'{'}
                                                                    {'{'}previewprice | number : '1.2-2'{'}'}
                                                                    {'}'} บาท */}
                                                                </div>
                                                            </div>
                                                            <div className='p-2 d-flex'>
                                                                <div className='col-8'>
                                                                    เบอร์โทรศัพท์ที่ต้องการใช้งานใหม่
                                                                </div>
                                                                <div className='ml-auto'>{props.mobile}</div>
                                                            </div>
                                                            <div className='border-top px-4 mx-3' />
                                                            <div className='border-top px-4 mx-3' />
                                                            <div className='p-2 d-flex pt-3'>
                                                                <div className='col-8'>
                                                                    <b>ยอดรวม</b>
                                                                </div>
                                                                <div className='ml-auto'>
                                                                    <b className='green'>
                                                                        <NumberFormat
                                                                            value='0'
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </b>
                                                                </div>
                                                            </div>
                                                            <div className='m-3'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-primary btn-block'
                                                                    onClick={btnNext}
                                                                >
                                                                    ชำระเงิน
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between border-top mt-5 pt-10' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
