import React from 'react';
import SVG from 'react-inlinesvg';
import { QRCode } from 'react-qrcode-logo';
import Moment from 'moment';
import html2canvas from 'html2canvas';

export function SubscriberResult(props) {
    const btnDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครผู้ใช้งานใหม่</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg mr-2'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-max'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครผู้ใช้งานใหม่</div>
                                                    ชำระเงิน
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        {Object.keys(props.result).length <= 0 ? (
                                            <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                                <div className='col-xl-12 col-xxl-10'>
                                                    <div className='col-12'>
                                                        <div className='row justify-content-center'>
                                                            <img
                                                                className='result-img'
                                                                src={props.toAbsoluteUrl(
                                                                    '/media/misc/error-punsook.svg'
                                                                )}
                                                                alt='result-image'
                                                            />
                                                        </div>
                                                        <div className='col-12 mt-50 text-center'>
                                                            <h5 className='text-center text-color-main mb-0'>
                                                                ไม่สามารถสร้างรายการได้
                                                            </h5>
                                                            <p className='text-center'>
                                                                กรุณาติดต่อเจ้าหน้าเพื่อตรวจสอบ
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                                {props.result.responsecode === 0 ? (
                                                    <div className='col-xl-12 col-xxl-10'>
                                                        {props.result.order.qrcode !== '' ? (
                                                            <div className='col-12'>
                                                                <div className='row justify-content-center'>
                                                                    <div className='text-center rounded bg-danger p-1'>
                                                                        <div className='text-white text-center'>
                                                                            หมายเลขคำสั่งซื้อ {props.result.order.ordernumber}
                                                                        </div>
                                                                        <div className='qrimg'>
                                                                            <QRCode
                                                                                className='border'
                                                                                value={props.result.order.qrcode}
                                                                                size={256}
                                                                                eyeRadius={10}
                                                                                logoImage={props.toAbsoluteUrl(
                                                                                    '/media/logos/new-logo.png'
                                                                                )}
                                                                                logoWidth={100}
                                                                            />
                                                                        </div>
                                                                        <div className='text-white text-center'>
                                                                            เบอร์ที่สมัครสมาชิก : {props.result.phonenumber}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='col-12'>
                                                                <div className='row justify-content-center'>
                                                                    <div className='text-center'>
                                                                        <div className='qrimg'>
                                                                            <img
                                                                                className='result-img'
                                                                                src={props.toAbsoluteUrl(
                                                                                    '/media/misc/qr-error.png'
                                                                                )}
                                                                                alt='result-image'
                                                                            />
                                                                        </div>
                                                                        <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                            คิวอาร์โค้ดไม่สมบูรณ์
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className='col-12 my-7 text-center'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success font-weight-bold text-uppercase px-6 py-2 mr-3'
                                                                onClick={btnDownload}
                                                            >
                                                                บันทึกรูป
                                                            </button>
                                                            {/* <button
                                                                type='button'
                                                                className='btn btn-primary font-weight-bold text-uppercase px-6 py-2 mr-3'
                                                            >
                                                                ขอคิวอาร์โค้ดใหม่
                                                            </button> */}
                                                        </div>
                                                        <div className='col-12 text-center'>
                                                            <h5 className='text-center text-color-main mb-0'>
                                                                หมายเลขคำสั่งซื้อ {props.result.order.ordernumber}
                                                                <br /> เบอร์ที่สั่งซื้อ {props.result.phonenumber}
                                                            </h5>
                                                            <p className='text-center'>
                                                                กรุณาบันทึกภาพคิวอาร์โค้ดและทำการชำระเงินที่แอพ
                                                                <span> ธนาคาร </span> ของท่าน
                                                                <b className='text-danger'>ภายใน 1 วัน</b>{' '}
                                                                เมื่อชำระเงินเรียบร้อยแล้วรอรับซิม Feels ที่บ้านประมาณ 2
                                                                - 3 วันทำการ
                                                            </p>
                                                        </div>
                                                        <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                                            <div className='mr-2' />
                                                            <div>
                                                                <a
                                                                    href='https://member.simk4.com/'
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className='btn btn-green font-weight-bold text-uppercase text-white px-9 py-4'
                                                                >
                                                                    เข้าสู่ระบบ
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='col-xl-12 col-xxl-10'>
                                                        <div className='col-12'>
                                                            <div className='row justify-content-center'>
                                                                <img
                                                                    className='result-img'
                                                                    src={props.toAbsoluteUrl(
                                                                        '/media/misc/error-punsook.svg'
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className='col-12 mt-50 text-center'>
                                                                <h5 className='text-center text-color-main mb-0'>
                                                                    ไม่สามารถสร้างรายการได้
                                                                </h5>
                                                                <p className='text-center'>
                                                                    กรุณาติดต่อเจ้าหน้าเพื่อตรวจสอบ
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
