/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RegisterSwitch from './pages/customs/Register/main';

export function Routes() {
    return (
        <Switch>
            <Route>
                <RegisterSwitch />
            </Route>
        </Switch>
    );
}
