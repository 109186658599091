import React from 'react';

export function FeelsTerm(props) {
    const pendingSim = () => {
        props.setLoading(true);
        let postparam = {
            personaltype: 'C',
            personalid: props.information.citizenid,
            firstname: props.information.firstname,
            lastname: props.information.lastname,
            religion: props.information.religion,
            birthday: props.information.birthday,
            address: props.information.address,
            dateissue: props.information.dateissue,
            dateexpire: props.information.dateexpire,
            lasercode: props.information.lasercode.split('-').join(''),
            contactnumber: '66' + props.information.contactnumber.slice(1),
            activatednumber: '66' + props.information.phonenumber.slice(1),
            imgcardid: props.frontidcard,
            imgbackcard: props.backidcard,
            imgpersonal: props.personal1,
            imgpersonal2: props.personal2
        };
        fetch(props.config.custom_url + props.config.version + 'onlinesim/pendingsim', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: props.config.custom_secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                props.setPendingResponse(result);
                if (result.responsecode === 0) {
                    updateeKyc();
                } else {
                    props.setLoading(false);
                    props.setPage('resultterm');
                }
            })
            .catch((error) => {
                props.setLoading(false);
                props.setPage('resultterm');
            });
    };

    const updateeKyc = () => {
        fetch(props.config.dealer_php_url + 'api/onlinesim/updateekyc', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phonenumber: props.information.phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setUpdateResponse(result);
                props.setLoading(false);
                props.setPage('resultterm');
            })
            .catch((error) => {
                props.setLoading(false);
                props.setPage('resultterm');
            });
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Feels-logo'
                                    src={props.toAbsoluteUrl('/media/logos/feels-full-logo.svg')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>ลงทะเบียนซิมออนไลน์</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center' />
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>เงื่อนไข</div>
                                                    การลงทะเบียนกรณี ตรวจสอบอัตลักษณ์ไม่ผ่าน
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center pb-8 px-8 pb-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <main className='bg-white'>
                                                    <div className='row justify-content-center'>
                                                        <div className='col-xl-12'>
                                                            <div className='agreement-bg'>
                                                                <div className='col-md-12 p-4 termpage'>
                                                                    <div className='force-overflow'>
                                                                        <ul>
                                                                            <li>
                                                                                การลงทะเบียนด้วยระบบอัตลักษณ์ยังไม่สมบูรณ์
                                                                                หากท่านประสงค์จะลงทะเบียนซิมการ์ดต่อไป
                                                                                ท่านจะต้องยืนยันและรับรองความถูกต้องในตัวบุคคลโดยต้องตรวจสอบจากบัตรจริงเท่านั้นและยืนยันรับรอง
                                                                            </li>
                                                                            <li>
                                                                                ข้อมูลนี้จะถูกเก็บข้อมูลไว้เป็นหลักฐานในการขอตรวจสอบตามกฎหมายต่อไป
                                                                            </li>
                                                                            <li>
                                                                                ในกรณีที่บริษัทตรวจพบว่าข้อมูลเป็นเท็จ
                                                                                บริษัทขอสงวนสิทธิในการระงับการใช้งานได้ทันที
                                                                            </li>
                                                                            <li>
                                                                                หากท่านไม่ติดต่อเจ้าหน้าที่ภายใน 15
                                                                                วันหลังจากการแจ้งเตือน
                                                                                บริษัทจะขอสงวนสิทธิยกเลิกบริการซิมการ์ดได้ทันที
                                                                                และส่งเรื่องดำเนินการตามกฎหมายต่อไป
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 text-center mt-3 p-3'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success btn-feels-main font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={pendingSim}
                                                            >
                                                                ยอมรับเงื่อนไข
                                                            </button>
                                                        </div>
                                                    </div>
                                                </main>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
