import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import NumberFormat from 'react-number-format';

export function DealerSummary(props) {
    useEffect(() => {
        props.setEdit(true);
    }, []);

    const btnEdit = (page) => {
        props.setPage(page);
    };

    const btnNext = () => {
        let postparam = {
            number: props.selectnumber,
            id_card: props.information.personalid,
            package: props.selectpackage,
            refnumber: props.suggestnumber,
            name_t: props.information.name,
            sex: props.gender === 'male' ? 'ชาย' : 'หญิง',
            national: props.national === 'thai' ? 'ไทย' : 'ต่างชาติ',
            address: props.address,
            geo_id: props.geo.value,
            provinceId: props.province.value,
            amphurId: props.district.value,
            districtId: props.subdistrict.value,
            zip: props.zipcode,
            mobile: props.information.contactnumber,
            bankcode: props.bank.value,
            acc_no: props.banknumber
        };
        let birthdatelist = props.information.birthday.split('/');
        let newBirthday = birthdatelist[2] + '-' + birthdatelist[1] + '-' + birthdatelist[0];
        postparam['birthday'] = newBirthday;
        if (props.information.email !== '') {
            postparam['email'] = props.information.email;
        }
        if (props.information.remark !== '') {
            postparam['remark'] = props.information.remark;
        }
        if (props.bankowner !== '') {
            postparam['acc_name'] = props.bankowner;
        }
        if (props.bankimage !== '') {
            postparam['account_img'] = props.bankimage;
        }
        if (props.shiptype === 'same') {
            postparam['ship_name'] = props.information.name;
            postparam['ship_mobile'] = props.information.contactnumber;
            postparam['ship_geo_id'] = props.geo.value;
            postparam['ship_provinceId'] = props.province.value;
            postparam['ship_amphurId'] = props.district.value;
            postparam['ship_districtId'] = props.subdistrict.value;
            postparam['ship_zip'] = props.zipcode;
            postparam['ship_address'] = props.address;
        } else {
            postparam['ship_name'] = props.shipname;
            postparam['ship_mobile'] = props.shipcontactnumber;
            postparam['ship_geo_id'] = props.shipgeo.value;
            postparam['ship_provinceId'] = props.shipprovince.value;
            postparam['ship_amphurId'] = props.shipdistrict.value;
            postparam['ship_districtId'] = props.shipsubdistrict.value;
            postparam['ship_zip'] = props.shipzipcode;
            postparam['ship_address'] = props.shipaddress;
        }
        createOrder(postparam);
    };

    const createOrder = (postparam) => {
        props.setLoading(true);
        fetch(props.config.dealer_php_url + 'api/register/create', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                props.setResult(result);
                props.setPage('result');
            })
            .catch((error) => {
                props.setLoading(false);
                props.setPage('result');
            });
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครดีลเลอร์ใหม่</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg mr-2'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครดีลเลอร์ใหม่</div>
                                                    สรุปรายการ
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-10'>
                                                <div className='row d-flex justify-content-center pb-5'>
                                                    <div className='col-sm-5 col-md-5 ml-1'>
                                                        <div className='py-4 d-flex flex-row'>
                                                            <img
                                                                alt='Logo'
                                                                src={props.toAbsoluteUrl('/media/logos/new-logo.png')}
                                                                className='logo-sticky max-h-35px mr-3'
                                                            />
                                                            <h5 className='my-auto'>
                                                                <b>รายละเอียดผู้สมัคร</b>
                                                            </h5>
                                                            {/* <span className='ml-3 pl-2 my-auto'>ซิม</span> */}
                                                        </div>
                                                        <OverlayScrollbarsComponent className='summary-body bg-light max-h-350px'>
                                                            <div className='p-5'>
                                                                <div className='d-flex row m-0 p-2'>
                                                                    <div className='col-xl-12 d-flex'>
                                                                        <div>
                                                                            <p>
                                                                                <i className='fas fa-user mr-3' />
                                                                                <b>ข้อมูลส่วนบุคคล</b>
                                                                            </p>
                                                                        </div>
                                                                        <div className='ml-auto'>
                                                                            <p
                                                                                className='text-primary cursor-pointer'
                                                                                onClick={() => btnEdit('information')}
                                                                            >
                                                                                <i className='fas fa-pencil-alt text-primary mr-3' />
                                                                                แก้ไข
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-xl-12'>
                                                                        <div className='rounded bg-white row m-0 p-3'>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    ชื่อ - นามสกุล
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.information.name}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>เพศ</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.gender === 'male' ? (
                                                                                        'ชาย'
                                                                                    ) : (
                                                                                        'หญิง'
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    วัน/เดือน/ปีเกิด
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.information.birthday}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>สัญชาติ</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.national === 'thai' ? (
                                                                                        'ไทย'
                                                                                    ) : (
                                                                                        'ต่างชาติ'
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    {props.national === 'thai' ? (
                                                                                        'หมายเลขบัตรประชาชน'
                                                                                    ) : (
                                                                                        'หมายเลขพาสปอร์ต'
                                                                                    )}
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.information.personalid}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    เบอร์โทรศัพท์ติดต่อ
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.information.contactnumber}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>อีเมล</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.information.email}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className='d-flex row m-0 p-2'>
                                                                    <div className='col-xl-12 d-flex'>
                                                                        <div>
                                                                            <p>
                                                                                <i className='fas fa-map-marked-alt mr-3' />
                                                                                <b>ข้อมูลที่อยู่ที่สามารถติดต่อได้</b>
                                                                            </p>
                                                                        </div>
                                                                        <div className='ml-auto'>
                                                                            <p
                                                                                className='text-primary cursor-pointer'
                                                                                onClick={() => btnEdit('address')}
                                                                            >
                                                                                <i className='fas fa-pencil-alt text-primary mr-3' />
                                                                                แก้ไข
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-xl-12'>
                                                                        <div className='rounded bg-white row m-0 p-3'>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>ภูมิภาค</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.geo.label}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>จังหวัด</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.province.label}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    {props.province !== '' ? props
                                                                                        .province.value === '1' ? (
                                                                                        'เขต'
                                                                                    ) : (
                                                                                        'อำเภอ'
                                                                                    ) : (
                                                                                        'อำเภอ / เขต'
                                                                                    )}
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.district.label}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    {props.province !== '' ? props
                                                                                        .province.value === '1' ? (
                                                                                        'แขวง'
                                                                                    ) : (
                                                                                        'ตำบล'
                                                                                    ) : (
                                                                                        'ตำบล / แขวง'
                                                                                    )}
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.subdistrict.label}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>รหัสไปรษณีย์</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.zipcode}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    บ้านเลขที่ ถนน ซอย
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.address}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className='d-flex row m-0 p-2'>
                                                                    <div className='col-xl-12 d-flex'>
                                                                        <div>
                                                                            <p>
                                                                                <i className='fas fa-money-check-alt mr-3' />
                                                                                <b>ข้อมูลบัญชีธนาคาร</b>
                                                                            </p>
                                                                        </div>
                                                                        <div className='ml-auto'>
                                                                            <p
                                                                                className='text-primary cursor-pointer'
                                                                                onClick={() => btnEdit('bank')}
                                                                            >
                                                                                <i className='fas fa-pencil-alt text-primary mr-3' />
                                                                                แก้ไข
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-xl-12'>
                                                                        <div className='rounded bg-white row m-0 p-3'>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>ธนาคาร</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.bank.label}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>เลขที่บัญชี</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.banknumber}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>สาขา</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.bankbranch}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>ชื่อบัญชี</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.bankowner}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className='d-flex row m-0 p-2'>
                                                                    <div className='col-xl-12 d-flex'>
                                                                        <div>
                                                                            <p>
                                                                                <i className='fas fa-truck mr-3' />
                                                                                <b>ข้อมูลที่อยู่จัดส่ง</b>
                                                                            </p>
                                                                        </div>
                                                                        <div className='ml-auto'>
                                                                            <p
                                                                                className='text-primary cursor-pointer'
                                                                                onClick={() => btnEdit('shipping')}
                                                                            >
                                                                                <i className='fas fa-pencil-alt text-primary mr-3' />
                                                                                แก้ไข
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-xl-12'>
                                                                        <div className='rounded bg-white row m-0 p-3'>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    ชื่อผู้รับสินค้า
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.shiptype === 'same' ? (
                                                                                        props.information.name
                                                                                    ) : (
                                                                                        props.shipname
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    เบอร์โทรศัพท์ติดต่อ
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.shiptype === 'same' ? (
                                                                                        props.information.contactnumber
                                                                                    ) : (
                                                                                        props.shipcontactnumber
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>ภูมิภาค</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.shiptype === 'same' ? (
                                                                                        props.geo.label
                                                                                    ) : (
                                                                                        props.shipgeo.label
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>จังหวัด</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.shiptype === 'same' ? (
                                                                                        props.province.label
                                                                                    ) : (
                                                                                        props.shipprovince.label
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    {props.shiptype ===
                                                                                    'same' ? props.province !==
                                                                                    '' ? props.province.value ===
                                                                                    '1' ? (
                                                                                        'เขต'
                                                                                    ) : (
                                                                                        'อำเภอ'
                                                                                    ) : (
                                                                                        'อำเภอ / เขต'
                                                                                    ) : props.shipprovince !==
                                                                                    '' ? props.shipprovince.value ===
                                                                                    '1' ? (
                                                                                        'เขต'
                                                                                    ) : (
                                                                                        'อำเภอ'
                                                                                    ) : (
                                                                                        'อำเภอ / เขต'
                                                                                    )}
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.shiptype === 'same' ? (
                                                                                        props.district.label
                                                                                    ) : (
                                                                                        props.shipdistrict.label
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    {props.shiptype ===
                                                                                    'same' ? props.province !==
                                                                                    '' ? props.province.value ===
                                                                                    '1' ? (
                                                                                        'แขวง'
                                                                                    ) : (
                                                                                        'ตำบล'
                                                                                    ) : (
                                                                                        'ตำบล / แขวง'
                                                                                    ) : props.shipprovince !==
                                                                                    '' ? props.shipprovince.value ===
                                                                                    '1' ? (
                                                                                        'แขวง'
                                                                                    ) : (
                                                                                        'ตำบล'
                                                                                    ) : (
                                                                                        'ตำบล / แขวง'
                                                                                    )}
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.shiptype === 'same' ? (
                                                                                        props.subdistrict.label
                                                                                    ) : (
                                                                                        props.shipsubdistrict.label
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>รหัสไปรษณีย์</div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.shiptype === 'same' ? (
                                                                                        props.zipcode
                                                                                    ) : (
                                                                                        props.shipzipcode
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex col-xl-12'>
                                                                                <div className='p-2'>
                                                                                    บ้านเลขที่ ถนน ซอย
                                                                                </div>
                                                                                <div className='ml-auto p-2'>
                                                                                    {props.shiptype === 'same' ? (
                                                                                        props.address
                                                                                    ) : (
                                                                                        props.shipaddress
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </OverlayScrollbarsComponent>
                                                        <div className='pt-5' />
                                                    </div>
                                                    <div className='col-sm-3 col-md-5 offset-md-1 mobile'>
                                                        <div className='bg-light rounded d-flex flex-column p-3'>
                                                            <div className='p-2 ml-3'>
                                                                <h4>รายละเอียดการเข้าร่วมโครงการ</h4>
                                                            </div>
                                                            <div className='p-2 d-flex'>
                                                                <div className='col-6'>ประเภทเบอร์เข้าร่วมโครงการ</div>
                                                                <div className='ml-auto'>
                                                                    {props.type === 'new' ? (
                                                                        'เข้าร่วมด้วยเบอร์ใหม่ปันสุข'
                                                                    ) : (
                                                                        'เข้าร่วมด้วยเบอร์ Feels ที่มีอยู่'
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className='p-2 d-flex'>
                                                                <div className='col-8'>ขนาดสัญญาดีลเลอร์</div>
                                                                <div className='ml-auto'>
                                                                    {props.packageobject.title}
                                                                    {/* {'{'}
                                                                    {'{'}previewprice | number : '1.2-2'{'}'}
                                                                    {'}'} บาท */}
                                                                </div>
                                                            </div>
                                                            <div className='p-2 d-flex'>
                                                                <div className='col-8'>
                                                                    เบอร์โทรศัพท์ที่เข้าร่วมโครงการ
                                                                </div>
                                                                <div className='ml-auto'>{props.selectnumber}</div>
                                                            </div>
                                                            <div className='border-top px-4 mx-3' />
                                                            <div className='d-flex flex-row align-content-center p-4'>
                                                                <div className='pt-2 pr-2 col-xl-12'>
                                                                    <div className='radio-inline'>
                                                                        <label className='radio radio-success col-xl-12'>
                                                                            <input
                                                                                type='radio'
                                                                                name='payment'
                                                                                value='qrcode'
                                                                                defaultChecked
                                                                            />
                                                                            <span />
                                                                            <div className='rounded border d-flex w-100 px-2 bg-white'>
                                                                                <div className='row p-0 m-0'>
                                                                                    <p className='my-auto'>
                                                                                        <i className='fas fa-qrcode text-primary pr-2' />
                                                                                        ชำระเงินผ่านคิวอาร์โค้ด
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='border-top px-4 mx-3' />
                                                            <div className='p-2 d-flex pt-3'>
                                                                <div className='col-8'>
                                                                    <b>ยอดรวม</b>
                                                                </div>
                                                                <div className='ml-auto'>
                                                                    <b className='green'>
                                                                        <NumberFormat
                                                                            value={parseFloat(
                                                                                props.packageobject.price
                                                                            )}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </b>
                                                                </div>
                                                            </div>
                                                            <div className='m-3'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-primary btn-block'
                                                                    onClick={btnNext}
                                                                >
                                                                    ชำระเงิน
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between border-top mt-5 pt-10' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
