import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export function SubscriberAgreement(props) {
    const btnNext = () => {
        props.setPage('package');
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครผู้ใช้งานใหม่</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg mr-2'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div> */}
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <form id='login-form' className='nobottommargin'>
                        <div
                            className='card card-custom card-stretch example example-compact'
                            id='kt_page_stretched_card'
                        >
                            <div className='card-header'>
                                <div className='card-title'>
                                    <h3 className='card-label text-center text-md-left m-0'>
                                        <small>
                                            ข้อตกลงและเงื่อนไขการให้บริการโทรศัพท์เคลื่อนที่ประเภทเรียกเก็บค่าธรรมเนียมและค่าบริการล่วงหน้า
                                            (Pre-Paid) ระหว่างผู้ให้บริการกับผู้ใช้บริการ
                                        </small>
                                    </h3>
                                </div>
                                <div className='card-toolbar' />
                            </div>
                            <div className='card-body'>
                                <OverlayScrollbarsComponent className='agreement-body'>
                                    <div className='p-5 agreement-bg'>
                                        <p>
                                            <b>1. ข้อความทั่วไป</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1
                                            ข้อตกลงและเงื่อนไขในการให้บริการโทรศัพท์เคลื่อนที่นี้ มีผลใช้บังคับระหว่าง
                                            บริษัท เคโฟร์ คอมมูนิเคชั่น จํากัด ในฐานะผู้ให้บริการกับผู้ใช้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.2
                                            ผู้ใช้บริการได้อ่านและเข้าใจเงื่อนไขและข้อตกลงการใช้บริการซึ่งกําหนดสิทธิและหน้าที่ของผู้ให้บริการและผู้ใช้บริการ
                                            จนเข้าใจรายละเอียดต่างๆ ครบถ้วนแล้ว
                                            จึงได้ตกลงใช้บริการโดยลงทะเบียนบัตรประจําตัวผู้ใช้บริการ (SIM Card)
                                            เปิดใช้บริการกับผู้ให้บริการ
                                            ด้วยวิธีการทางอิเล็กทรอนิกส์ผ่านโทรศัพท์เคลื่อนที่ รวมถึงการพิมพ์
                                            หรือเลือกข้อความและการกระทําใดๆ
                                            ที่ทําให้ปรากฏซึ่งข้อความตามวิธีการที่ผู้ให้บริการกําหนดไว้ผ่านเครื่องโทรศัพท์เคลื่อนที่หรือผ่านทางเครือข่ายอินเตอร์เน็ต
                                            (Internet) หรือสื่ออิเล็กทรอนิกส์อื่นใดตามที่ผู้ให้บริการกําหนด
                                            และผู้ให้บริการได้เปิด
                                            หรือยินยอมให้ผู้ใช้บริการใช้บริการโทรศัพท์เคลื่อนที่แล้ว ณ
                                            ศูนย์บริการของผู้ให้บริการหรือจุดให้บริการที่มีการใช้เครื่องอ่านบัตรประชาชน
                                            หรือช่องทางออนไลน์ผ่านเว็บไซต์และแอปพลิเคชั่นของผู้ให้บริการ
                                            ถือว่าผู้ใช้บริการได้ตกลงทําสัญญากับผู้ให้บริการและยินยอมผูกพันและปฏิบัติตามข้อตกลงฉบับนี้
                                            ทุกประการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.3
                                            ผู้ให้บริการมีสิทธิที่จะปฏิเสธการให้บริการแก่ผู้ใช้บริการตามเงื่อนไขที่กําหนดไว้ในข้อตกลงฉบับนี้
                                        </p>
                                        <p>
                                            <b>2. คำนิยาม</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.1{' '}
                                            <b>“สัญญา”</b> หมายถึง
                                            ความตกลงร่วมกันระหว่างผู้ให้บริการกับผู้ใช้บริการเกี่ยวกับการให้บริการโทรศัพท์เคลื่อนที่แก่ผู้ใช้บริการ
                                            โดยจะมีผลผูกพันและใช้บังคับกับผู้ให้บริการและผู้ใช้บริการตามสิทธิและหน้าที่ที่กำหนดไว้ในสัญญาการให้บริการโทรคมนาคมฉบับนี้
                                            ซึ่งคณะกรรมการเห็นชอบแล้วทันทีเมื่อผู้ให้บริการได้ยินยอมให้ผู้ใช้บริการได้ใช้บริการโทรศัพท์เคลื่อนที่
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.2{' '}
                                            <b>“คำขอ”</b> หมายถึง แบบคำขอทุกประเภทที่ผู้ให้บริการกำหนดขึ้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.3{' '}
                                            <b>“สมัครใช้บริการ”</b> หมายถึง การยื่นคำขอใช้บริการทุกประเภท
                                            โดยยื่นต่อผู้ให้บริการด้วยตนเองรวมถึงการพิมพ์หรือเลือกข้อความและการกระทำใด ๆ
                                            ที่ทำให้ปรากฏซึ่งข้อความตามวิธีการที่ผู้ให้บริการกำหนดไว้
                                            ผ่านเครื่องโทรศัพท์เคลื่อนที่
                                            หรือผ่านทางเครือข่ายอินเทอร์เน็ตหรือสื่ออื่นใดตามที่ผู้ให้บริการกำหนด
                                            ทั้งที่มีอยู่ในปัจจุบันและที่จะกำหนดขึ้นในอนาคต
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.4{' '}
                                            <b>“ผู้ให้บริการ”</b> หมายถึง บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด (“K4”)
                                            ซึ่งให้บริการบนเครือข่าย Feels โดย บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.5{' '}
                                            <b>“ผู้ใช้บริการ”</b> หมายถึง
                                            บุคคลหรือนิติบุคคลผู้ซึ่งได้ตกลงทำสัญญาใช้บริการโทรศัพท์เคลื่อนที่ของผู้ให้บริการไว้กับผู้ให้บริการ
                                            และให้หมายความรวมถึงผู้รับโอนสิทธิตามสัญญาจากผู้ใช้บริการเดิม
                                            โดยได้รับความยินยอมจากผู้ให้บริการเป็นลายลักษณ์อักษรแล้ว
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.6{' '}
                                            <b>“ศูนย์บริการลูกค้า”</b> หมายถึง ศูนย์บริการลูกค้าของผู้ให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.7{' '}
                                            <b>“ตัวแทนศูนย์บริการลูกค้า”</b> หมายถึง
                                            ตัวแทนที่ได้รับการแต่งตั้งจากผู้ให้บริการให้เป็นผู้รับสมัครและจดทะเบียนคำขอใช้บริการ
                                            รับเปลี่ยนแปลงทะเบียน รับชำระค่าใช้บริการ จำหน่ายสินค้า และให้บริการอื่น ๆ
                                            ตามที่ผู้ให้บริการมอบหมาย
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.8{' '}
                                            <b>“บริการโทรศัพท์เคลื่อนที่”</b> หมายถึง บริการเสริม
                                            บริการโทรศัพท์เคลื่อนที่ข้ามแดนระหว่างประเทศ (International Roaming)
                                            บริการโทรศัพท์ทางไกลระหว่างประเทศ (International Direct Dialing)
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.9{' '}
                                            <b>
                                                “บริการโทรศัพท์เคลื่อนที่แบบชำระค่าธรรมเนียมและค่าบริการล่วงหน้า
                                                (Pre-Paid)”
                                            </b>{' '}
                                            หมายถึง
                                            บริการโทรศัพท์เคลื่อนที่ซึ่งกำหนดให้ผู้ใช้บริการต้องชำระเงินค่าธรรมเนียม
                                            และค่าบริการต่าง ๆ ให้แก่ผู้ให้บริการเป็นการล่วงหน้าก่อนการใช้บริการ ทั้งนี้
                                            ค่าธรรมเนียมและค่าบริการที่ชำระไว้ล่วงหน้าจะถูกตัดทอนไปตามสัดส่วนของระยะเวลาใช้บริการและอัตราค่าธรรมเนียมและค่าบริการที่กำหนดไว้ในการใช้บริการแต่ละครั้ง
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.10{' '}
                                            <b>
                                                “บัตรประจำตัวผู้ใช้บริการ (Subscriber Identity Module Card “SIM Card”)”
                                            </b>{' '}
                                            หมายถึง อุปกรณ์บรรจุหน่วยประมวลผลและความจำขนาดเล็ก
                                            ซึ่งผู้ให้บริการบันทึกหมายเลขโทรศัพท์ประจำตัวของผู้ใช้บริการเพื่อให้ผู้ใช้บริการสามารถใช้บริการต่อสัญญาณกับโครงข่ายได้และใช้เก็บหมายเลขโทรศัพท์เคลื่อนที่และข้อมูลต่าง
                                            ๆ ของผู้ใช้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.11{' '}
                                            <b>“บริการ”</b> หมายถึง บริการปกติและบริการเสริม
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.12{' '}
                                            <b>“บริการปกติ”</b> หมายถึง
                                            การให้บริการโทรคมนาคมที่ผู้ส่งหรือผู้รับสามารถสื่อสารกันได้เป็นคำพูด สัญญาณ
                                            เครื่องหมาย ตัวหนังสือ ภาพ เสียง หรืออื่นใดที่สามารถสื่อความหมายได้
                                            และหมายความรวมถึงบริการโทรศัพท์ระหว่างประเทศบริการ
                                            เพื่อการรับส่งข้อมูลบริการเชื่อมต่อและ/หรือบริการโทรคมนาคมอื่นที่ให้บริการผ่านบริการโทรคมนาคมของผู้ให้บริการ
                                            เช่น การเชื่อมต่อเพื่อรับบริการอินเทอร์เน็ต เป็นต้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.13{' '}
                                            <b>“บริการเสริม”</b> หมายถึง บริการใดๆ อันได้แก่ บริการเสียง ภาพ
                                            ภาพเคลื่อนไหว ข้อมูล พหุสื่อ หรือสื่อผสม
                                            ซึ่งผู้ให้บริการหรือผู้ร่วมให้บริการเสริมเป็นผู้จัดทํา
                                            เพื่อให้บริการแก่ผู้ใช้บริการ นอกเหนือจากการใช้บริการโทรศัพท์เคลื่อนที่
                                            เพื่อการโทรออกหรือรับสายเข้าตามปกติ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.14{' '}
                                            <b>“ผู้ร่วมให้บริการเสริม”</b> หมายถึง
                                            ผู้ที่มีข้อตกลงกับผู้ให้บริการเพื่อเสนอขายสินค้าหรือให้บริการแก่ผู้ใช้บริการที่ใช้บริการเสริม
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.15{' '}
                                            <b>“บริการโทรศัพท์ระหว่างประเทศ”</b> หมายถึง
                                            การเรียกออกต่างประเทศโดยผู้ใช้บริการเอง (International Direct Dialing: IDD)
                                            โดยเรียกผ่านเลขหมายสำหรับบริการเรียกออกต่างประเทศ
                                            หรือสัญลักษณ์ที่ใช้แทนรหัสเรียกออกต่างประเทศของผู้ให้บริการเอง
                                            หรือของผู้ให้บริการโทรศัพท์ระหว่างประเทศรายอื่นซึ่งมีข้อตกลงร่วมกับผู้ให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.16{' '}
                                            <b>“บริการข้ามแดนอัตโนมัติ”</b> หมายถึง
                                            การให้บริการโทรศัพท์เคลื่อนที่ข้ามแดนระหว่างประเทศ (International Roaming)
                                            โดยใช้โครงข่ายของผู้ให้บริการโทรศัพท์เคลื่อนที่ในต่างประเทศ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.17{' '}
                                            <b>“ข้อมูลส่วนบุคคล”</b> หมายถึง ชื่อ สกุล
                                            ที่อยู่ของผู้ใช้บริการและให้หมายความรวมถึงรายละเอียดเกี่ยวกับผู้ใช้บริการที่สามารถระบุตัวผู้ใช้บริการหรืออาจระบุตัวผู้ใช้บริการนั้นได้
                                            ไม่ว่าทางตรงหรือทางอ้อม ข้อมูลการใช้บริการ เลขหมายโทรคมนาคม
                                            และพฤติกรรมการใช้บริการโทรคมนาคมของผู้ใช้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.18{' '}
                                            <b>“วงเงินค่าใช้บริการ”</b> หมายถึง
                                            จำนวนเงินค่าใช้บริการสูงสุดที่ผู้ใช้บริการได้ทำการเติมเงิน
                                            หรือชำระค่าบริการล่วงหน้าตามประเภท จำนวน หรือวิธีการที่ผู้ให้บริการกำหนด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.19{' '}
                                            <b>“เติมเงิน” หรือ “การเติมเงิน”</b> หมายถึง
                                            การชำระค่าบริการล่วงหน้าผ่านช่องทางต่าง ๆ
                                            ที่ผู้ให้บริการกำหนดและเผยแพร่ให้ผู้ใช้บริการทราบล่วงหน้าเป็นการทั่วไปผ่านเว็บไซต์และศูนย์บริการลูกค้าของผู้ให้บริการ
                                            เช่น บัตรเติมเงินของผู้ให้บริการเอง การชำระเงินออนไลน์
                                            การชำระเงินผ่านช่องทางอิเล็กทรอนิกส์ การชำระเงินผ่านเคาน์เตอร์เซอร์วิส
                                            หรือร้านบริการรับชำระค่าสินค้าและบริการ การชำระเงิน ณ
                                            ศูนย์บริการลูกค้าของผู้ให้บริการ เป็นต้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.20{' '}
                                            <b>“รายการส่งเสริมการขาย”</b> หมายถึง
                                            รายการส่งเสริมการให้บริการของผู้ให้บริการ เช่น โปรโมชันแพ็กเกจอัตราค่าบริการ
                                            ส่วนลดค่าบริการ
                                            ซึ่งผู้ให้บริการกำหนดขึ้นสำหรับการให้บริการแต่ละประเภทแก่ผู้ใช้บริการ
                                            โดยผู้ใช้บริการมีสิทธิเลือกใช้รายการส่งเสริมการขายดังกล่าวกับการใช้บริการของตนได้ภายใต้เงื่อนไขที่ผู้ให้บริการกำหนด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.21{' '}
                                            <b>“สิทธิพิเศษอื่น ๆ”</b> หมายถึง สิทธิประโยชน์อื่น ๆ
                                            นอกเหนือจากรายการส่งเสริมการขายที่ผู้ให้บริการกำหนดขึ้นสำหรับการให้บริการแก่ผู้ใช้บริการโดยผู้ใช้บริการมีสิทธิเลือกรับสิทธิพิเศษอื่น
                                            ๆ ดังกล่าวเพิ่มเติมจากการใช้บริการได้ ภายใต้เงื่อนไขที่ผู้ให้บริการกำหนด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.22{' '}
                                            <b>“คณะกรรมการ”</b> หมายถึง คณะกรรมการกิจการกระจายเสียง
                                            กิจการโทรทัศน์และกิจการ โทรคมนาคมแห่งชาติ (“กสทช.”)
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.23{' '}
                                            <b>“ค่าบริการ”</b> หมายถึง
                                            ค่าธรรมเนียมหรือค่าใช้จ่ายอื่นใดที่ผู้ให้บริการเรียกเก็บจากผู้ใช้บริการ
                                            อันเนื่องจากการได้ใช้ประโยชน์หรือจะได้ใช้ประโยชน์ในบริการโทรศัพท์เคลื่อนที่ของผู้ให้บริการ
                                            รวมถึงค่าบริการโทรทางไกลระหว่างประเทศที่เรียกเก็บตามจำนวนหรือปริมาณการใช้ในแต่ละรอบการใช้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.24{' '}
                                            <b>“รอบค่าใช้บริการ”</b> หมายถึง
                                            รอบระยะเวลาของการให้บริการเพื่อใช้ในการเรียกเก็บค่าใช้บริการตามที่ผู้ให้บริการกำหนด
                                            ซึ่งไม่เกินกว่า 31 วันต่อรอบ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.25{' '}
                                            <b>“การระงับใช้บริการชั่วคราว”</b> หมายถึง
                                            การระงับการใช้บริการโทรคมนาคมทั้งการโทรออกและการรับสายเรียกเข้าเป็นการชั่วคราว
                                        </p>
                                        <p>
                                            <b>3. การทำสัญญาและการใช้บริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1
                                            เมื่อผู้ใช้บริการได้สมัครใช้บริการโดยวิธีการทำรายการลงทะเบียนบัตรประจำตัวผู้ใช้บริการ
                                            (SIM Card) เพื่อเปิดใช้บริการโทรศัพท์เคลื่อนที่กับผู้ให้บริการ
                                            หรือด้วยวิธีการทางอิเล็กทรอนิกส์ผ่านโทรศัพท์เคลื่อนที่
                                            รวมถึงการพิมพ์หรือเลือกข้อความและการกระทำใด ๆ
                                            ที่ทำให้ปรากฏซึ่งข้อความตามวิธีการที่ผู้ให้บริการกำหนดไว้
                                            ผ่านเครื่องโทรศัพท์เคลื่อนที่
                                            หรือผ่านทางเครือข่ายอินเทอร์เน็ตหรือสื่ออิเล็กทรอนิกส์อื่นใดตามที่ผู้ให้บริการกำหนด
                                            หรือเมื่อผู้ให้บริการได้เปิด
                                            หรือยินยอมให้ผู้ใช้บริการใช้บริการโทรศัพท์เคลื่อนที่แล้ว
                                            ถือว่าผู้ใช้บริการได้ตกลงทำสัญญาการให้บริการโทรคมนาคมกับผู้ให้บริการตามเงื่อนไขและข้อตกลงที่กำหนดไว้ในเอกสารนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.2
                                            ผู้ใช้บริการต้องให้ข้อมูล ชื่อ สกุล ที่อยู่พร้อมสำเนาบัตรประจำตัวประชาชน
                                            หรือบัตรอื่นใดที่หน่วยงานราชการออกให้
                                            เพื่อแสดงตัวในฐานะเป็นผู้ใช้บริการและผู้ครอบครองบัตรประจำตัวผู้ใช้บริการ
                                            (SIM Card) ทั้งนี้
                                            เป็นไปตามที่คณะกรรมการกำหนดให้ผู้ให้บริการจัดเก็บข้อมูลแสดงตน
                                            กรณีผู้ใช้บริการไม่ยินยอมให้ข้อมูลแสดงตน
                                            ผู้ให้บริการมีสิทธิระงับการใช้บริการชั่วคราวได้ตามกฎหมายและ/หรือประกาศหลักเกณฑ์ที่คณะกรรมการได้กำหนดไว้
                                            ทั้งในปัจจุบันและที่จะมีเกิดขึ้นในภายหน้า
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.3
                                            ผู้ใช้บริการจะได้รับสิทธิประโยชน์ในการใช้บริการตามเงื่อนไขและรายละเอียดที่กำหนดไว้ในรายการส่งเสริมการขายที่ผู้ให้บริการประกาศไว้ให้แก่ผู้ที่สมัครใช้บริการ
                                            หรือที่ใช้บริการอยู่ในแต่ละช่วงเวลานั้น ทั้งนี้
                                            ผู้ใช้บริการมีสิทธิขอเปลี่ยนแปลงรายการส่งเสริมการขายและการใช้บริการได้ตามรายการส่งเสริมการขายที่ผู้ให้บริการเปิดให้สมัครใช้ได้อยู่
                                            ณ เวลานั้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.4
                                            ผู้ใช้บริการจะต้องใช้บริการตามข้อกำหนดและเงื่อนไขที่ผู้ให้บริการกำหนด
                                            รวมทั้งการใช้บริการต้องไม่มีลักษณะอย่างหนึ่งอย่างใด ดังต่อไปนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                            ใช้บริการ หรืออนุญาตให้บุคคลอื่นใช้บริการในลักษณะหยาบคาย ลามก อนาจาร
                                            ไม่ชอบด้วยกฎหมาย หรือขัดต่อความสงบเรียบร้อย หรือศีลธรรมอันดีของประชาชน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                            ใช้เพื่อวัตถุประสงค์ในการข่มขู่ รบกวน คุกคาม ล่วงเกิน หรือเป็นการกระทำละเมิด
                                            ต่อสิทธิในความเป็นส่วนตัว หรือทำให้บุคคลอื่นเสื่อมเสียชื่อเสียง
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                            กระทำการใดอันเป็นการละเมิดสิทธิในทรัพย์สินทางปัญญา
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                            ใช้เพื่อการสร้างความเข้าใจผิด และ/หรือหลอกลวงประชาชน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                            ใช้ในลักษณะที่ต้องห้ามหรือขัดต่อกฎหมาย ระเบียบ จรรยาบรรณ หรือประกาศใด ๆ
                                            ที่ใช้บังคับ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                            ดำเนินการใด ๆ
                                            ในลักษณะของการแทรกแซงการให้บริการของผู้ให้บริการหรือผู้ให้บริการรายอื่น
                                            ซึ่งอาจก่อให้เกิดความเสียหายต่อผู้ให้บริการหรือโครงข่ายโทรคมนาคมอื่น ๆ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.5
                                            หากผู้ใช้บริการได้ใช้บริการอันเป็นการขัดต่อสัญญาให้บริการนี้
                                            หรือขัดต่อกฎหมายใด ๆ อันเป็นการละเมิดต่อบุคคลภายนอกและมีความเสียหายเกิดขึ้น
                                            ผู้ใช้บริการต้องรับผิดในความเสียหายที่เกิดขึ้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.6
                                            ผู้ใช้บริการจะต้องเติมเงินผ่านช่องทาง วิธีการ
                                            และด้วยจำนวนเงินขั้นต่ำตามที่ผู้ให้บริการกำหนด
                                            ทั้งนี้หากผู้ใช้บริการตกลงเข้าร่วมรายการการส่งเสริมการขายหรือขอรับสิทธิพิเศษอื่น
                                            ๆ ที่ผู้ให้บริการเสนอ ผู้ใช้บริการตกลงปฏิบัติตามข้อกำหนดหรือเงื่อนไขต่าง ๆ
                                            ที่เกี่ยวข้อง
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.7
                                            ผู้ให้บริการมีสิทธินำข้อมูลส่วนบุคคลของผู้ใช้บริการไปใช้ในการให้บริการได้
                                            โดยต้องได้รับความยินยอมจากผู้ใช้บริการ
                                            และเพื่อประโยชน์ในการดำเนินกิจการโทรคมนาคมและเป็นไปตามหลักเกณฑ์ที่กำหนดตามที่คณะกรรมการประกาศกำหนดในเรื่องมาตรการคุ้มครองสิทธิของผู้ใช้บริการโทรคมนาคมเกี่ยวกับข้อมูลส่วนบุคคลฯ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.8
                                            กรณีผู้ใช้บริการโอนสิทธิการใช้บริการโดยมอบการครอบครองบัตรประจำตัวผู้ใช้บริการ
                                            (SIM Card) ให้แก่บุคคลอื่น
                                            ผู้รับโอนหรือผู้ใช้บริการจะต้องดำเนินการให้ผู้รับโอนมาดำเนินการให้ข้อมูลรายละเอียดเกี่ยวกับผู้รับโอน
                                            เพื่อแสดงตัวในฐานะเป็นผู้ใช้บริการและผู้ครอบครองบัตรประจำตัวผู้ใช้บริการ
                                            (SIM Card) ทั้งนี้ เป็นไปตามที่คณะกรรมการกำหนดให้
                                            ผู้ให้บริการจัดเก็บข้อมูลแสดงตน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีผู้ใช้บริการมิได้ดำเนินการตามวรรคแรก
                                            ผู้ใช้บริการไม่สามารถยกเหตุแห่งการโอนสิทธิมาปฏิเสธความรับผิดชอบความเสียหายที่เกิดขึ้นอันเนื่องจากการใช้บริการโทรศัพท์เคลื่อนที่ของผู้รับโอนได้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;อนึ่ง
                                            กรณีผู้ให้บริการยินยอมให้ผู้ใช้บริการโอนสิทธิการใช้บริการให้กับบุคคลอื่นแล้ว
                                            ถือว่าสิทธิการใช้บริการโทรศัพท์เคลื่อนที่ตามรายการส่งเสริมการขาย
                                            สิทธิการใช้บริการโทรศัพท์ข้ามแดนอัตโนมัติ
                                            และ/หรือการใช้บริการโทรทางไกลระหว่างประเทศเดิมของผู้ใช้บริการเป็นอันสิ้นสุดลงทันที
                                            ในกรณีนี้ผู้รับโอนต้องดำเนินการสมัครหรือยื่นคำขอใช้บริการใหม่กับผู้ให้บริการต่อไป
                                        </p>
                                        <p>
                                            <b>4. การให้บริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ให้บริการเป็นผู้ให้บริการโทรศัพท์เคลื่อนที่แก่ผู้ใช้บริการที่ได้สมัคร
                                            หรือยื่นคําขอและทําสัญญากับผู้ให้บริการ
                                            ซึ่งจะมีสิทธิใช้เลขหมายโทรศัพท์เคลื่อนที่ตามที่ผู้ให้บริการจัดสรรให้
                                            เพื่อใช้ในการสื่อสารทางโทรศัพท์
                                            รวมตลอดถึงการใช้บริการเสริมและการใช้บริการประเภทอื่น ๆ
                                            ที่เกี่ยวเนื่องกับบริการโทรศัพท์เคลื่อนที่
                                            ตามที่ผู้ให้บริการกําหนดและที่เปิดให้บริการอยู่แล้วทั้งในปัจจุบันและที่จะจัดให้มีขึ้นในอนาคต
                                        </p>
                                        <p>
                                            <b>5. ข้อกําหนดและวิธีการใช้บริการเสริม</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1
                                            การใช้บริการเสริมประเภทที่กำหนดให้ต้องใช้รหัสผ่านหรือรหัสประจำตัวของผู้ใช้บริการ
                                            ผู้ใช้บริการต้องเก็บรักษารหัสดังกล่าวไว้เป็นความลับแต่เพียงผู้เดียวเท่านั้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2
                                            ผู้ใช้บริการเสริมมีสิทธิทำรายการบริการเสริมแต่ละประเภทได้ไม่เกินจำนวนครั้งและจำนวนเงินที่ผู้ให้บริการกำหนดไว้
                                            ตามรายละเอียดของบริการแต่ละประเภทที่ได้แจ้งแก่ผู้ใช้บริการผ่านสื่อต่าง ๆ
                                            และหากปรากฏว่าผู้ใช้บริการกดรหัสผ่านหรือรหัสประจำตัวผิดเกินกว่าที่กำหนด
                                            ผู้ใช้บริการจะไม่สามารถทำรายการใด ๆ
                                            ได้อีกจนกว่าผู้ใช้บริการจะติดต่อกับผู้ให้บริการ
                                            เพื่อแก้ไขให้สามารถใช้บริการได้ดังเดิม
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.3
                                            ผู้ใช้บริการยินยอมให้ผู้ให้บริการเป็นผู้เรียกเก็บเงินค่าบริการหรือค่าใช้จ่ายต่าง
                                            ๆ ทั้งหมดจากผู้ใช้บริการแทนผู้ร่วมให้บริการเสริม ทั้งนี้
                                            ผู้ให้บริการจะต้องร่วมรับผิดกับผู้ร่วมให้บริการเสริมต่อผู้ใช้บริการ
                                            ในความเสียหายที่เกิดขึ้นจากการให้บริการเสริมด้วย
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.4
                                            กรณีผู้ใช้บริการประสงค์จะบอกเลิกบริการเสริม
                                            ผู้ให้บริการต้องดำเนินการดังกล่าวทันที
                                            และกรณีมีข้อโต้แย้งหากผู้ให้บริการไม่สามารถพิสูจน์ได้ว่าผู้ใช้บริการแสดงเจตนาสมัครใช้บริการเสริมโดยชัดแจ้ง
                                            ผู้ให้บริการไม่มีสิทธิเรียกเก็บค่าบริการเสริมดังกล่าว
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.5
                                            ผู้ใช้บริการสามารถใช้บริการเสริมในต่างประเทศ
                                            หากผู้ให้บริการในต่างประเทศนั้นเปิดให้บริการนั้น ๆ
                                            โดยผู้ใช้บริการต้องเสียค่าบริการเพิ่มเติมตามอัตราของการบริการข้ามแดนอัตโนมัติตามที่ผู้ให้บริการประเทศนั้น
                                            ๆ หรือผู้ให้บริการได้กำหนดไว้
                                        </p>
                                        <p>
                                            <b>
                                                6. ข้อกำหนดและวิธีการใช้บริการข้ามแดนอัตโนมัติ
                                                และ/หรือบริการโทรทางไกลระหว่างประเทศ
                                            </b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีผู้ใช้บริการสมัครหรือยื่นคำขอใช้บริการข้ามแดนอัตโนมัติ
                                            และ/หรือบริการโทรทางไกลระหว่างประเทศ
                                            ผู้ใช้บริการต้องปฏิบัติตามเงื่อนไขและข้อกำหนดของการใช้บริการเพิ่มเติม
                                            ดังต่อไปนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.1
                                            เมื่อผู้ใช้บริการได้สมัครหรือยื่นคำขอใช้บริการข้ามแดนอัตโนมัติกับผู้ให้บริการแล้ว
                                            ผู้ใช้บริการสามารถนำเลขหมายโทรศัพท์เคลื่อนที่ของผู้ใช้บริการหรือเลขหมายอื่นที่ผู้ให้บริการกำหนดไปใช้บริการโทรศัพท์ข้ามแดนระหว่างประเทศ
                                            ในประเทศที่ผู้ให้บริการมีข้อตกลงดังกล่าวกับผู้ให้บริการในต่างประเทศ
                                            และ/หรือในกรณีที่ผู้ใช้บริการสมัครหรือยื่นคำขอใช้
                                            บริการโทรทางไกลระหว่างประเทศไว้กับผู้ให้บริการผู้ใช้บริการสามารถใช้โทรศัพท์จากประเทศไทยไปยังเลขหมายปลายทางที่เป็นเบอร์โทรศัพท์ของต่างประเทศได้ตามวิธีการและขั้นตอนที่ผู้ให้บริการประกาศและแจ้งให้ผู้ใช้บริการทราบ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.2
                                            ในระหว่างที่ใช้บริการข้ามแดนอัตโนมัติอยู่ในต่างประเทศ
                                            ผู้ใช้บริการจะต้องไม่ทำการจำหน่าย จ่าย โอน
                                            หรือเปลี่ยนแปลงสิทธิการเป็นผู้ใช้บริการโทรศัพท์เคลื่อนที่ของเลขหมายที่นำไปใช้งานในต่างประเทศ
                                        </p>
                                        <p>
                                            <b>7. การแก้ไขเปลี่ยนแปลง</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.1
                                            บรรดาข้อตกลง ข้อกำหนด และเงื่อนไขการใช้บริการ
                                            และเอกสารแนบท้ายสัญญาการให้บริการอื่นใดที่มีอยู่ก่อนแล้วระหว่างผู้ให้บริการกับผู้ใช้บริการในส่วนที่กำหนดไว้แล้วในสัญญาการให้บริการนี้
                                            ซึ่งขัดหรือแย้งกับสัญญาให้บริการนี้ให้ใช้สัญญาให้บริการนี้แทน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.2
                                            ผู้ให้บริการขอสงวนสิทธิ์ในการเปลี่ยนแปลงแก้ไขเพิ่มเติมสัญญาให้บริการนี้
                                            ตามที่ได้รับความเห็นชอบจากคณะกรรมการหรือตามที่คณะกรรมการประกาศกำหนด
                                            โดยจะแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
                                            ผ่านเว็บไซต์และศูนย์บริการลูกค้าของผู้ให้บริการ ตัวแทนศูนย์บริการลูกค้า
                                            หรือศูนย์บริการรับข้อร้องเรียนของผู้ให้บริการ หรือผ่านเลขหมายของผู้ให้บริการ
                                            ทั้งนี้ ไม่ตัดสิทธิผู้ใช้บริการที่จะบอกเลิกสัญญาให้บริการได้ทันที
                                            ในกรณีที่การเปลี่ยนแปลงสัญญาให้บริการมีผลเป็นการลดสิทธิหรือประโยชน์อันพึงได้รับของผู้ใช้บริการให้น้อยลง
                                            เว้นแต่เกิดจากเหตุตามที่กฎหมายกำหนด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.3
                                            กรณีที่สัญญาข้อหนึ่งข้อใดขัดหรือแย้งกับกฎหมาย ระเบียบ หรือประกาศ
                                            ซึ่งออกโดยคณะกรรมการหรือหน่วยงานของรัฐที่มีอำนาจตามกฎหมาย
                                            คู่สัญญาทั้งสองฝ่ายตกลงกันให้ถือว่าสาระสำคัญของสัญญาในข้ออื่น ๆ
                                            ที่ไม่ขัดหรือแย้งกับกฎหมาย ระเบียบ หรือประกาศดังกล่าวยังคงมีผลใช้บังคับต่อไป
                                        </p>
                                        <p>
                                            <b>8. การตรวจสอบข้อมูลการใช้บริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1
                                            ในกรณีที่ผู้ใช้บริการเห็นว่าผู้ให้บริการเรียกเก็บค่าบริการสูงกว่าอัตราที่กำหนดไว้หรือสูงกว่าที่เรียกเก็บจากผู้ใช้บริการรายอื่นที่ใช้บริการโทรคมนาคมในลักษณะหรือประเภทเดียวกัน
                                            หรือเห็นว่าผู้ให้บริการเรียกเก็บค่าบริการไม่ถูกต้อง
                                            ผู้ใช้บริการมีสิทธิยื่นคำขอตรวจสอบข้อมูลการใช้บริการได้ตามหลักเกณฑ์ที่กำหนด
                                            ตามประกาศ กทช.
                                            เรื่องมาตรการคุ้มครองสิทธิของผู้ใช้บริการโทรคมนาคมเกี่ยวกับข้อมูลส่วนบุคคลฯ
                                            ทั้งนี้ ผู้ใช้บริการต้องยื่นคำขอเป็นหนังสือ ณ
                                            ศูนย์บริการลูกค้าของผู้ให้บริการ เว็บไซด์ของผู้ให้บริการ
                                            หรือช่องทางอื่นตามที่ผู้ให้บริการกำหนดเพิ่มเติม
                                            และผู้ให้บริการจะดำเนินการตรวจสอบข้อเท็จจริงและแจ้งผลการตรวจสอบข้อมูลให้ผู้ใช้บริการทราบโดยเร็ว
                                            แต่ทั้งนี้ต้องไม่เกินกว่า 30 วัน นับแต่วันที่ผู้ใช้บริการมีคำขอ
                                            หากผู้ให้บริการไม่ดำเนินการภายในระยะเวลาที่กำหนด
                                            ให้ถือว่าผู้ให้บริการนั้นสิ้นสิทธิในการเรียกเก็บค่าธรรมเนียมหรือค่าบริการในจำนวนที่ผู้ใช้บริการได้โต้แย้งนั้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.2
                                            กรณีข้อเท็จจริงปรากฏว่าผู้ให้บริการเรียกเก็บค่าบริการเกินกว่าจำนวนที่เกิดขึ้นจากการใช้บริการจริง
                                            ผู้ให้บริการจะคืนเงินส่วนต่างของค่าบริการให้แก่ผู้ใช้บริการภายใน 30 วัน
                                            นับแต่วันที่ข้อเท็จจริงยุติ
                                            และผู้ให้บริการต้องชำระดอกเบี้ยในส่วนต่างในอัตราเท่ากับที่ได้กำหนดว่าจะเรียกเก็บจากผู้ใช้บริการ
                                            ในกรณีที่ผู้ใช้บริการผิดนัดโดยผู้ให้บริการจะจ่ายคืนเงินให้เป็นเงินสด
                                            หรือเช็ค หรือนำเข้าบัญชีเงินฝากของผู้ใช้บริการตามที่ผู้ใช้บริการแจ้งไว้
                                            หรือโอนเงินค่าบริการที่
                                            ผู้ใช้บริการชำระล่วงหน้าไว้ไปยังเลขหมายอื่นที่อยู่ภายในเครือข่ายเดียวกันตามที่ผู้ใช้บริการได้แจ้งความประสงค์ไว้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.3
                                            ผู้ให้บริการจะดำเนินการตรวจสอบข้อมูลการใช้บริการ แจ้งผล และคืนเงิน (ถ้ามี)
                                            ให้แก่ผู้ใช้บริการที่มีหลักฐานแสดงตนว่าเป็นผู้ใช้บริการที่แท้จริงเท่านั้น
                                        </p>
                                        <p>
                                            <b>9. อัตราและการเรียกเก็บค่าบริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.1
                                            ผู้ให้บริการจะเรียกเก็บค่าบริการต่อผู้ให้บริการตามอัตราที่ผู้ให้บริการกำหนด
                                            ทั้งนี้ อัตราค่าบริการประเภทเสียง (voice) บริการที่ไม่ใช่ประเภทเสียง
                                            (non-voice)
                                            ให้เป็นไปตามเงื่อนไขใบอนุญาตประกอบกิจการโทรคมนาคมแบบที่หนึ่งของผู้ให้บริการไปจนกว่าคณะกรรมการจะออกประกาศกำหนดอัตราขั้นสูงต่อไป
                                            ซึ่งผู้ให้บริการได้เผยแพร่และแจ้งให้ผู้ใช้บริการทราบเป็นการทั่วไป ณ ที่ทำการ
                                            และเว็บไซต์ของผู้ให้บริการ
                                            ผู้ให้บริการรับรองว่าจะไม่เรียกเก็บค่าธรรมเนียมหรือค่าบริการนอกเหนือจากที่กำหนดดังกล่าว
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.2
                                            ภายใต้อัตราค่าบริการตามข้อ 9.1
                                            ผู้ให้บริการอาจลดหรือยกเว้นการเรียกเก็บค่าบริการตามสัญญาให้บริการนี้ทั้งหมดหรือแต่บางส่วนได้
                                            โดยขึ้นอยู่กับเงื่อนไขของรายการส่งเสริมการขาย หรือสิทธิพิเศษอื่น ๆ
                                            ที่ผู้ใช้บริการเข้าร่วมหรือได้รับ ทั้งนี้
                                            ตามที่ผู้ใช้บริการได้แจ้งความประสงค์ให้ผู้ให้บริการทราบ
                                            ภายใต้เงื่อนไขการเข้าร่วมรายการส่งเสริมการขายหรือการขอรับสิทธิพิเศษอื่น ๆ
                                            ทั้งนี้ เงื่อนไขในการเข้าร่วมรายการส่งเสริมการขายแต่ละรายการ
                                            หรือการขอรับสิทธิพิเศษอื่น ๆ
                                            รวมถึงระยะเวลาของรายการส่งเสริมการขายแต่ละรายการ หรือการรับสิทธิอื่น ๆ
                                            จะเป็นไปตามที่ผู้ให้บริการและผู้ใช้บริการได้ตกลงกัน
                                        </p>
                                        <p>
                                            <b>10. วิธีการชำระค่าใช้บริการและกำหนดเวลาชำระค่าใช้บริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.1
                                            ผู้ใช้บริการต้องชำระค่าบริการและค่าธรรมเนียมให้แก่ผู้ให้บริการเป็นการล่วงหน้าก่อนการใช้บริการโทรศัพท์
                                            โดยวิธีการเติมเงิน (Top Up) ตามช่องทางการชำระเงินต่าง ๆ
                                            ที่ผู้ให้บริการกำหนดไว้ ดังนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                            การใช้บัตรเติมเงิน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                            ศูนย์บริการลูกค้าของผู้ให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                            เคาน์เตอร์เซอร์วิส
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                            ผ่านเครือข่ายการให้บริการอินเทอร์เน็ต
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีผู้ให้บริการแก้ไข
                                            ลด หรือเพิ่มเติมช่องทางและวิธีการเติมเงิน (Top Up)
                                            ผู้ให้บริการจะประกาศและแจ้งให้ผู้ใช้บริการทราบต่อไป
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.2
                                            ผู้ใช้บริการสามารถทำการเติมเงิน (Top Up)
                                            เพื่อการใช้บริการโทรศัพท์เคลื่อนที่ได้ตลอดเวลาที่ใช้บริการ
                                            กรณีที่ยังคงมียอดเงินคงเหลืออยู่ในระบบการใช้บริการและผู้ใช้บริการได้มีการเติมเงิน
                                            (Top Up) ภายในกำหนดเวลา
                                            ระบบจะนำยอดเงินเก่าที่ยังคงเหลืออยู่รวมกับยอดเงินใหม่
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.3
                                            ผู้ใช้บริการสามารถตรวจสอบวงเงินคงเหลืออยู่ในระบบการใช้บริการโทรศัพท์ได้ตลอดเวลา
                                            และผู้ให้บริการจะแจ้งวงเงินคงเหลือให้ผู้ใช้บริการทราบเป็นลายลักษณ์อักษรผ่านระบบการให้บริการการส่งข้อความสั้น
                                            (SMS)
                                        </p>
                                        <p>
                                            <b>11. ระยะเวลาการใช้บริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11.1
                                            การเติมเงินแต่ละครั้งตามมูลค่าที่ผู้ให้บริการกำหนดไว้
                                            ผู้ใช้บริการจะได้รับระยะเวลาการใช้งานเป็นจำนวนไม่น้อยกว่า 30 วัน ทั้งนี้
                                            มีระยะเวลาสะสมสูงสุด 365 วัน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11.2
                                            หากผู้ใช้บริการไม่มีระยะเวลาสะสมคงเหลือ ผู้ให้บริการขอสงวนสิทธิ์ดำเนินการ
                                            ดังนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                            ช่วงที่ 1 ระงับการโทรออก แต่สามารถรับสายและเติมเงินได้ภายใน 30 วัน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                            ช่วงที่ 2 ระงับการโทรออกและการรับสาย แต่สามารถเติมเงินได้ภายใน 30 วัน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                            ช่วงที่ 3 ไม่สามารถใช้บริการได้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ทั้งนี้
                                            ผู้ใช้บริการสามารถโอนเงินค่าบริการที่ชำระล่วงหน้าไว้ไปยังเลขหมายอื่นที่อยู่ภายใต้บริการของเครือข่าย
                                            Feels ได้
                                        </p>
                                        <p>
                                            <b>12. การแจ้งขอระงับใช้บริการชั่วคราวของผู้ใช้บริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12.1
                                            ในกรณีที่ผู้ใช้บริการมีเหตุจำเป็นไม่สามารถใช้บริการโทรศัพท์เคลื่อนที่ได้เป็นการชั่วคราว
                                            ผู้ใช้บริการสามารถขอระงับการใช้บริการเป็นการชั่วคราวได้
                                            โดยต้องแจ้งความประสงค์เป็นคราว ๆ ด้วยตนเอง
                                            หรือส่งโทรสารพร้อมสำเนาบัตรประจำตัวประชาชนมายังศูนย์บริการลูกค้าหรือตัวแทนศูนย์บริการลูกค้าเป็นการล่วงหน้าไม่น้อยกว่า
                                            3 วัน ทั้งนี้ ผู้ใช้บริการสามารถขอระงับการใช้บริการได้คราวละไม่เกิน 15 วัน
                                            โดยการขอระงับใช้ชั่วคราวในแต่ละครั้งต้องห่างกันอย่างน้อย 180 วัน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีมีเหตุสุดวิสัยเกิดขึ้นกับผู้ใช้บริการจนเป็นเหตุให้ผู้ใช้บริการไม่สามารถใช้บริการได้
                                            ผู้ให้บริการตกลงให้ผู้ใช้บริการระงับการใช้บริการชั่วคราวได้
                                            ตามระยะเวลาที่ผู้ใช้บริการร้องขอ
                                            แต่ทั้งนี้ไม่เกินกว่าระยะเวลาที่เหตุสุดวิสัยนั้นคงอยู่
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในการขอระงับการใช้บริการชั่วคราว
                                            ผู้ใช้บริการไม่ต้องเสียค่าธรรมเนียมหรือค่าใช้จ่ายใด ๆ ในการขอระงับบริการ
                                            เว้นแต่กรณีที่ผู้ใช้บริการระงับบริการเกินกว่าระยะเวลาขั้นสูงที่ผู้ใช้บริการกำหนดไว้
                                            ผู้ให้บริการมีสิทธิยกเลิกการให้บริการได้โดยแจ้งให้ผู้ใช้บริการทราบล่วงหน้าเป็นหนังสือไม่น้อยกว่า
                                            30 วัน
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12.2
                                            ผู้ให้บริการจะเปิดให้ผู้ใช้บริการได้ใช้บริการทันที
                                            ในวันถัดจากวันที่ครบกำหนดการขอระงับใช้บริการชั่วคราว
                                            โดยไม่เรียกเก็บค่าใช้จ่ายใด ๆ ในการขอเปิดใช้บริการ
                                        </p>
                                        <p>
                                            <b>13. การแจ้งขอระงับการให้บริการชั่วคราวของผู้ให้บริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อมีเหตุจำเป็น
                                            ผู้ให้บริการมีสิทธิระงับการให้บริการโทรคมนาคมต่อผู้ใช้บริการเป็นการชั่วคราวได้
                                            โดยแจ้งเป็นหนังสือพร้อมระบุเหตุให้ผู้ใช้บริการทราบล่วงหน้าไม่น้อยกว่า 30 วัน
                                            เว้นแต่กรณีดังต่อไปนี้ ผู้ให้บริการสามารถระงับการให้บริการได้ทันที
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                            เกิดเหตุสุดวิสัยขึ้นแก่ผู้ให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                            ผู้ใช้บริการถึงแก่ความตาย หรือสิ้นสุดสภาพนิติบุคคล
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                            ผู้ใช้บริการใช้เอกสารปลอม หรือแสดงข้อความอันเป็นเท็จในการขอใช้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                            ผู้ให้บริการพิสูจน์ได้ว่าบริการโทรคมนาคมที่ให้แก่ผู้ใช้บริการถูกนำไปใช้โดยมิชอบด้วยกฎหมายหรือฝ่าฝืนต่อสัญญาให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                            ผู้ให้บริการมีเหตุอันเชื่อได้ว่าผู้ใช้บริการมีพฤติกรรมฉ้อฉลในการใช้บริการหรือนำบริการไปใช้เพื่อแสวงหารายได้โดยมีเจตนาจะไม่ชำระค่าใช้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                            ผู้ให้บริการมีเหตุที่จำเป็นต้องบำรุงรักษาหรือแก้ไขระบบโทรคมนาคมที่ใช้ในการให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)
                                            ผู้ใช้บริการไม่ปฏิบัติตามกฎระเบียบ หรือคำสั่งที่ออกโดย กสทช.
                                        </p>
                                        <p>
                                            <b>
                                                14. กรณีเครื่องโทรศัพท์เคลื่อนที่และ/หรือ SIM Card สูญหายหรือถูกโจรกรรม
                                            </b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;หากเครื่องโทรศัพท์เคลื่อนที่และ/หรือ
                                            SIM Card สูญหายหรือถูกโจรกรรม
                                            ให้ผู้ใช้บริการแจ้งเหตุสูญหายหรือถูกโจรกรรมให้ผู้ให้บริการทราบ ณ
                                            ศูนย์บริการลูกค้าของผู้ให้บริการเป็นหนังสือ หรือทางโทรศัพท์
                                            หรือโดยวิธีการอื่นใดตามผู้ให้บริการกำหนด
                                            และสามารถตรวจสอบได้ว่าเป็นผู้ใช้บริการจริง
                                            ผู้ให้บริการจะดำเนินการระงับการให้บริการโดยทันทีที่ได้รับแจ้งหากมีค่าใช้บริการเกิดขึ้นหลังจากการแจ้งสูญหายหรือถูกโจรกรรม
                                            ผู้ใช้บริการไม่ต้องรับผิดเว้นแต่ค่าใช้บริการจะเกิดจากการกระทำของผู้ใช้บริการเอง
                                        </p>
                                        <p>
                                            <b>
                                                15.
                                                เหตุแห่งการปฏิเสธการให้บริการผู้ให้บริการมีสิทธิปฏิเสธการให้บริการแก่ผู้ยื่นคำขอใช้บริการได้
                                                ดังนี้
                                            </b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1)
                                            เมื่อผู้ใช้บริการปฏิเสธไม่ให้ข้อมูลรายละเอียดการแสดงตัวในฐานะเป็นผู้ใช้บริการและผู้ครอบครองบัตรประจำตัวผู้ใช้บริการ
                                            (SIM Card)
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2)
                                            เมื่อปรากฏว่าผู้ใช้บริการถูกระงับการให้บริการหรือเคยถูกผู้ให้บริการระงับการให้บริการ
                                            เนื่องมาจากเหตุข้อหนึ่งข้อใดตามข้อ 16.
                                        </p>
                                        <p>
                                            <b>16. เหตุแห่งการเลิกสัญญาของผู้ให้บริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่มีเหตุผลดังต่อไปนี้
                                            ผู้ให้บริการอาจใช้สิทธิเลิกสัญญาให้บริการได้ทันที
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                            เมื่อผู้ใช้บริการไม่มีระยะเวลาสะสมคงเหลือตามข้อ 11.2 (3)
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                            ผู้ใช้บริการถึงแก่ความตาย หรือสิ้นสุดสภาพนิติบุคคล
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                            ผู้ให้บริการมีเหตุผลอันเชื่อได้ว่าผู้ใช้บริการมีพฤติกรรมฉ้อฉลในการใช้บริการหรือนำบริการไปใช้โดยผิดกฎหมายหรือฝ่าฝืนข้อห้ามในสัญญาให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                            ผู้ให้บริการไม่สามารถให้บริการได้
                                            โดยเหตุที่อยู่นอกเหนือการควบคุมของผู้ให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
                                            ผู้ใช้บริการโอนสิทธิการใช้บริการตามสัญญานี้
                                            โดยไม่ได้รับความยินยอมจากผู้ให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
                                            เป็นการยกเลิกโดยบทบัญญัติแห่งกฎหมาย
                                        </p>
                                        <p>
                                            <b>17. เหตุแห่งการเลิกสัญญาของผู้ใช้บริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ใช้บริการบอกเลิกสัญญาให้บริการในเวลาใดก็ได้โดยบอกกล่าวเป็นหนังสือให้แก่ผู้ให้บริการทราบล่วงหน้าไม่น้อยกว่า
                                            5 วันทำการ หรือด้วยวิธีอื่นใดตามที่ผู้ให้บริการกำหนด
                                            เว้นแต่ในกรณีดังต่อไปนี้ ผู้ใช้บริการอาจใช้สิทธิเลิกสัญญาให้บริการได้ทันที
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1)
                                            ผู้ใช้บริการไม่สามารถรับบริการจากผู้ให้บริการได้ด้วยเหตุผลที่เกิดขึ้นอย่างต่อเนื่อง
                                            และอยู่เหนือการควบคุมของผู้ให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2)
                                            ผู้ให้บริการได้ละเมิดข้อตกลงอันเป็นสาระสำคัญของสัญญาให้บริการ
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3)
                                            ผู้ให้บริการตกเป็นบุคคลล้มละลาย
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4)
                                            ผู้ให้บริการเปลี่ยนแปลงข้อตกลงหรือเงื่อนไขในสัญญาให้บริการ
                                            ซึ่งมีผลเป็นการลดสิทธิหรือประโยชน์อันพึงได้รับของผู้ใช้บริการลงเว้นแต่เกิดจากเหตุตามที่กฎหมายกำหนด
                                        </p>
                                        <p>
                                            <b>18. การคืนเงินค่าบริการ</b>
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1)
                                            เมื่อสัญญาเลิกกันไม่ว่าด้วยเหตุใด ๆ
                                            หากปรากฏว่าผู้ให้บริการมีเงินค้างชำระแก่ผู้ใช้บริการหลังจากที่ได้หักชำระหนี้ค่าบริการและ/หรือค่าธรรมเนียมที่ผู้ใช้บริการค้างชำระ
                                            โดยจะไม่รวมถึงค่าโบนัส ค่าโทรพิเศษ
                                            หรือบริการอื่นที่ผู้ให้บริการเสนอว่าจะเติม เคยเติม หรือแจ้งว่าจะเติมในอนาคต
                                            ผู้ให้บริการจะคืนเงินส่วนที่ผู้ให้บริการได้รับไว้ให้แก่ผู้ใช้บริการที่แท้จริง
                                            หรือบุคคลที่ได้รับมอบอำนาจจากผู้ใช้บริการภายใน 30 วัน นับแต่วันเลิกสัญญา
                                            ด้วยเงินสด เช็ค หรือนำเข้าบัญชีเงินฝากของผู้ใช้บริการ
                                            หรือตามวิธีการที่ผู้ใช้บริการได้แจ้งความประสงค์ไว้ ทั้งนี้
                                            สามารถโอนเงินค่าบริการที่ชำระล่วงหน้าไว้ไปยังเลขหมายระบบ Pre-Paid
                                            อื่นที่อยู่ภายในเครือข่ายเดียวกันตามที่ผู้ใช้บริการได้แจ้งความประสงค์ไว้เท่านั้น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2)
                                            กรณีผู้ให้บริการไม่สามารถคืนเงินค้างชำระให้แก่ผู้ใช้บริการได้ภายในระยะเวลาที่กำหนด
                                            ผู้ให้บริการต้องชำระค่าเสียประโยชน์ในอัตราเท่ากับอัตราตอกเบี้ยที่ผู้ให้บริการคิดจากผู้ใช้บริการกรณีผู้ใช้บริการผิดนัดไม่ชำระค่าธรรมเนียม
                                            หรือค่าบริการแก่ผู้ให้บริการ
                                            แต่ทั้งนี้ไม่ตัดสิทธิผู้ใช้บริการที่จะเรียกค่าเสียหายอย่างอื่น
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ใช้บริการได้ศึกษารายละเอียดของสัญญาการให้บริการโทรคมนาคมประเภทบริการโทรศัพท์เคลื่อนที่แบบชำระค่าธรรมเนียมและค่าบริการล่วงหน้า
                                            (Pre-Paid)
                                            ของผู้ให้บริการและเข้าใจข้อกำหนดและเงื่อนไขของสัญญานี้แล้วจึงตกลงใช้บริการ
                                            โดยยินยอมปฏิบัติตามสัญญาการให้บริการโทรคมนาคมนี้ทุกประการ ทั้งนี้
                                            หากผู้ใช้บริการได้รับความเดือดร้อนจากการใช้บริการของผู้ให้บริการตามสัญญา
                                            สามารถร้องเรียนโดยตรงต่อผู้ให้บริการ ดังต่อไปนี้
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                                            ยื่นเรื่องร้องเรียนด้วยตนเองที่ศูนย์บริการลูกค้า บริษัท เคโฟร์ คอมมูนิเคชั่น
                                            จำกัด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                                            ทางไปรษณีย์ถึง
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัท
                                            เคโฟร์ คอมมูนิเคชั่น จำกัด
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เลขที่
                                            21/111-112 ถนนพระราม 9 แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310
                                        </p>
                                        <p>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
                                            ทางโทรศัพท์ผ่านหมายเลขโทรศัพท์ Call Center 02-142 9965
                                        </p>
                                        <div className='row justify-content-center m-0'>
                                            <div className='col-12 p-0 text-center'>
                                                <b>บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด</b>
                                            </div>
                                        </div>
                                    </div>
                                </OverlayScrollbarsComponent>
                                <div className='row pl-5 pr-5 mt-5'>
                                    <div className='text-center'>
                                        <div className='radio-inline'>
                                            <label className='radio radio-success'>
                                                <input
                                                    type='radio'
                                                    name='agreement'
                                                    checked={props.agreement}
                                                    onClick={() => props.setAgreement(!props.agreement)}
                                                    readOnly
                                                />
                                                <span />
                                                ยอมรับ
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                    <div className='mr-2' />
                                    <div>
                                        <button
                                            type='button'
                                            className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                            disabled={!props.agreement}
                                            onClick={btnNext}
                                        >
                                            ตกลง
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
