import React from 'react';
import MaskedInput from 'react-text-mask';
import TextareaAutosize from 'react-textarea-autosize';
import { maxLengthCheck, validateCitizenid, validateNumber, validateThai } from '../../../../../../_metronic/_helpers';

export function FeelsInformation(props) {
    const btnNext = () => {
        let message = '';
        if (props.information.citizenid === '' && message === '') {
            message = 'กรุณากรอกหมายเลขบัตรประชาชน';
        } else if (props.information.citizenid !== '' && message === '') {
            if (props.information.citizenid.length !== 13) {
                message = 'กรุณากรอกหมายเลขบัตรประชาชนให้ครบ 13 หลัก';
            } else {
                let citizenidstatus = validateCitizenid(props.information.citizenid);
                if (!citizenidstatus) {
                    message = 'กรุณากรอกหมายเลขบัตรประชาชนให้ถูกต้อง';
                }
            }
        }
        if ((props.information.lasercode === '' || props.information.lasercode.includes('_')) && message === '') {
            message = 'กรุณากรอกรหัสหลังบัตรประชาชน';
        }
        if (props.information.firstname === '' && message === '') {
            message = 'กรุณากรอกชื่อ';
        }
        if (props.information.lastname === '' && message === '') {
            message = 'กรุณากรอกนามสกุล';
        }
        if (props.information.religion === '' && message === '') {
            message = 'กรุณากรอกศาสนา';
        }
        if (props.information.birthday === '' && message === '') {
            message = 'กรุณากรอกวัน/เดือน/ปีเกิด';
        } else if (props.information.birthday !== '' && message === '') {
            if (props.information.birthday.includes('_')) {
                message = 'กรุณากรอกวัน/เดือน/ปีเกิดให้ครบ';
            } else {
                let birthdatelist = props.information.birthday.split('/');
                let maxdate = new Date(parseInt(birthdatelist[2]), parseInt(birthdatelist[1]), 0).getDate();
                if (parseInt(birthdatelist[1]) > 12 || parseInt(birthdatelist[1]) < 0) {
                    message = 'กรุณากรอกเดือนเกิดให้ถูกต้อง';
                }
                if (parseInt(birthdatelist[2] !== 0)) {
                    if (parseInt(birthdatelist[0]) > maxdate || parseInt(birthdatelist[0]) < 0) {
                        message = 'กรุณากรอกวันเกิดให้ถูกต้อง';
                    }
                } else {
                    if (parseInt(birthdatelist[1]) !== 2) {
                        if (parseInt(birthdatelist[0]) > maxdate || parseInt(birthdatelist[0]) < 0) {
                            message = 'กรุณากรอกวันเกิดให้ถูกต้อง';
                        }
                    }
                }
            }
        }
        if (props.information.address === '' && message === '') {
            message = 'กรุณากรอกที่อยู่ตามบัตรประชาชน';
        }
        if (props.information.dateissue === '' && message === '') {
            message = 'กรุณากรอกวันออกบัตร';
        } else if (props.information.dateissue !== '' && message === '') {
            if (props.information.dateissue.includes('_')) {
                message = 'กรุณากรอกวันออกบัตรให้ครบ';
            } else {
                let dateissuelist = props.information.dateissue.split('/');
                let maxdate = new Date(parseInt(dateissuelist[2]), parseInt(dateissuelist[1]), 0).getDate();
                if (parseInt(dateissuelist[1]) > 12 || parseInt(dateissuelist[1]) < 0) {
                    message = 'กรุณากรอกเดือนให้ถูกต้อง';
                }
                if (parseInt(dateissuelist[2] !== 0)) {
                    if (parseInt(dateissuelist[0]) > maxdate || parseInt(dateissuelist[0]) < 0) {
                        message = 'กรุณากรอกวันให้ถูกต้อง';
                    }
                } else {
                    if (parseInt(dateissuelist[1]) !== 2) {
                        if (parseInt(dateissuelist[0]) > maxdate || parseInt(dateissuelist[0]) < 0) {
                            message = 'กรุณากรอกวันให้ถูกต้อง';
                        }
                    }
                }
            }
        }
        if (props.information.dateexpire === '' && message === '') {
            message = 'กรุณากรอกวันออกบัตร';
        } else if (props.information.dateexpire !== '' && message === '') {
            if (props.information.dateexpire.includes('_')) {
                message = 'กรุณากรอกวันออกบัตรให้ครบ';
            } else {
                let dateexpirelist = props.information.dateexpire.split('/');
                let maxdate = new Date(parseInt(dateexpirelist[2]), parseInt(dateexpirelist[1]), 0).getDate();
                if (parseInt(dateexpirelist[1]) > 12 || parseInt(dateexpirelist[1]) < 0) {
                    message = 'กรุณากรอกเดือนให้ถูกต้อง';
                }
                if (parseInt(dateexpirelist[2] !== 0)) {
                    if (parseInt(dateexpirelist[0]) > maxdate || parseInt(dateexpirelist[0]) < 0) {
                        message = 'กรุณากรอกวันให้ถูกต้อง';
                    }
                } else {
                    if (parseInt(dateexpirelist[1]) !== 2) {
                        if (parseInt(dateexpirelist[0]) > maxdate || parseInt(dateexpirelist[0]) < 0) {
                            message = 'กรุณากรอกวันให้ถูกต้อง';
                        }
                    }
                }
            }
        }
        if (props.information.contactnumber === '' && message === '') {
            message = 'กรุณากรอกเบอร์โทรศัพท์ติดต่อ';
        } else if (props.information.contactnumber !== '' && message === '') {
            if (props.information.contactnumber.length !== 10 || props.information.contactnumber.charAt(0) !== '0') {
                message = 'กรุณากรอกเบอร์โทรศัพท์ติดต่อให้ถูกต้อง';
            }
        }
        if (message !== '') {
            props.SwalBs.fire({
                // title: 'ผิดพลาด!',
                html: `<div class='alert-text'>${message}</div>`,
                icon: 'warning',
                confirmButtonColor: '#ffa800',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        props.setPage('personalimage');
    };

    const handleChange = (e) => {
        let information = props.information;
        if (Object.keys(props.information).includes(e.target.name)) {
            information[e.target.name] = e.target.value;
        }
        props.setInformation(information);
    };
    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Feels-logo'
                                    src={props.toAbsoluteUrl('/media/logos/feels-full-logo.svg')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>ลงทะเบียนซิมออนไลน์</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center' />
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>ตรวจสอบ</div>
                                                    ข้อมูลส่วนตัว
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center pb-8 px-8 pb-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <form
                                                    className='form mt-0 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='pb-5'>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        หมายเลขบัตรประชาชน<span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        inputMode='tel'
                                                                        className='form-control form-control-lg'
                                                                        name='citizenid'
                                                                        defaultValue={props.information.citizenid}
                                                                        onChange={(e) => handleChange(e)}
                                                                        maxLength='13'
                                                                        onInput={maxLengthCheck}
                                                                        onKeyPress={(event) => {
                                                                            validateNumber(event);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        รหัสหลังบัตรประชาชน<span className='required-text'>*</span>
                                                                    </label>
                                                                    <MaskedInput
                                                                        mask={[
                                                                            /[A-Z0-9]/,
                                                                            /[A-Z0-9]/,
                                                                            /[0-9]/,
                                                                            '-',
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            '-',
                                                                            /[0-9]/,
                                                                            /[0-9]/
                                                                        ]}
                                                                        className='form-control form-control-lg'
                                                                        name='lasercode'
                                                                        defaultValue={props.information.lasercode}
                                                                        // placeholderChar={}
                                                                        keepCharPositions={true}
                                                                        onChange={(e) => handleChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>
                                                                        ชื่อ<span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='firstname'
                                                                        defaultValue={props.information.firstname}
                                                                        onChange={(e) => handleChange(e)}
                                                                        onKeyPress={(event) => {
                                                                            validateThai(event);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>
                                                                        นามสกุล<span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='lastname'
                                                                        defaultValue={props.information.lastname}
                                                                        onChange={(e) => handleChange(e)}
                                                                        onKeyPress={(event) => {
                                                                            validateThai(event);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>
                                                                        ศาสนา<span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='religion'
                                                                        defaultValue={props.information.religion}
                                                                        onChange={(e) => handleChange(e)}
                                                                        onKeyPress={(event) => {
                                                                            validateThai(event);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>
                                                                        วัน/เดือน/ปีเกิด (ปีคริสต์ศักราช)<span className='required-text'>*</span>
                                                                    </label>
                                                                    <div className='form-group fv-plugins-icon-container'>
                                                                        <div className='input-group'>
                                                                            <MaskedInput
                                                                                mask={[
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    '/',
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    '/',
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    /[0-9]/
                                                                                ]}
                                                                                className='form-control form-control-lg'
                                                                                name='birthday'
                                                                                defaultValue={
                                                                                    props.information.birthday
                                                                                }
                                                                                // placeholderChar={}
                                                                                keepCharPositions={true}
                                                                                onChange={(e) => handleChange(e)}
                                                                            />
                                                                            <div className='input-group-append btn-light-info'>
                                                                                <span className='input-group-text'>
                                                                                    หากไม่ทราบให้ใส่ "0"
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='form-group fv-plugins-icon-container'>
                                                            <label>
                                                                ที่อยู่ตามบัตรประชาชน<span className='required-text'>*</span>
                                                            </label>
                                                            <TextareaAutosize
                                                                className='form-control form-control-lg textarea-min-h'
                                                                name='address'
                                                                defaultValue={props.information.address}
                                                                onChange={(e) => handleChange(e)}
                                                            />
                                                            {/* <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    กรุณากรอกที่อยู่ตามบัตรประชาชน
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>
                                                                        วันออกบัตร (ปีคริสต์ศักราช)<span className='required-text'>*</span>
                                                                    </label>
                                                                    <div className='form-group fv-plugins-icon-container'>
                                                                        <div className='input-group'>
                                                                            <MaskedInput
                                                                                mask={[
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    '/',
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    '/',
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    /[0-9]/
                                                                                ]}
                                                                                className='form-control form-control-lg'
                                                                                name='dateissue'
                                                                                defaultValue={
                                                                                    props.information.dateissue
                                                                                }
                                                                                // placeholderChar={}
                                                                                keepCharPositions={true}
                                                                                onChange={(e) => handleChange(e)}
                                                                            />
                                                                            <div className='input-group-append btn-light-info'>
                                                                                <span className='input-group-text'>
                                                                                    หากไม่ทราบให้ใส่ "0"
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>
                                                                        วันบัตรหมดอายุ (ปีคริสต์ศักราช)<span className='required-text'>*</span>
                                                                    </label>
                                                                    <div className='form-group fv-plugins-icon-container'>
                                                                        <div className='input-group'>
                                                                            <MaskedInput
                                                                                mask={[
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    '/',
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    '/',
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    /[0-9]/,
                                                                                    /[0-9]/
                                                                                ]}
                                                                                className='form-control form-control-lg'
                                                                                name='dateexpire'
                                                                                defaultValue={
                                                                                    props.information.dateexpire
                                                                                }
                                                                                // placeholderChar={}
                                                                                keepCharPositions={true}
                                                                                onChange={(e) => handleChange(e)}
                                                                            />
                                                                            <div className='input-group-append btn-light-info'>
                                                                                <span className='input-group-text'>
                                                                                    หากไม่ทราบให้ใส่ "0"
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        เบอร์โทรศัพท์ติดต่อ<span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        inputMode='tel'
                                                                        className='form-control form-control-lg'
                                                                        name='contactnumber'
                                                                        defaultValue={props.information.contactnumber}
                                                                        onChange={(e) => handleChange(e)}
                                                                        maxLength='10'
                                                                        onInput={maxLengthCheck}
                                                                        onKeyPress={(event) => {
                                                                            validateNumber(event);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group'>
                                                                    <label>เบอร์ที่ต้องการลงทะเบียน</label>
                                                                    <input
                                                                        type='text'
                                                                        inputMode='tel'
                                                                        className='form-control form-control-solid form-control-lg'
                                                                        defaultValue={props.information.phonenumber}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center border-top mt-5 pt-10'>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success btn-feels-main font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
