import React from 'react';
import SVG from 'react-inlinesvg';
import Select from 'react-select';
import { validateNumber } from '../../../../../../_metronic/_helpers';
import { bank } from '../../../../../bank';
import { ControlComponent, customStyles } from './dropdown';

export function DealerBank(props) {
    const selectBank = (item) => {
        if (item) {
            props.setBank(item);
        } else {
            props.setBank('');
        }
    };

    const btnBack = () => {
        props.setPage('address');
    };

    const btnNext = () => {
        let message = '';
        if (props.bank === '' && message === '') {
            message = 'กรุณาเลือกธนาคาร';
        }
        if (props.banknumber === '' && message === '') {
            message = 'กรุณากรอกเลขที่บัญชี';
        }
        if (message !== '') {
            props.SwalBs.fire({
                // title: 'ผิดพลาด!',
                html: `<div class='alert-text'>${message}</div>`,
                icon: 'warning',
                confirmButtonColor: '#ffa800',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        if (!props.edit) {
            props.setPage('package');
        } else {
            props.setPage('summary');
        }
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครดีลเลอร์ใหม่</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย :{props.suggesttitle}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครดีลเลอร์ใหม่</div>
                                                    กรอกข้อมูลบัญชีธนาคาร
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <form
                                                    className='form mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='pb-5'>
                                                        <div className='row'>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        ธนาคาร<span className='required-text'>*</span>
                                                                    </label>
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent
                                                                        }}
                                                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                                        value={props.bank}
                                                                        options={bank}
                                                                        placeholder='เลือก'
                                                                        name='bank'
                                                                        onChange={selectBank}
                                                                        isClearable={true}
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>
                                                                        เลขที่บัญชี<span className='required-text'>*</span>
                                                                    </label>
                                                                    <input
                                                                        type='text'
                                                                        inputMode='tel'
                                                                        className='form-control form-control-lg'
                                                                        name='banknumber'
                                                                        value={props.banknumber}
                                                                        onChange={(e) =>
                                                                            props.setBankNumber(e.target.value)}
                                                                        onKeyPress={(event) => validateNumber(event)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>สาขา</label>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='bankbranch'
                                                                        value={props.bankbranch}
                                                                        onChange={(e) =>
                                                                            props.setBankBranch(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <label>ชื่อบัญชี</label>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='bankowner'
                                                                        value={props.bankowner}
                                                                        onChange={(e) =>
                                                                            props.setBankOwner(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                                        <div className='mr-2'>
                                                            {!props.edit && (
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-light-primary font-weight-bold text-uppercase px-9 py-4'
                                                                    onClick={btnBack}
                                                                >
                                                                    ก่อนหน้า
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
