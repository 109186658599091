import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import NumberFormat from 'react-number-format';

export function SubscriberPackage(props) {
    const [ packages, setPackages ] = useState({});

    useEffect(() => {
        getPackage();
    }, []);

    const btnNext = () => {
        let message = '';
        if (props.selectpackage === '' && message === '') {
            message = 'กรุณาเลือกขนาดสัญญาดีลเลอร์';
        }
        if (message !== '') {
            props.SwalBs.fire({
                // title: 'ผิดพลาด!',
                html: `<div class='alert-text'>${message}</div>`,
                icon: 'warning',
                confirmButtonColor: '#ffa800',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        props.setPage('phonenumber');
    };

    const btnSelect = (object) => {
        props.setSelectPackage(object.code);
        props.setPackageObject(object);
    };

    const getPackage = () => {
        props.setLoading(true);
        fetch(props.config.dealer_url + props.config.version + 'product/getproduct', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: props.config.secret
            },
            body: JSON.stringify({
                packs_id: [ '6', '103' ]
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                setPackages(result);
            })
            .catch((error) => {
                props.setLoading(false);
            });
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครผู้ใช้งานใหม่</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-min'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครผู้ใช้งานใหม่</div>
                                                    เลือกแพ็กเกจ
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <form
                                                    className='form mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='form-group fv-plugins-icon-container'>
                                                        <label>เลือกประเภทเบอร์ที่ต้องการสมัครผู้ใช้งานใหม่</label>
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <label className='option'>
                                                                    <span className='option-control'>
                                                                        <span className='radio'>
                                                                            <input
                                                                                type='radio'
                                                                                name='type'
                                                                                checked={props.type === 'new'}
                                                                                onChange={() => props.setType('new')}
                                                                                readOnly
                                                                            />
                                                                            <span />
                                                                        </span>
                                                                    </span>
                                                                    <span className='option-label'>
                                                                        <span className='option-head'>
                                                                            <span className='option-title'>
                                                                                เปิดเบอร์ใหม่{' '}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <label className='option'>
                                                                    <span className='option-control'>
                                                                        <span className='radio'>
                                                                            <input
                                                                                type='radio'
                                                                                name='type'
                                                                                checked={props.type === 'old'}
                                                                                onChange={() => props.setType('old')}
                                                                                readOnly
                                                                            />
                                                                            <span />
                                                                        </span>
                                                                    </span>
                                                                    <span className='option-label'>
                                                                        <span className='option-head'>
                                                                            <span className='option-title'>
                                                                                ใช้เบอร์ที่ท่านเป็นเจ้าของอยู่แล้ว{' '}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='form-group fv-plugins-icon-container'>
                                                        <label>เลือกขนาดแพ็กเกจที่ต้องการใช้งาน</label>
                                                        {!props.loading ? (
                                                            <div>
                                                                {Object.keys(packages).length <= 0 ? (
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center col-12 p-0 m-0 min-h-400px'>
                                                                                <div className='text-center col-12 p-0'>
                                                                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                                                    <h1 className='col mt-5'>
                                                                                        ไม่สามารถเรียกข้อมูลได้
                                                                                        กรุณาลองใหม่อีกครั้ง
                                                                                    </h1>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='row'>
                                                                        {packages.responsecode !== 0 ? (
                                                                            <div className='col-12'>
                                                                                <div className='row align-items-center col-12 p-0 m-0 min-h-400px'>
                                                                                    <div className='text-center col-12 p-0'>
                                                                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                                                        <h1 className='col mt-5'>
                                                                                            ไม่พบแพ็กเกจในขณะนี้
                                                                                        </h1>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className='row col-12 p-0 m-0'>
                                                                                {Object.values(
                                                                                    packages.product
                                                                                ).map((object, index) => {
                                                                                    return (
                                                                                        <div
                                                                                            className='col-lg-6'
                                                                                            key={index}
                                                                                        >
                                                                                            <label className='option'>
                                                                                                <span className='option-control'>
                                                                                                    <span className='radio'>
                                                                                                        <input
                                                                                                            type='radio'
                                                                                                            name='package'
                                                                                                            checked={
                                                                                                                props.selectpackage ===
                                                                                                                object.code
                                                                                                            }
                                                                                                            onChange={() =>
                                                                                                                btnSelect(
                                                                                                                    object
                                                                                                                )}
                                                                                                            readOnly
                                                                                                        />
                                                                                                        <span />
                                                                                                    </span>
                                                                                                </span>
                                                                                                <span className='option-label'>
                                                                                                    <span className='option-head'>
                                                                                                        <span className='option-title'>
                                                                                                            {/* {
                                                                                                                object.title
                                                                                                            } */}
                                                                                                            <NumberFormat
                                                                                                                value={parseFloat(
                                                                                                                    object.price
                                                                                                                )}
                                                                                                                displayType={
                                                                                                                    'text'
                                                                                                                }
                                                                                                                decimalScale={
                                                                                                                    2
                                                                                                                }
                                                                                                                fixedDecimalScale={
                                                                                                                    true
                                                                                                                }
                                                                                                                thousandSeparator={
                                                                                                                    true
                                                                                                                }
                                                                                                                // suffix={
                                                                                                                //     " บาท"
                                                                                                                // }
                                                                                                            />
                                                                                                        </span>
                                                                                                        {/* <span className='option-focus'>
                                                                                                            <NumberFormat
                                                                                                                value={parseFloat(
                                                                                                                    object.price
                                                                                                                )}
                                                                                                                displayType={
                                                                                                                    'text'
                                                                                                                }
                                                                                                                decimalScale={
                                                                                                                    2
                                                                                                                }
                                                                                                                fixedDecimalScale={
                                                                                                                    true
                                                                                                                }
                                                                                                                thousandSeparator={
                                                                                                                    true
                                                                                                                }
                                                                                                                // suffix={
                                                                                                                //     " บาท"
                                                                                                                // }
                                                                                                            />
                                                                                                        </span> */}
                                                                                                    </span>
                                                                                                    <span className='option-body'>
                                                                                                        {object.detail}
                                                                                                    </span>
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='row align-items-center col-12 p-0 m-0 min-h-400px'>
                                                                        {/* <div className='text-center col-12 p-0'>
                                                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                                            <h1 className='col mt-5'>
                                                                                ไม่สามารถเรียกข้อมูลได้
                                                                                กรุณาลองใหม่อีกครั้ง
                                                                            </h1>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='d-flex justify-content-end border-top mt-5 pt-10'>
                                                        {/* <div className='mr-2'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-light-primary font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnBack}
                                                            >
                                                                ก่อนหน้า
                                                            </button>
                                                        </div> */}
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
