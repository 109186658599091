import React, { useEffect, useState } from 'react';
import { getQueryVariable, toAbsoluteUrl, SwalBs } from '../../../../../_metronic/_helpers';
import {
    SubscriberAddress,
    SubscriberAgreement,
    SubscriberInformation,
    SubscriberPackage,
    SubscriberPhonenumber,
    SubscriberResult,
    SubscriberShipping,
    SubscriberSummary
} from './components';
import { config } from '../../../../config';

const initInformation = {
    name: '',
    birthday: '',
    personalid: '',
    contactnumber: '',
    email: '',
    remark: ''
};

const MainHead = [ '0976935555', '0976955555', '0976936365', '0974141329' ];

const SubMainHead = {
    '0976510999': 'Official',
    '0976948111': 'Official2',
    '0976532875': 'Official3'
};

export function SubscriberMain(props) {
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('agreement');
    const [ msisdn, setMsisdn ] = useState('');
    const [ edit, setEdit ] = useState(false);
    // init
    const [ suggesttitle, setSuggestTitle ] = useState('');
    const [ suggestnumber, setSuggestNumber ] = useState('');
    // agreement
    const [ agreement, setAgreement ] = useState(false);
    // package
    const [ type, setType ] = useState('new');
    const [ selectpackage, setSelectPackage ] = useState('');
    const [ packageobject, setPackageObject ] = useState({});
    // phonenumber
    const [ selectnumber, setSelectNumber ] = useState('');
    // information
    const [ information, setInformation ] = useState(initInformation);
    const [ gender, setGender ] = useState('male');
    const [ national, setNational ] = useState('thai');
    // address
    const [ geo, setGeo ] = useState('');
    const [ province, setProvince ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ subdistrict, setSubDistrict ] = useState('');
    const [ zipcode, setZipcode ] = useState('');
    const [ address, setAddress ] = useState('');
    // shipping
    const [ shiptype, setShipType ] = useState('same');
    const [ shipname, setShipName ] = useState('');
    const [ shipcontactnumber, setShipContactnumber ] = useState('');
    const [ shipgeo, setShipGeo ] = useState('');
    const [ shipprovince, setShipProvince ] = useState('');
    const [ shipdistrict, setShipDistrict ] = useState('');
    const [ shipsubdistrict, setShipSubDistrict ] = useState('');
    const [ shipzipcode, setShipZipcode ] = useState('');
    const [ shipaddress, setShipAddress ] = useState('');
    // summary
    const [ result, setResult ] = useState({});

    useEffect(() => {
        document.title = 'เมืองปันสุข';
        document.getElementById('kt_body').classList.add('bg-subscriber');
        // document.getElementById('favicon').href = 'https://www.google.com/favicon.ico';
        let mobileparam = getQueryVariable(props, 'mobile');
        if (!mobileparam) {
            SwalBs.fire({
                title: 'ยินดีต้อนรับ',
                // html: '<div class="alert-text">ท่านกำลังทำการสมัครดีลเลอร์โดยไม่มีผู้แนะนำ</div>',
                html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สั่งซื้อจากดีลเลอร์</div>',
                // icon: 'info',
                imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonColor: '#0bb7af',
                confirmButtonText: 'รับทราบ',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            setSuggestTitle('เมืองปันสุข');
            return;
        } else {
            if (mobileparam === '') {
                SwalBs.fire({
                    title: 'ยินดีต้อนรับ',
                    // html: '<div class="alert-text">ท่านกำลังทำการสมัครดีลเลอร์โดยไม่มีผู้แนะนำ</div>',
                    html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สั่งซื้อจากดีลเลอร์</div>',
                    // icon: 'info',
                    imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    confirmButtonColor: '#0bb7af',
                    confirmButtonText: 'รับทราบ',
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                setSuggestTitle('เมืองปันสุข');
                return;
            } else {
                if (mobileparam.length !== 10 || mobileparam.charAt(0) !== '0') {
                    SwalBs.fire({
                        title: 'ยินดีต้อนรับ',
                        // html: '<div class="alert-text">ท่านกำลังทำการสมัครดีลเลอร์โดยไม่มีผู้แนะนำ</div>',
                        html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สั่งซื้อจากดีลเลอร์</div>',
                        // icon: 'info',
                        imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                        imageWidth: 80,
                        imageHeight: 80,
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'รับทราบ',
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    setSuggestTitle('เมืองปันสุข');
                    return;
                } else {
                    setMsisdn(mobileparam);
                    getDealerInfo(mobileparam);
                }
            }
        }
    }, []);

    const getDealerInfo = (phonenumber) => {
        setLoading(true);
        fetch(config.dealer_php_url + 'api/register/getdealerinfo', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setSuggestTitle(phonenumber);
                    setSuggestNumber(phonenumber);
                    let suggestname = result.name;
                    if (MainHead.includes(phonenumber)) {
                        setSuggestTitle('เมืองปันสุข');
                        SwalBs.fire({
                            title: 'ยินดีต้อนรับ',
                            html: '<div class="alert-text">ท่านกำลังซื้อซิมโดยไม่มีดีลเลอร์แนะนำ</div>',
                            // icon: 'info',
                            imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                            imageWidth: 80,
                            imageHeight: 80,
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else if (phonenumber in SubMainHead) {
                        setSuggestTitle(SubMainHead[phonenumber]);
                        // this.suggestnumber = 'ซิมปันสุข';
                        SwalBs.fire({
                            title: 'ยินดีต้อนรับ',
                            html: `<div class="alert-text">ท่านกำลังซื้อซิมผ่านดีลเลอร์<br/><b class="text-success">${SubMainHead[
                                phonenumber
                            ]}</b></div>`,
                            // icon: 'info',
                            imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                            imageWidth: 80,
                            imageHeight: 80,
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else {
                        SwalBs.fire({
                            title: 'ยินดีต้อนรับ',
                            html: `<div class="alert-text">ท่านกำลังซื้อซิมผ่านดีลเลอร์<br/><b class="text-success">${suggestname}</b></div>`,
                            // icon: 'info',
                            imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                            imageWidth: 80,
                            imageHeight: 80,
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    }
                } else {
                    setSuggestTitle('เมืองปันสุข');
                    setSuggestNumber('เมืองปันสุข');
                    SwalBs.fire({
                        title: 'ยินดีต้อนรับ',
                        html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สั่งซื้อจากดีลเลอร์</div>',
                        // icon: 'info',
                        imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                        imageWidth: 80,
                        imageHeight: 80,
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                setSuggestTitle('เมืองปันสุข');
                setSuggestNumber('เมืองปันสุข');
                SwalBs.fire({
                    title: 'ยินดีต้อนรับ',
                    html: '<div class="alert-text">กรุณาติดต่อเพื่อขอลิงค์สั่งซื้อจากดีลเลอร์</div>',
                    // icon: 'info',
                    imageUrl: toAbsoluteUrl('/media/logos/new-logo.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    return (
        <div className='subscriber-register'>
            {loading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            <div>
                {page === 'agreement' ? (
                    <SubscriberAgreement
                        agreement={agreement}
                        setAgreement={setAgreement}
                        suggesttitle={suggesttitle}
                        suggestnumber={suggestnumber}
                        setPage={setPage}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'package' ? (
                    <SubscriberPackage
                        suggesttitle={suggesttitle}
                        suggestnumber={suggestnumber}
                        config={config}
                        loading={loading}
                        setLoading={setLoading}
                        type={type}
                        setType={setType}
                        selectpackage={selectpackage}
                        setSelectPackage={setSelectPackage}
                        packageobject={packageobject}
                        setPackageObject={setPackageObject}
                        setPage={setPage}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'phonenumber' ? (
                    <SubscriberPhonenumber
                        suggesttitle={suggesttitle}
                        suggestnumber={suggestnumber}
                        config={config}
                        loading={loading}
                        setLoading={setLoading}
                        type={type}
                        packageobject={packageobject}
                        selectnumber={selectnumber}
                        setSelectNumber={setSelectNumber}
                        setPage={setPage}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'information' ? (
                    <SubscriberInformation
                        edit={edit}
                        suggesttitle={suggesttitle}
                        suggestnumber={suggestnumber}
                        config={config}
                        setLoading={setLoading}
                        setPage={setPage}
                        gender={gender}
                        setGender={setGender}
                        national={national}
                        setNational={setNational}
                        information={information}
                        setInformation={setInformation}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'address' ? (
                    <SubscriberAddress
                        edit={edit}
                        suggesttitle={suggesttitle}
                        suggestnumber={suggestnumber}
                        setPage={setPage}
                        geo={geo}
                        setGeo={setGeo}
                        province={province}
                        setProvince={setProvince}
                        district={district}
                        setDistrict={setDistrict}
                        subdistrict={subdistrict}
                        setSubDistrict={setSubDistrict}
                        zipcode={zipcode}
                        setZipcode={setZipcode}
                        address={address}
                        setAddress={setAddress}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'shipping' ? (
                    <SubscriberShipping
                        suggesttitle={suggesttitle}
                        suggestnumber={suggestnumber}
                        shiptype={shiptype}
                        setShipType={setShipType}
                        information={information}
                        geo={geo}
                        province={province}
                        district={district}
                        subdistrict={subdistrict}
                        zipcode={zipcode}
                        address={address}
                        shipname={shipname}
                        setShipName={setShipName}
                        shipcontactnumber={shipcontactnumber}
                        setShipContactnumber={setShipContactnumber}
                        shipgeo={shipgeo}
                        setShipGeo={setShipGeo}
                        shipprovince={shipprovince}
                        setShipProvince={setShipProvince}
                        shipdistrict={shipdistrict}
                        setShipDistrict={setShipDistrict}
                        shipsubdistrict={shipsubdistrict}
                        setShipSubDistrict={setShipSubDistrict}
                        shipzipcode={shipzipcode}
                        setShipZipcode={setShipZipcode}
                        shipaddress={shipaddress}
                        setShipAddress={setShipAddress}
                        setPage={setPage}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : page === 'summary' ? (
                    <SubscriberSummary
                        setEdit={setEdit}
                        suggesttitle={suggesttitle}
                        suggestnumber={suggestnumber}
                        config={config}
                        setLoading={setLoading}
                        information={information}
                        gender={gender}
                        national={national}
                        geo={geo}
                        province={province}
                        district={district}
                        subdistrict={subdistrict}
                        zipcode={zipcode}
                        address={address}
                        shiptype={shiptype}
                        shipname={shipname}
                        shipcontactnumber={shipcontactnumber}
                        shipgeo={shipgeo}
                        shipprovince={shipprovince}
                        shipdistrict={shipdistrict}
                        shipsubdistrict={shipsubdistrict}
                        shipzipcode={shipzipcode}
                        shipaddress={shipaddress}
                        type={type}
                        packageobject={packageobject}
                        selectpackage={selectpackage}
                        selectnumber={selectnumber}
                        setResult={setResult}
                        setPage={setPage}
                        SwalBs={SwalBs}
                        toAbsoluteUrl={toAbsoluteUrl}
                    />
                ) : (
                    <SubscriberResult result={result} SwalBs={SwalBs} toAbsoluteUrl={toAbsoluteUrl} />
                )}
            </div>
        </div>
    );
}
