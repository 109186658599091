import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { maxLengthCheck, validateNumber } from '../../../../../../_metronic/_helpers';

const maxindex = 12;
const initstartindex = 0;
const initendindex = 11;

export function DealerPhonenumber(props) {
    const [ title, setTile ] = useState('');
    const [ keyword, setKeyword ] = useState('');
    const [ startindex, setStartIndex ] = useState(initstartindex);
    const [ endindex, setEndIndex ] = useState(initendindex);
    const [ phonenumbers, setPhonenumbers ] = useState({});

    useEffect(() => {
        let postparam = {
            package: props.packageobject.sim_pack_id,
            qty: '48'
        };
        if (props.type === 'new') {
            setTile('เลือกเบอร์ปันสุขใหม่');
            getPhonenumber(postparam);
        } else {
            setTile('ตรวจสอบเบอร์ Feels');
        }
    }, []);

    const onPrevious = () => {
        setStartIndex(startindex - maxindex);
        setEndIndex(endindex - maxindex);
    };

    const onNext = () => {
        setStartIndex(startindex + maxindex);
        setEndIndex(endindex + maxindex);
    };

    const onRandom = () => {
        let postparam = {
            package: props.packageobject.sim_pack_id,
            qty: '48'
        };
        if (keyword !== '') {
            postparam['filter'] = {
                number: keyword
            };
        }
        getPhonenumber(postparam);
    };

    const onSearch = () => {
        let postparam = {
            package: props.packageobject.sim_pack_id,
            qty: '48'
        };
        if (keyword !== '') {
            postparam['filter'] = {
                number: keyword
            };
        }
        getPhonenumber(postparam);
    };

    const btnBack = () => {
        props.setSelectNumber('');
        props.setPage('package');
    };

    const btnNext = () => {
        if (props.type === 'new') {
            if (props.selectnumber === '') {
                props.SwalBs.fire({
                    // title: 'ผิดพลาด!',
                    html: `<div class='alert-text'>กรุณาเลือกเบอร์ที่ต้องการใช้งาน</div>`,
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            props.SwalBs
                .fire({
                    // title: 'ผิดพลาด!',
                    html: `<div class='alert-text'>คุณได้เลือกเบอร์ <b>${props.selectnumber}</b> ใช่หรือไม่?</div>`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'ยืนยัน',
                    cancelButtonText: 'ยกเลิก',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                })
                .then((result) => {
                    if (result.value) {
                        let payload = {
                            type: 'sim',
                            number: props.selectnumber
                        };
                        bookPhonenumber(payload);
                    }
                });
        } else {
            if (props.selectnumber === '') {
                props.SwalBs.fire({
                    // title: 'ผิดพลาด!',
                    html: `<div class='alert-text'>กรุณากรอกเบอร์ Feels ที่ใช้งานอยู่</div>`,
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            } else {
                if (props.selectnumber.length !== 10 || props.selectnumber.charAt(0) !== '0') {
                    props.SwalBs.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class='alert-text'>กรุณากรอกเบอร์ Feels ที่ใช้งานอยู่ให้ถูกต้อง</div>`,
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            }
            checkNumber();
        }
    };

    const checkNumber = () => {
        props.setLoading(true);
        fetch(props.config.dealer_php_url + 'api/register/getsim', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: 'feels',
                filter: { number: props.selectnumber }
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0 || result.responsecode === 105) {
                    let payload = {
                        type: 'feels',
                        number: props.selectnumber
                    };
                    bookPhonenumber(payload);
                } else {
                    props.setLoading(false);
                    if (result.responsecode === 100) {
                        props.SwalBs.fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class="alert-text">หมายเลข ${props.selectnumber} อยู่ระหว่างการจองอยู่</div>`,
                            icon: 'info',
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else if (result.responsecode === 103) {
                        props.SwalBs.fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class="alert-text">ไม่พบหมายเลข ${props.selectnumber} ในระบบของ Feels</div>`,
                            icon: 'info',
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else if (result.responsecode === 104) {
                        props.SwalBs.fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class="alert-text">หมายเลข ${props.selectnumber} ยังไม่ถูกเปิดใช้งาน</div>`,
                            icon: 'info',
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else if (result.responsecode === 106) {
                        props.SwalBs.fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class="alert-text">หมายเลข ${props.selectnumber} หมดอายุแล้ว</div>`,
                            icon: 'info',
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else {
                        props.SwalBs.fire({
                            title: 'ผิดพลาด!',
                            html: `<div class="alert-text">ไม่สามารถตรวจสอบหมายเลข Feels ได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>`,
                            icon: 'warning',
                            confirmButtonColor: '#ffa800',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    }
                }
            })
            .catch((error) => {
                props.setLoading(false);
                props.SwalBs.fire({
                    title: 'ผิดพลาด!',
                    html:
                        '<div class="alert-text">ไม่สามารถตรวจสอบหมายเลข Feels ได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>',
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const getPhonenumber = (postparam) => {
        props.setLoading(true);
        setStartIndex(initstartindex);
        setEndIndex(initendindex);
        setPhonenumbers({});
        fetch(props.config.dealer_php_url + 'api/register/getsim', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                setPhonenumbers(result);
            })
            .catch((error) => {
                props.setLoading(false);
            });
    };

    const bookPhonenumber = (postparam) => {
        props.setLoading(true);
        fetch(props.config.dealer_php_url + 'api/register/booknumber', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                if (result.responsecode === 0) {
                    props.setPage('shipping');
                } else {
                    props.SwalBs.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class='alert-text'>ไม่สามารถจองเบอร์ ${props.selectnumber} ได้ <br/>เนื่องจากเบอร์นี้บุคคลอื่นจองอยู่</div>`,
                        icon: 'info',
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                props.setLoading(false);
                props.SwalBs.fire({
                    title: 'ผิดพลาด!',
                    html: `<div class='alert-text'>ไม่สามารถจองเบอร์ได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>`,
                    icon: 'warning',
                    confirmButtonColor: '#0bb7af',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Punsook-logo'
                                    src={props.toAbsoluteUrl('/media/logos/new-logo-long.png')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>สมัครดีลเลอร์ใหม่</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='svg-icon svg-icon-success svg-icon-lg'>
                            <SVG src={props.toAbsoluteUrl('/media/svg/icons/Communication/Active-call.svg')} />
                        </span>
                        แนะนำโดย : {props.suggesttitle}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-step'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>สมัครดีลเลอร์ใหม่</div>
                                                    {title}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <div
                                                    className='mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='pb-5'>
                                                        {props.type === 'old' ? (
                                                            <div className='row justify-content-center'>
                                                                <div className='row col-xl-12 justify-content-center'>
                                                                    <div className='row col-xl-12 col-sm-12 justify-content-center'>
                                                                        <div className='col-xl-12'>
                                                                            <div className='form-group fv-plugins-icon-container'>
                                                                                <label>เบอร์ Feels ที่ใช้งานอยู่</label>
                                                                                <input
                                                                                    type='text'
                                                                                    inputMode='tel'
                                                                                    name='phonenumber'
                                                                                    className='form-control form-control-lg'
                                                                                    value={props.selectnumber}
                                                                                    onChange={(e) =>
                                                                                        props.setSelectNumber(
                                                                                            e.target.value
                                                                                        )}
                                                                                    maxLength='10'
                                                                                    onInput={maxLengthCheck}
                                                                                    onKeyPress={(event) =>
                                                                                        validateNumber(event)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='row justify-content-center'>
                                                                <div className='row col-xl-12 justify-content-center'>
                                                                    <div className='row col-xl-9 col-sm-12 justify-content-center'>
                                                                        <div className='col-xl-12'>
                                                                            <div className='form-group fv-plugins-icon-container'>
                                                                                <label>ค้นหาเลขที่สนใจ</label>
                                                                                <input
                                                                                    type='text'
                                                                                    inputMode='tel'
                                                                                    name='keyword'
                                                                                    className='form-control form-control-lg'
                                                                                    value={keyword}
                                                                                    onChange={(e) =>
                                                                                        setKeyword(e.target.value)}
                                                                                    maxLength='10'
                                                                                    onInput={maxLengthCheck}
                                                                                    onKeyPress={(event) =>
                                                                                        validateNumber(event)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-xl-3 col-sm-12 d-flex justify-content-center align-items-center'>
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-secondary font-weight-bold text-uppercase px-9 py-4'
                                                                            onClick={onSearch}
                                                                        >
                                                                            ค้นหา
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 p-0'>
                                                                    {!props.loading ? (
                                                                        <div>
                                                                            {Object.keys(phonenumbers).length <= 0 ? (
                                                                                <div className='row'>
                                                                                    <div className='col-12'>
                                                                                        <div className='row align-items-center col-12 p-0 m-0 min-h-400px'>
                                                                                            <div className='text-center col-12 p-0'>
                                                                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                                                                <h1 className='col mt-5'>
                                                                                                    ไม่สามารถเรียกข้อมูลได้
                                                                                                    กรุณาลองใหม่อีกครั้ง
                                                                                                </h1>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className='row col-xl-12 m-0 justify-content-center'>
                                                                                    {phonenumbers.responsecode === 0 ? (
                                                                                        <div className='number-box w-100'>
                                                                                            <div className='col-xl-12 row form-group m-0 mt-5 mb-5 p-0'>
                                                                                                {phonenumbers.info
                                                                                                    .slice(
                                                                                                        startindex,
                                                                                                        endindex + 1
                                                                                                    )
                                                                                                    .map(
                                                                                                        (
                                                                                                            object,
                                                                                                            index
                                                                                                        ) => {
                                                                                                            return (
                                                                                                                <div
                                                                                                                    className='col-md-3 col-sm-4 col-6 pr-2 pl-2 mt-3'
                                                                                                                    key={
                                                                                                                        index
                                                                                                                    }
                                                                                                                >
                                                                                                                    <button
                                                                                                                        type='button'
                                                                                                                        className={`btn ${props.selectnumber ===
                                                                                                                        object.phonenumber
                                                                                                                            ? 'btn-success'
                                                                                                                            : 'btn-outline-success'} pt-2 pb-2 pl-0 pr-0 fs-20 font-weight-normal w-100`}
                                                                                                                        onClick={() =>
                                                                                                                            props.setSelectNumber(
                                                                                                                                object.phonenumber
                                                                                                                            )}
                                                                                                                    >
                                                                                                                        {object.phonenumber.slice(
                                                                                                                            0,
                                                                                                                            3
                                                                                                                        ) +
                                                                                                                            '-' +
                                                                                                                            object.phonenumber.slice(
                                                                                                                                3,
                                                                                                                                6
                                                                                                                            ) +
                                                                                                                            '-' +
                                                                                                                            object.phonenumber.slice(
                                                                                                                                6
                                                                                                                            )}
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                            </div>
                                                                                            <div className='col-xl-12 text-center d-flex justify-content-center mt-3'>
                                                                                                {phonenumbers.info
                                                                                                    .length >=
                                                                                                    endindex + 1 && (
                                                                                                    <button
                                                                                                        type='button'
                                                                                                        className='btn btn-success btn-sm font-weight-bold text-uppercase mr-3'
                                                                                                        disabled={
                                                                                                            startindex <=
                                                                                                            0
                                                                                                        }
                                                                                                        onClick={
                                                                                                            onPrevious
                                                                                                        }
                                                                                                    >
                                                                                                        ก่อนหน้า
                                                                                                    </button>
                                                                                                )}
                                                                                                {endindex <
                                                                                                    phonenumbers.info
                                                                                                        .length -
                                                                                                        1 && (
                                                                                                    <button
                                                                                                        type='button'
                                                                                                        className='btn btn-success btn-sm font-weight-bold text-uppercase'
                                                                                                        onClick={onNext}
                                                                                                    >
                                                                                                        ถัดไป
                                                                                                    </button>
                                                                                                )}
                                                                                                {!(
                                                                                                    endindex <
                                                                                                    phonenumbers.info
                                                                                                        .length -
                                                                                                        1
                                                                                                ) &&
                                                                                                phonenumbers.info
                                                                                                    .length > 12 && (
                                                                                                    <button
                                                                                                        type='button'
                                                                                                        className='btn btn-success btn-sm font-weight-bold text-uppercase'
                                                                                                        onClick={
                                                                                                            onRandom
                                                                                                        }
                                                                                                    >
                                                                                                        สุ่มใหม่
                                                                                                    </button>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <div className='col-xl-12 form-group text-center p-5'>
                                                                                                <i className='fas fa-box-open fa-5x mb-2 text-light-green-main' />
                                                                                                <h4 className='mt-3'>
                                                                                                    ไม่พบเลขนี้ในระบบ
                                                                                                </h4>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div className='row align-items-center col-12 p-0 m-0 min-h-400px'>
                                                                                    {/* <div className='text-center col-12 p-0'>
                                                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                                                <h1 className='col mt-5'>
                                                                                    ไม่สามารถเรียกข้อมูลได้
                                                                                    กรุณาลองใหม่อีกครั้ง
                                                                                </h1>
                                                                            </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                                        <div className='mr-2'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-light-primary font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnBack}
                                                            >
                                                                ก่อนหน้า
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                {props.type === 'old' ? 'ตรวจสอบ' : 'ถัดไป'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
