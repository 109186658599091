import React from 'react';

export function FeelsResultTerm(props) {
    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Feels-logo'
                                    src={props.toAbsoluteUrl('/media/logos/feels-full-logo.svg')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>ลงทะเบียนซิมออนไลน์</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center' />
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-max'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>ตรวจสอบ</div>
                                                    ผลลัพท์
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center pb-8 px-8 pb-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <div className='pb-5'>
                                                    <div className='row justify-content-center'>
                                                        {Object.keys(props.pendingresponse).length === 0 ? (
                                                            <div className='text-center'>
                                                                <div>
                                                                    <img
                                                                        className='result-img'
                                                                        alt='result-img'
                                                                        src={props.toAbsoluteUrl(
                                                                            '/media/misc/regiser-error.svg'
                                                                        )}
                                                                    />
                                                                </div>
                                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                    ปัญหาที่ 1
                                                                </h4>
                                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                    ลงทะเบียนไม่สำเร็จ
                                                                </h4>
                                                                <p>กรุณาติดต่อเจ้าหน้าที่ เพื่อดำเนินการแก้ไขปัญหา</p>
                                                            </div>
                                                        ) : Object.keys(props.updateresponse).length === 0 ? (
                                                            <div className='text-center'>
                                                                <div>
                                                                    <img
                                                                        className='result-img'
                                                                        alt='result-img'
                                                                        src={props.toAbsoluteUrl(
                                                                            '/media/misc/regiser-pending.svg'
                                                                        )}
                                                                    />
                                                                </div>
                                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                    ปัญหาที่ 2
                                                                </h4>
                                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                    ลงทะเบียนไม่สมบูรณ์
                                                                </h4>
                                                                <p>กรุณาติดต่อเจ้าหน้าที่ เพื่อดำเนินการแก้ไขปัญหา</p>
                                                            </div>
                                                        ) : props.pendingresponse.responsecode !== 0 ? (
                                                            <div className='text-center'>
                                                                <div>
                                                                    <img
                                                                        className='result-img'
                                                                        alt='result-img'
                                                                        src={props.toAbsoluteUrl(
                                                                            '/media/misc/regiser-error.svg'
                                                                        )}
                                                                    />
                                                                </div>
                                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                    ปัญหาที่ 1/{props.pendingresponse.responsecode}
                                                                </h4>
                                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                    ลงทะเบียนไม่สำเร็จ
                                                                </h4>
                                                                <p>กรุณาติดต่อเจ้าหน้าที่ เพื่อดำเนินการแก้ไขปัญหา</p>
                                                            </div>
                                                        ) : props.updateresponse.responsecode !== 0 ? (
                                                            <div className='text-center'>
                                                                <div>
                                                                    <img
                                                                        className='result-img'
                                                                        alt='result-img'
                                                                        src={props.toAbsoluteUrl(
                                                                            '/media/misc/regiser-error.svg'
                                                                        )}
                                                                    />
                                                                </div>
                                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                    ปัญหาที่ 2/{props.updateresponse.responsecode}
                                                                </h4>
                                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                    ลงทะเบียนไม่สมบูรณ์
                                                                </h4>
                                                                <p>กรุณาติดต่อเจ้าหน้าที่ เพื่อดำเนินการแก้ไขปัญหา</p>
                                                            </div>
                                                        ) : (
                                                            <div className='text-center'>
                                                                <div>
                                                                    <img
                                                                        className='result-img'
                                                                        alt='result-img'
                                                                        src={props.toAbsoluteUrl(
                                                                            '/media/misc/regiser-pending.svg'
                                                                        )}
                                                                    />
                                                                </div>
                                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                    เจ้าหน้าที่กำลังตรวจสอบข้อมูลของคุณ
                                                                </h4>
                                                                <p>เจ้าหน้าที่ของเราได้รับข้อมูลของท่านแล้ว</p>
                                                                <p>เราจะดำเนินการเปิดซิมของคุณภายใน 1 วันทำการ</p>
                                                                <p>
                                                                    หากดำเนินการเรียบร้อยแล้วจะมีการติดต่อไป
                                                                    หรือหากสงสัยสามารถติดต่อสอบถามเจ้าหน้าที่
                                                                </p>
                                                                {/* <p>กรุณาลงทะเบียนใหม่อีกครั้ง เนื่องจากไม่</p> */}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <div className='row justify-content-center'>
                                                        <div className='text-center'>
                                                            <div>
                                                                <img
                                                                    className='result-img'
                                                                    src='assets/media/onlinesim/result/pending.svg'
                                                                />
                                                            </div>
                                                            <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                รอดำเนินการ
                                                            </h4>
                                                            <p>
                                                                ลงทะเบียนเรียบร้อย กรุณารอประมาณ 10 ถึง 15
                                                                นาทีก่อนเริ่มใช้งาน
                                                            </p>
                                                            <p>กรุณาลงทะเบียนใหม่อีกครั้ง เนื่องจากไม่</p>
                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-center'>
                                                        <div className='text-center'>
                                                            <div>
                                                                <img
                                                                    className='result-img'
                                                                    src='assets/media/onlinesim/result/error.svg'
                                                                />
                                                            </div>
                                                            <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                ลงทะเบียนไม่สมบูรณ์
                                                            </h4>
                                                            <p>กรุณาติดต่อเจ้าหน้าที่ เพื่อดำเนินการแก้ไขปัญหา</p>
                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-center'>
                                                        <div className=' text-center'>
                                                            <div>
                                                                <img
                                                                    className='result-img'
                                                                    src='assets/media/onlinesim/result/success.svg'
                                                                />
                                                            </div>
                                                            <h4 className='text-center text-color-main mb-0 mt-5'>
                                                                ลงทะเบียนเรียบร้อย
                                                            </h4>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className='d-flex justify-content-center border-top mt-5 pt-10'>
                                                    {(Object.keys(props.pendingresponse).length === 0 ||
                                                        Object.keys(props.updateresponse).length === 0 ||
                                                        props.pendingresponse.responsecode !== 0 ||
                                                        props.updateresponse.responsecode !== 0) && (
                                                        <div>
                                                            <a
                                                                href='http://line.me/ti/p/~@dealersimsupport'
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                                className='btn btn-success btn-feels-main font-weight-bold text-uppercase px-9 py-4'
                                                            >
                                                                ติดต่อเจ้าหน้าที่
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
