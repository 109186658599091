import React, { useRef } from 'react';
import { convertBase64 } from '../../../../../../_metronic/_helpers';

export function FeelsInfoImage(props) {
    const frontidcard = useRef(null);
    const backidcard = useRef(null);

    const btnNext = () => {
        imageReader();
    };

    const handleImage = async (e, param) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        if (param === 'front') {
            props.setFrontIdcard(base64.split(',')[1]);
        } else if (param === 'back') {
            props.setBackIdcard(base64.split(',')[1]);
        }
    };

    const imageReader = () => {
        props.setLoading(true);
        fetch(props.config.custom_url + props.config.version + 'onlinesim/ocrpersonalinfo', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: props.config.custom_secret
            },
            body: JSON.stringify({
                msisdn: '66' + props.msisdn.slice(1),
                frontimg: props.frontidcard,
                backimg: props.backidcard
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                if (result.responsecode === 0) {
                    let information = props.information;
                    for (const key in result) {
                        if (Object.keys(props.information).includes(key)) {
                            if (key === 'citizenid') {
                                if (result[key].match(/^[0-9]+$/) !== null) {
                                    information[key] = result[key];
                                }
                            } else {
                                information[key] = result[key];
                            }
                        }
                    }
                    props.setInformation(information);
                    props.setPage('information');
                } else {
                    if (result.responsecode === 103) {
                        props.SwalBs
                            .fire({
                                // title: 'ผิดพลาด!',
                                html: '<div class="alert-text">ไม่สามารถอ่านข้อมูลจากรูปได้ กรุณาลองใหม่อีกครั้ง</div>',
                                imageUrl: props.toAbsoluteUrl('/media/misc/info-punsook.svg'),
                                imageWidth: 80,
                                imageHeight: 80,
                                confirmButtonColor: '#0bb7af',
                                confirmButtonText: 'ตกลง',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    props.setFrontIdcard('');
                                    props.setBackIdcard('');
                                    frontidcard.current.value = ''
                                    backidcard.current.value = ''
                                }
                            });
                    } else if (result.responsecode === 104) {
                        props.SwalBs
                            .fire({
                                // title: 'ผิดพลาด!',
                                html:
                                    '<div class="alert-text">ไม่สามารถอ่านข้อมูลจากรูปหลังบัตรได้ กรุณาลองใหม่อีกครั้ง</div>',
                                imageUrl: props.toAbsoluteUrl('/media/misc/info-punsook.svg'),
                                imageWidth: 80,
                                imageHeight: 80,
                                confirmButtonColor: '#0bb7af',
                                confirmButtonText: 'ตกลง',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    props.setBackIdcard('');
                                    backidcard.current.value = ''
                                }
                            });
                    } else if (result.responsecode === 105) {
                        props.SwalBs
                            .fire({
                                // title: 'ผิดพลาด!',
                                html:
                                    '<div class="alert-text">ไม่สามารถอ่านข้อมูลจากรูปหน้าบัตรได้ กรุณาลองใหม่อีกครั้ง</div>',
                                imageUrl: props.toAbsoluteUrl('/media/misc/info-punsook.svg'),
                                imageWidth: 80,
                                imageHeight: 80,
                                confirmButtonColor: '#0bb7af',
                                confirmButtonText: 'ตกลง',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    props.setFrontIdcard('');
                                    frontidcard.current.value = ''
                                }
                            });
                    } else {
                        props.SwalBs
                            .fire({
                                title: 'ผิดพลาด!',
                                html: '<div class="alert-text">ระบบมีปัญหา กรุณาลองใหม่อีกครั้ง</div>',
                                icon: 'warning',
                                confirmButtonColor: '#28a745',
                                confirmButtonText: 'ตกลง',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    props.setFrontIdcard('');
                                    props.setBackIdcard('');
                                    frontidcard.current.value = ''
                                    backidcard.current.value = ''
                                }
                            });
                    }
                }
            })
            .catch((error) => {
                props.setLoading(false);
                props.SwalBs.fire({
                    title: 'ผิดพลาด!',
                    html: '<div class="alert-text">ระบบมีปัญหา! กรุณาลองใหม่อีกครั้ง</div>',
                    icon: 'error',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };
    return (
        <div>
            <div className='subheader py-3 py-lg-8 subheader-transparent' id='kt_subheader'>
                <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
                    <div className='d-flex align-items-center flex-wrap mr-1'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <h2 className='subheader-title text-dark font-weight-bold my-1 mr-3'>
                                <img
                                    alt='Feels-logo'
                                    src={props.toAbsoluteUrl('/media/logos/feels-full-logo.svg')}
                                    className='logo-sticky max-h-35px'
                                />
                            </h2>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0'>
                                <li className='breadcrumb-item'>ลงทะเบียนซิมออนไลน์</li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-flex align-items-center' />
                </div>
            </div>
            <div className='d-flex flex-column-fluid'>
                <div className='container'>
                    <div className='card card-custom card-transparent'>
                        <div className='card-body p-0'>
                            <div>
                                <div className='card card-custom card-shadowless'>
                                    <div className='progressbar-wrapper'>
                                        <ul className='progressbar-min'>
                                            <li className='mb-3'>
                                                <span>
                                                    <div className='step-title'>ตรวจสอบ</div>
                                                    บัตรประจำตัวประชาชน
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='card-body p-0'>
                                        <div className='row justify-content-center pb-8 px-8 pb-lg-15 px-lg-10'>
                                            <div className='col-xl-12 col-xxl-7'>
                                                <form
                                                    className='form mt-0 fv-plugins-bootstrap fv-plugins-framework'
                                                    id='kt_form'
                                                >
                                                    <div className='pb-5'>
                                                        <div className='form-group fv-plugins-icon-container has-danger' />
                                                        <div className='row'>
                                                            <div className='col-xl-12'>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <div className='text-center upload-img mt-3'>
                                                                        <img
                                                                            className={`${props.frontidcard === ''
                                                                                ? 'd-example'
                                                                                : 'd-none'}`}
                                                                            alt='front-idcard-preview-img'
                                                                            src={props.toAbsoluteUrl(
                                                                                '/media/misc/example-front-idcard.png'
                                                                            )}
                                                                            onClick={() => {
                                                                                frontidcard.current.click();
                                                                            }}
                                                                        />
                                                                        <div>
                                                                            <img
                                                                                className={`${props.frontidcard === ''
                                                                                    ? 'd-none'
                                                                                    : 'd-example'}`}
                                                                                alt='front-idcard-img'
                                                                                src={`data:image/png;base64,${props.frontidcard}`}
                                                                                onClick={() => {
                                                                                    frontidcard.current.click();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <p className='mt-3'>
                                                                            ภาพถ่ายบัตรประจำตัวประชาชน (ด้านหน้า)
                                                                        </p>
                                                                        <input
                                                                            ref={frontidcard}
                                                                            className='d-none'
                                                                            type='file'
                                                                            accept='image/*'
                                                                            capture
                                                                            onChange={(e) => {
                                                                                handleImage(e, 'front');
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='form-group fv-plugins-icon-container'>
                                                                    <div className='text-center upload-img mt-3'>
                                                                        <img
                                                                            className={`${props.backidcard === ''
                                                                                ? 'd-example'
                                                                                : 'd-none'}`}
                                                                            alt='back-idcard-preview-img'
                                                                            src={props.toAbsoluteUrl(
                                                                                '/media/misc/example-back-idcard.png'
                                                                            )}
                                                                            onClick={() => {
                                                                                backidcard.current.click();
                                                                            }}
                                                                        />
                                                                        <div>
                                                                            <img
                                                                                className={`${props.backidcard === ''
                                                                                    ? 'd-none'
                                                                                    : 'd-example'}`}
                                                                                alt='back-idcard-img'
                                                                                src={`data:image/png;base64,${props.backidcard}`}
                                                                                onClick={() => {
                                                                                    backidcard.current.click();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <p className='mt-3'>
                                                                            ภาพถ่ายบัตรประจำตัวประชาชน (ด้านหลัง)
                                                                        </p>
                                                                        <input
                                                                            ref={backidcard}
                                                                            className='d-none'
                                                                            type='file'
                                                                            accept='image/*'
                                                                            capture
                                                                            onChange={(e) => {
                                                                                handleImage(e, 'back');
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center border-top mt-5 pt-10'>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success btn-feels-main font-weight-bold text-uppercase px-9 py-4'
                                                                onClick={btnNext}
                                                            >
                                                                ถัดไป
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
